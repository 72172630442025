import { useState, useRef, FormEvent, useEffect } from "react";
import { College } from "../models/college";
import { Country } from "../models/country";
import { University } from "../models/university";
import { getAllCountries, getAllUniversities, getAllColleges } from "../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

interface FilterBoxProps {
    countryFilter?: boolean,
    universityFilter?: boolean,
    collegeFilter?: boolean,
    onFilter?(countryId: string | undefined,
        universityId?: string | undefined,
        collegeId?: string | undefined): void,
}

export default function FilterBox(props: FilterBoxProps) {
    const {
        countryFilter = false,
        universityFilter = false,
        collegeFilter = false,
        onFilter = () => { },
    } = props;

    const { t, i18n } = useTranslation();

    const [shown, setShown] = useState(false);

    const [countriesList, setCountriesList] = useState<Country[]>([]);
    const [universitiesList, setUniversitiesList] = useState<University[]>([]);
    const [collegesList, setCollegesList] = useState<College[]>([]);

    const [filteredUniversities, setFilteredUniversities] = useState<University[]>(universitiesList);
    const [filteredColleges, setFilteredColleges] = useState<College[]>(collegesList);

    const countryRef = useRef<HTMLSelectElement>(null);
    const universityRef = useRef<HTMLSelectElement>(null);
    const collegeRef = useRef<HTMLSelectElement>(null);

    const handleCollegeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const cid = e.currentTarget?.value;
    }

    const handleUniversityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const uid = e.currentTarget?.value;

        updateCollegeList(uid);

        if (collegeRef.current) {
            collegeRef.current.value = "";
        }
    }

    const handleCountryChange = (event: FormEvent<HTMLSelectElement>) => {
        const cid = countryRef.current?.value ?? "";

        updateUniversityList(cid);

        if (universityRef.current) {
            universityRef.current.value = "";
        }

        if (collegeRef.current) {
            collegeRef.current.value = "";
        }
    }

    const updateUniversityList = async (cid: string) => {
        if (cid) {
            const items = universitiesList.filter((e: University) => {
                return e.country?.id === cid;
            })

            setFilteredUniversities(items);
        } else {
            setFilteredUniversities(universitiesList);
        }
    }

    const updateCollegeList = async (cid: string) => {
        if (cid) {
            const items = collegesList.filter((e: College) => {
                return e.university?.id === cid;
            })

            setFilteredColleges(items);
        } else {
            setFilteredColleges(collegesList);
        }
    }

    const handleFilter = () => {
        if (props.onFilter) {
            props.onFilter(countryRef.current?.value,
                universityRef.current?.value, collegeRef.current?.value);
        }

        setShown(false);
    }

    useEffect(() => {
        getAllCountries().then((rows) => {
            setCountriesList(rows);
        });

        getAllUniversities().then((rows) => {
            setUniversitiesList(rows);
            setFilteredUniversities(rows);
        });

        getAllColleges().then((rows) => {
            setCollegesList(rows);
            setFilteredColleges(rows);
        });
    }, [])

    return (
        <div className="d-inline float-end position-relative">
            <FontAwesomeIcon icon={faFilter} size="1x" title={t("filter_hint")} onClick={() => { setShown(!shown); }} />
            <div className={!shown ? " d-none" : "" + "position-absolute mb-2 filter-box px-2 py-2 bg-light border"} style={{ width: "300px", marginInlineStart: "-285px" }}>
                <div className="px-2 py-2">
                    {countryFilter && (<select
                        ref={countryRef}
                        onChange={handleCountryChange}
                        className="w-100 mb-2"
                    >
                        <option key={0} value="">{t("all_countries_title")}</option>
                        {countriesList.map((u: Country) => (
                            <option key={u.id} value={u.id}>{i18n.language === "en" ? u.englishName : u.arabicName}</option>
                        ))}
                    </select>)}
                    {universityFilter && (<select
                        ref={universityRef}
                        onChange={handleUniversityChange}
                        className="w-100 mb-2"
                    >
                        <option key={0} value="">{t("all_universities_title")}</option>
                        {filteredUniversities.map((u: University) => (
                            <option key={u.id} value={u.id}>{i18n.language === "en" ? u.englishName : u.arabicName}</option>
                        ))}
                    </select>)}
                    {collegeFilter && (<select
                        ref={collegeRef}
                        onChange={handleCollegeChange}
                        className="w-100 mb-2"
                    >
                        <option key={0} value="">{t("all_colleges_title")}</option>
                        {filteredColleges.map((u: College) => (
                            <option key={u.id} value={u.id}>{i18n.language === "en" ? u.englishName : u.arabicName}</option>
                        ))}
                    </select>)}
                </div>
                <div className="float-end">
                    <Button
                        type="button"
                        variant="primary"
                        className="px-4 py-2 me-2"
                        onClick={handleFilter}
                    >
                        {t("filter_button_label")}
                    </Button>
                    <Button
                        type="button"
                        variant="secondary"
                        className="px-4 py-2 me-2"
                        onClick={(e) => setShown(false)}
                    >
                        {t("close_button_label")}
                    </Button>
                </div>
            </div>
        </div >

    );
}