import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/page-header";
import { getCourseContents, getReview } from "../../services/api";
import { Button, Table } from "react-bootstrap";
import Review from "../../models/reviewe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import PreviewDialog from "../../components/preview-dialog";
import { CourseItem } from "../../models/course_item";
import { useTranslation } from "react-i18next";

export default function ReviewDetailsPage() {
    const { id } = useParams<string>();
    const { t, i18n } = useTranslation();
    const router = useNavigate();

    const [review, setReview] = useState<Review | null>(null);
    const [courseItem, setCourseItem] = useState<CourseItem | null>(null);
    const [showPreview, setShowPreview] = useState(false);
    const [showContentPreview, setShowContentPreview] = useState(false);

    useEffect(() => {
        if (id) {
            getReview(id).then((res) => {
                if (res) {
                    setReview(res);
                    getCourseContents(id).then((items) => {
                        if (items) {
                            setCourseItem(items[0])
                        }
                    })
                }
            })
        }
    }, [id])

    const showPreviewDialog = () => {
        setShowPreview(true);
    }

    const showContentPreviewDialog = () => {
        setShowContentPreview(true);
    }

    function handleItemView(rowIndex: number): void {
        router(`content/${rowIndex}`);
    }

    function handleItemEdit(rowIndex: number): void {
        router(`content/edit/${rowIndex}`);
    }

    function handleItemDelete(rowIndex: number): void {
    }

    return review ? (
        <>
            <PageHeader title={t("review_title")} />
            <Table bordered striped>
                <tbody>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("title_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td className="w-100" colSpan={2}>
                            {review.title}
                        </td>
                    </tr>
                    {review.subtitle &&
                        <tr>
                            <td className="w-100 no-top-border" colSpan={2}>
                                {review.subtitle}
                            </td>
                        </tr>
                    }
                    <tr>
                        <td className="w-100 field-label" colSpan={2}>
                            {t("banner_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            {review.banner ? (
                                <div className="d-flex d-flex-row">
                                    <div className="w-100">
                                        {review.banner}
                                    </div>
                                    <div className="d-inline float-end">
                                        <Button type="button" variant="secondary" style={{ height: "30px" }} className="d-flex px-2 py-2 allign-items-center">
                                            <FontAwesomeIcon icon={faEye} onClick={showPreviewDialog} title="Preview" />
                                        </Button>
                                    </div>
                                </div>
                            ) : "\u00A0"}
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label">
                            {t("price_column_header")}
                        </td>
                        <td className="field-label">
                            App Store Code
                        </td>
                    </tr>
                    <tr>
                        <td className="no-bottom-border">
                            ${review.price.toFixed(3)}
                        </td>
                        <td className="no-bottom-border">
                            Apple: {review.iosCode ?? ""}<br />
                            Google: {review.androidCode ?? ""}
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("college_column_header")}
                        </td>
                    </tr>
                    <tr>
                        {i18n.language === "en"
                            ? <>
                                <td className="no-bottom-border">
                                    {review.college?.englishName}
                                    <br />
                                    {review.college?.university?.englishName}
                                    <br />
                                    {review.college?.university?.country?.englishName}
                                </td>
                                <td className="text-end no-bottom-border">
                                    {review.college?.arabicName}
                                    <br />
                                    {review.college?.university?.arabicName}
                                    <br />
                                    {review.college?.university?.country?.arabicName}
                                </td>
                            </>
                            : <>
                                <td className="no-bottom-border">
                                    {review.college?.arabicName}
                                    <br />
                                    {review.college?.university?.arabicName}
                                    <br />
                                    {review.college?.university?.country?.arabicName}
                                </td>
                                <td className="text-end no-bottom-border">
                                    {review.college?.englishName}
                                    <br />
                                    {review.college?.university?.englishName}
                                    <br />
                                    {review.college?.university?.country?.englishName}
                                </td>
                            </>
                        }
                    </tr>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("tutors_column_header")}
                        </td>
                    </tr>
                    <tr>
                        {i18n.language === "en"
                            ? <>
                                <td>
                                    {
                                        review.tutors
                                            ? review.tutors.map((t) => <p>{t.englishName}</p>)
                                            : "\u00A0"
                                    }
                                </td>
                                <td className="text-end">
                                    {
                                        review.tutors
                                            ? review.tutors.map((t) => <p>{t.arabicName}</p>)
                                            : "\u00A0"
                                    }
                                </td>
                            </>
                            : <>
                                <td>
                                    {
                                        review.tutors
                                            ? review.tutors.map((t) => <p>{t.arabicName}</p>)
                                            : "\u00A0"
                                    }
                                </td>
                                <td className="text-end">
                                    {
                                        review.tutors
                                            ? review.tutors.map((t) => <p>{t.englishName}</p>)
                                            : "\u00A0"
                                    }
                                </td>
                            </>
                        }
                    </tr>
                    <tr>
                        <td className="field-label">
                            {t("time_created_column_header")}
                        </td>
                        <td className="field-label">
                            {t("time_modified_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {
                                review.timeCreated
                                    ? review.timeCreated.toISOString()
                                    : "\u00A0"
                            }
                        </td>
                        <td>
                            {
                                review.timeModified
                                    ? review.timeModified.toISOString()
                                    : "\u00A0"
                            }
                        </td>
                    </tr>
                </tbody>
            </Table>
            <PageHeader title={t("content_title")} />
            <div className="mb-2">
                <div className="d-inline">
                    <Button variant="success" className='inline px-4 py-2' onClick={() => router(courseItem ? `content/edit/${courseItem.id}` : "content/create")}>
                        {courseItem ? t("edit_content_button_label") : t("new_content_button_label")}
                    </Button>
                </div>
            </div>
            {courseItem &&
                <Table bordered striped>
                    <tbody>
                        <tr>
                            <td className="field-label" colSpan={2}>
                                {t("title_column_header")}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                {courseItem?.title}
                            </td>
                        </tr>
                        <tr>
                            <td className="field-label" colSpan={2}>
                                {t("type_column_header")}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {courseItem?.type}
                            </td>
                        </tr>
                        <tr>
                            <td className="field-label" colSpan={2}>
                                {t("url_column_header")}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                {courseItem?.url ? (
                                    <div className="d-flex d-flex-row">
                                        <div className="w-100">
                                            {courseItem.url}
                                        </div>
                                        <div className="d-inline float-end">
                                            <Button type="button" variant="secondary" style={{ height: "30px" }} className="d-flex px-2 py-2 allign-items-center">
                                                <FontAwesomeIcon icon={faEye} onClick={showContentPreviewDialog} title="Preview" />
                                            </Button>
                                        </div>
                                    </div>
                                ) : "\u00A0"}
                            </td>
                        </tr>
                        <tr>
                            <td className="field-label" colSpan={2}>
                                {t("size_column_header")}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {courseItem?.length ? courseItem?.length : "\u00A0"}
                            </td>
                        </tr>
                        <tr>
                            <td className="field-label" colSpan={2}>
                                {t("free_column_header")}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                {courseItem?.free ? t("yes") : t("no")}
                            </td>
                        </tr>
                        <tr>
                            <td className="field-label" colSpan={2}>
                                {t("active_column_header")}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                {courseItem?.active ? t("yes") : t("no")}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            }
            <div className="mt-3 float-end">
                <Button type="button" variant="secondary" className="px-4 py-2"
                    onClick={() => { router("/reviews") }}>
                    {t("back_button_label")}
                </Button>
            </div>

            <PreviewDialog
                show={showPreview}
                url={review?.banner}
                onHide={() => setShowPreview(false)}
            />

            <PreviewDialog
                show={showContentPreview}
                url={courseItem?.url ?? ""}
                type={courseItem?.type}
                onHide={() => setShowContentPreview(false)}
            />

            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </>
    ) : (
        <div>
            Loading...
        </div>
    );
};