import { DocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { TicketStatus } from "../enums/TicketStatus";

export class Ticket {
    id?: string;
    subject: string = "";
    message: string = "";
    status: TicketStatus = TicketStatus.created;
    timeCreated: Date = new Date()
}

export const ticketConverter = {
    toFirestore: (ticket: Ticket) => {
        return {
            subject: ticket?.subject,
            message: ticket?.message,
            status: ticket?.status,
            timeCreated: ticket?.timeCreated,
        };
    },
    fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
        const data = snapshot.data(options);

        let ticket = new Ticket();
        ticket.id = snapshot.id;
        ticket.subject = data?.subject;
        ticket.message = data?.message;
        ticket.status = data?.status;

        if (data?.timeCreated) {
            ticket.timeCreated = data?.timeCreated.toDate();
        }

        return ticket;
    }
}