import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, onSnapshot, query } from "firebase/firestore";
import ActionPane from "../../components/action-pane";
import { DataGrid } from "../../components/data-grid";
import { db } from "../../firebase";
import DeleteDialog from "../../components/delete-dialog";
import { Ticket, ticketConverter } from "../../models/ticket";
import { TicketStatus } from "../../enums/TicketStatus";
import { useTranslation } from "react-i18next";

export default function TicketsPage() {
    const navigate = useNavigate()
    const { t } = useTranslation();

    const [data, setData] = useState<Ticket[]>([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);


    const handleEdit = (row: any) => {
        navigate(`edit/${row.id}`);
    }

    const handleView = (row: any) => {
        navigate(`${row.id}`);
    }

    useEffect(() => {
        const q = query(collection(db, "tickets")).withConverter(ticketConverter);

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const items: Ticket[] = [];
            querySnapshot.forEach((doc) => {
                items.push(doc.data());
            });

            setData(items);
        });

        return unsubscribe;
    }, [])

    return (
        <>
            <h2 className="mb-4">{t("tickets_title")}</h2>
            <DataGrid
                keyField='id'
                data={data}
                actions={[]}
                actionsPaneWidth='120px'
                columns={[
                    {
                        dataField: 'statusIndicator',
                        text: '',
                        isDummyField: true,
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '40px%',
                                textAlign: "center",
                            };
                        },
                        classes: (cell: any, row: any, rowIndex: number, colIndex: number) => {
                            let classNames = "bg-primary";

                            if (row.status === TicketStatus.created) {
                                classNames = "bg-danger";
                            } else if (row.status === TicketStatus.closed) {
                                classNames = "bg-success";
                            }

                            return classNames;
                        },
                    },
                    {
                        dataField: 'subject',
                        text: t("subject_column_header"),
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '100%',
                                textAlign: "center",
                            };
                        },
                    },
                    {
                        headerStyle: (column: any, colIndex: number) => {
                            return { width: '120px', maxWidth: "120px", minWidth: '120px', textAlign: 'center' };
                        },
                        dataField: 'dummy_field',
                        isDummyField: true,
                        text: t("actions_column_header"),
                        sort: true,
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return (
                                <div style={{ textAlign: 'center' }}>
                                    <ActionPane
                                        onView={() => handleView(row)}
                                        onEdit={() => handleEdit(row)}
                                        onDelete={() => {
                                            setSelectedRow(row);
                                            setShowDeleteDialog(true);
                                        }}
                                    />
                                </div>
                            )
                        }
                    },
                ]}
            />

            <DeleteDialog
                visible={showDeleteDialog}
                row={selectedRow}
                onConfirm={() => { }}
                onHide={() => setShowDeleteDialog(false)}
            />
        </>
    );
}