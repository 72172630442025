import { DocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { QuestionType } from "../enums/QuestionType";
import { Answer, answerConverter } from "./answer";

export class Question {
    id?: string;
    body: string = "";
    type: QuestionType = QuestionType.multiplechoicesingle;
    grade?: number = 0;

    answers: Answer[] = [];
}

export const quesgtionConverter = {
    toFirestore: (question: Question) => {

        const answers = question?.answers.map((a) => answerConverter.toFirestore(a));

        return {
            id: question?.id,
            body: question?.body,
            type: question?.type,
            grade: question?.grade ?? null,
            answers: answers,
        };
    },
    fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
        const data = snapshot.data(options);

        let question = new Question();
        question.id = snapshot.id;
        question.body = data?.body;
        question.type = data?.type;
        question.grade = parseInt(data?.grade as string);

        // if (data?.timeCreated) {
        //     quiz.timeCreated = data?.timeCreated.toDate();
        // }

        // if (data?.timeModified) {
        //     quiz.timeModified = data?.timeModified.toDate();
        // }

        return question;
    }
};