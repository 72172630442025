import { ReactNode } from "react";
import { useLayoutDirection } from "./LayoutDirectionProvider";
import { Container, ThemeProvider } from "react-bootstrap";

interface HomeLayoutProps {
    children?: ReactNode
}

export const HomeLayout = (props: HomeLayoutProps) => {
    const { isRTL } = useLayoutDirection();

    return (
        <>
            <link
                rel="stylesheet"
                type="text/css"
                href={`/css/${isRTL() ? "bootstrap.rtl.min.css" : "bootstrap.min.css"}`}
            />

            <ThemeProvider dir={isRTL() ? "rtl" : "ltr"} >
                <Container className="mt-4">
                    {props.children}
                </Container>
            </ThemeProvider >
        </>
    )

}