import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../components/page-header";
import { db } from "../../firebase";
import CountryForm from "../../components/country-form";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SavingIndicator from "../../components/saving-indicator";
import { useState } from "react";

export default function CountryCreatePage() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (data: any) => {
        setLoading(true);

        try {
            // Add dates
            data.timeCreated = serverTimestamp();
            data.timeModified = serverTimestamp();
            data.deleted = false;

            const countriesCollection = collection(db, 'countries');

            await addDoc(countriesCollection, data);

            navigate("/admin/countries");
        } catch (e) {
            console.log(e);
            setError("Error!!");
            setLoading(false);
        }
    }

    return (
        <>
            <PageHeader title={t("add_country_title")} />
            <Container fluid>
                {error &&
                    <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                        {error}
                    </div>
                }
                {loading
                    ? <SavingIndicator />
                    : <CountryForm
                        target={null}
                        onSubmit={handleSubmit}
                        onCancel={() => navigate("/admin/countries")}
                    />
                }
            </Container>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </>
    );
};