import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";
import { Country } from "../models/country";
import { CountrySchema } from "../services/forms-schemas";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface CountryFormProps {
    target: Country | null,
    onSubmit?(data: any): void,
    onCancel?(): void,
}

export default function CountryForm(props: CountryFormProps) {

    const { target: target = new Country() } = props;
    const { t, i18n } = useTranslation();

    type Inputs = z.infer<typeof CountrySchema>;

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors } } = useForm<Inputs>({
            resolver: zodResolver(CountrySchema)
        });

    const processForm: SubmitHandler<Inputs> = data => {

        const objectData = {
            arabicName: data.arabicName,
            englishName: data.englishName,
            countryCode: data.countryCode,
            active: data.active,
        }

        if (props.onSubmit) {
            props?.onSubmit(objectData);
        }

        reset();
    }

    return (
        <form className="w-100" method="post" onSubmit={handleSubmit(processForm)}>
            <Table bordered striped>
                <tbody>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("name_column_header")}
                        </td>
                    </tr>
                    <tr>
                        {i18n.language === "en" &&
                            <>
                                <td>
                                    <input
                                        className="w-100"
                                        placeholder="Enter name (English)"
                                        defaultValue={target?.englishName}
                                        {...register("englishName", { value: target?.englishName, required: true })}
                                    />
                                    {errors.englishName?.message && (
                                        <p className="small text-danger">{errors.englishName.message}</p>
                                    )}
                                </td>
                                <td>
                                    <input
                                        className="w-100"
                                        dir="rtl"
                                        placeholder="Enter name (Arabic)"
                                        defaultValue={target?.arabicName}
                                        {...register("arabicName", { value: target?.arabicName, required: true })}
                                    />
                                    {errors.arabicName?.message && (
                                        <p className="small text-danger">{errors.arabicName.message}</p>
                                    )}
                                </td>
                            </>
                        }
                        {i18n.language === "ar" &&
                            <>
                                <td>
                                    <input
                                        className="w-100 text-start"
                                        placeholder="Enter name (Arabic)"
                                        defaultValue={target?.arabicName}
                                        {...register("arabicName", { value: target?.arabicName, required: true })}
                                    />
                                    {errors.arabicName?.message && (
                                        <p className="small text-danger">{errors.arabicName.message}</p>
                                    )}
                                </td>
                                <td className="text-end">
                                    <input
                                        className="w-100 text-end"
                                        placeholder="Enter name (English)"
                                        defaultValue={target?.englishName}
                                        {...register("englishName", { value: target?.englishName, required: true })}
                                    />
                                    {errors.englishName?.message && (
                                        <p className="small text-danger">{errors.englishName.message}</p>
                                    )}
                                </td>
                            </>
                        }
                    </tr>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("country_code")}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} className={i18n.language === "ar" ? "text-end" : "text-start"}>
                            <input
                                className={`w-100 ${i18n.language === "ar" ? "text-end" : "text-start"}`}
                                placeholder="Enter code"
                                defaultValue={target?.countryCode}
                                {...register("countryCode", { value: target?.countryCode, required: true })}
                            />
                            {errors.countryCode?.message && (
                                <p className="small text-danger">{errors.countryCode.message}</p>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("active_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <input type="checkbox"
                                {...register("active")}
                                defaultChecked={target?.active}
                            />
                            {errors.active?.message && (
                                <p className="small text-danger">{errors.active?.message}</p>
                            )}
                        </td>
                    </tr>
                </tbody>
            </Table>
            <div className="mt-3 float-end">
                <Button
                    variant="primary"
                    type="submit"
                    className="me-3 py-2 px-4"
                >
                    {t("save_button_label")}
                </Button>
                <Button
                    variant="secondary"
                    type="button"
                    className="py-2 px-4"
                    onClick={props.onCancel}
                >
                    {t("cancel_button_label")}
                </Button>
            </div>
        </form >
    );
}