import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/page-header";
import { Tutor } from "../../models/tutor";
import { getTutor } from "../../services/api";
import { Button, Container, Table } from "react-bootstrap";
import CoursesDataGrid from "../../components/courses-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PreviewDialog from "../../components/preview-dialog";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function TutorDetailsPage() {
    const { id } = useParams<string>();
    const { t, i18n } = useTranslation();
    const [tutor, setTutor] = useState<Tutor | null>(null);
    const [showPreview, setShowPreview] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            getTutor(id).then((res) => {
                setTutor(res);
            })
        }
    })

    const showPreviewDialog = () => {
        setShowPreview(true);
    }

    return (
        <>
            <PageHeader title={t("tutor_title")} />
            <Container fluid>
                {tutor ? (
                    <>
                        <Table bordered striped>
                            <tbody>
                                <tr>
                                    <td className="w-100 field-label" colSpan={2}>
                                        {t("name_column_header")}
                                    </td>
                                </tr>
                                <tr>
                                    {i18n.language === "en" &&
                                        <>
                                            <td className="w-50 text-start">
                                                {tutor.englishName}
                                            </td>
                                            <td className="w-50 text-end">
                                                {tutor.arabicName}
                                            </td>
                                        </>
                                    }
                                    {i18n.language === "ar" &&
                                        <>
                                            <td className="w-50 text-start">
                                                {tutor.arabicName}
                                            </td>
                                            <td className="w-50 text-end">
                                                {tutor.englishName}
                                            </td>
                                        </>
                                    }
                                </tr>
                                <tr>
                                    <td className="w-100 field-label" colSpan={2}>
                                        {t("photo_column_header")}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        {tutor.photoUrl ? (
                                            <div className="d-flex d-flex-row">
                                                <div className="w-100">
                                                    {tutor.photoUrl}
                                                </div>
                                                <div className="d-inline float-end">
                                                    <Button type="button" variant="secondary" style={{ height: "30px" }} className="d-flex px-2 py-2 allign-items-center">
                                                        <FontAwesomeIcon icon={faEye} onClick={showPreviewDialog} title="Preview" />
                                                    </Button>
                                                </div>
                                            </div>
                                        ) : "\u00A0"}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="field-label" colSpan={2}>
                                        {t("active_column_header")}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        {tutor.active ? t("yes") : t("no")}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="field-label">
                                        {t("time_created_column_header")}
                                    </td>
                                    <td className="field-label">
                                        {t("time_modified_column_header")}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {
                                            tutor.timeCreated
                                                ? tutor.timeCreated.toISOString()
                                                : "\u00A0"
                                        }
                                    </td>
                                    <td>
                                        {
                                            tutor.timeModified
                                                ? tutor.timeModified.toISOString()
                                                : "\u00A0"
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <PageHeader title={t("courses_title")} />
                        <CoursesDataGrid
                            mode="tutor"
                            id={tutor.id}
                            enableCreating={false}
                            enableFiltering={false}
                        />
                        <PageHeader title={t("reviews_title")} />
                        <CoursesDataGrid
                            type="reviews"
                            mode="tutor"
                            id={tutor.id}
                            enableCreating={false}
                            enableFiltering={false}
                        />
                        <div className="mt-3 float-end">
                            <Button type="button" variant="secondary" className="px-4 py-2"
                                onClick={() => { navigate("/admin/tutors") }}>
                                {t("back_button_label")}
                            </Button>
                        </div>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <PreviewDialog
                            show={showPreview}
                            url={tutor?.photoUrl}
                            onHide={() => setShowPreview(false)}
                        />
                    </>
                ) : (
                    <div>Loading...</div>
                )}
            </Container >
        </>
    )
};