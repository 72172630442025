import { DocumentReference, DocumentSnapshot, SnapshotOptions, getDoc } from "firebase/firestore";
import { TicketStatus } from "../enums/TicketStatus";
import { Student, studentConverter } from "./Student";

export class TicketReply {
    id?: string;
    message: string = "";
    owner?: Student;
    status: TicketStatus = TicketStatus.created;
    timeCreated: Date = new Date()
}

export const ticketReplyConverter = {
    toFirestore: (ticket: TicketReply) => {
        return {
            message: ticket?.message,
            status: ticket?.status,
            timeCreated: ticket?.timeCreated,
        };
    },
    fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
        const data = snapshot.data(options);

        let ticket = new TicketReply();
        ticket.id = snapshot.id;
        ticket.message = data?.message;
        ticket.status = data?.status;

        if (data?.timeCreated) {
            ticket.timeCreated = data?.timeCreated.toDate();
        }

        return ticket;
    }
}