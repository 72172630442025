import { faPlus, faRemove } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { Tutor } from "../models/tutor";
import { getAllTutors } from "../services/api";
import AddTutorDialog from "./add-tutor-dialog";
import { useTranslation } from "react-i18next";

interface TutorsPickerProps {
    data?: Tutor[],
    onAdd?: ((tutor?: Tutor[]) => void) | undefined,
    onRemove?: ((tutor?: Tutor) => void) | undefined,
}

export default function TutorsPicker(props: TutorsPickerProps): JSX.Element {
    const { data = [], onAdd = null, onRemove = null } = props;

    const { t } = useTranslation();

    const [tutorsList, setTutorsList] = useState<Tutor[]>([]);
    const [showDialog, setShowDialog] = useState(false);
    const [tutors, setTutors] = useState<Tutor[]>(data);

    const handleAdding = (tutor?: Tutor[]) => {
        if (tutor) {
            const tx: Tutor[] = tutor.map((t) => {
                // First we need to remove the selected item from selectables list
                const index = tutorsList.indexOf(t);
                if (index > -1) {
                    tutorsList.splice(index, 1);
                }

                // Assign the new selectables list
                setTutorsList(tutorsList);

                return t;
            });

            setTutors([...tutors as Tutor[], ...tx]);

            if (props?.onAdd) {
                props?.onAdd(tx);
            }
        }

        setShowDialog(false);
    }

    const restoreTutor = (tutor: Tutor) => {
        setTutorsList([...tutorsList, tutor]);
        // First we need to remove the selected item from selected list
        const index = tutors.indexOf(tutor);
        if (index > -1) {
            tutors.splice(index, 1);
        }

        // Assign the new selected list
        setTutors(tutors);

        if (props.onRemove) {
            props?.onRemove(tutor);
        }
    }

    useEffect(() => {
        getAllTutors().then((t) => setTutorsList(t))
    }, []);

    return (
        <>
            <tr>
                <td className="field-label" colSpan={2}>
                    <p className="d-inline me-3">{t("tutors_column_header")}</p>
                    <FontAwesomeIcon
                        icon={faPlus}
                        onClick={() => setShowDialog(true)}
                    />
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    {tutors && tutors.length > 0 ? tutors.map((tutor) => (
                        <p key={tutor.id}>
                            <FontAwesomeIcon
                                icon={faRemove}
                                className="text-danger-700 ms-2 me-3"
                                onClick={() => restoreTutor(tutor)}
                            />
                            {tutor.englishName} | {tutor.arabicName}
                        </p>
                    )) : "\u00A0"}
                    <AddTutorDialog
                        data={tutorsList}
                        show={showDialog}
                        onHide={() => setShowDialog(false)}
                        onAdd={handleAdding}
                    />
                </td>
            </tr>

        </>
    );

}