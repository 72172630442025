import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/page-header";
import { getOrder } from "../../services/api";
import { Button, Table } from "react-bootstrap";
import Order from "../../models/order";
import { useTranslation } from "react-i18next";

export default function OrderDetailsPage() {
    const { id } = useParams<string>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [order, setOrder] = useState<Order | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [showPreview, setShowPreview] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    useEffect(() => {
        if (id) {
            getOrder(id).then((res) => {
                setOrder(res);
            });
        }
    }, [])

    const showPreviewDialog = () => {
        setShowPreview(true);
    }

    return order ? (
        <>
            <PageHeader title={t("order_title")} />
            <Table bordered striped>
                <tbody>
                    <tr>
                        <td className="w-50 field-label">
                            {t("invoice_column_header")}
                        </td>
                        <td className="w-50 field-label">
                            {t("date_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td className="w-50">
                            {order.invoiceNo}
                        </td>
                        <td className="w-50">
                            {order.date.toLocaleString()}
                        </td>
                    </tr>
                </tbody>
            </Table>
            <p>&nbsp;</p>
            <Table bordered striped>
                <thead>
                    <th>{t("item_column_header")}</th>
                    <th>{t("unit_price_column_header")}</th>
                    <th>{t("discount_column_header")}</th>
                    <th>{t("quantity_column_header")}</th>
                    <th>{t("total_column_header")}</th>
                </thead>
                <tbody>
                    {order.items.map((item) => {
                        return (
                            <tr>
                                <td>
                                    <span>{item.title}</span>
                                </td>
                                <td style={{ width: "120px" }}>
                                    <span>{item.price.toFixed(3)}</span>
                                </td>
                                <td style={{ width: "120px" }}>
                                    <span>{item.discount?.toFixed(3)}</span>
                                </td>
                                <td className="text-center" style={{ width: "120px" }}>
                                    <span>1</span>
                                </td>
                                <td style={{ width: "120px" }}>
                                    <span>{item.price.toFixed(3)}</span>
                                </td>
                            </tr>
                        )
                    })}
                    <tr>
                        <td className="text-end" colSpan={4}>
                            {t("total_column_header")}
                        </td>
                        <td>
                            {order.total.toFixed(3)}
                        </td>
                    </tr>
                </tbody>
            </Table>
            <div className="mt-3 float-end">
                <Button type="button" variant="secondary" className="px-4 py-2"
                    onClick={() => { navigate("/admin/orders") }}>
                    {t("back_button_label")}
                </Button>
            </div>

            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </>
    ) : (
        <div>
            Loading...
        </div>
    );
};