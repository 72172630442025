import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
// import overlayFactory from "react-bootstrap-table2-overlay";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit, faEye, faClose } from "@fortawesome/free-solid-svg-icons";
// import Button from "react-bootstrap/Button";
// import DeleteDialog from "./DeleteDialog";
import filterFactory, { textFilter, selectFilter } from "react-bootstrap-table2-filter";

interface DataGridProps {
    keyField: string,
    data: any[],
    columns: any[],
    actions?: any[],
    showView?: boolean,
    showEdit?: boolean,
    showDelete?: boolean,
    onView?(row: any): void,
    onEdit?(row: any): void,
    onDelete?(row: any): void,
    canEdit?(row: any): boolean,
    canDelete?(row: any): boolean,
    actionsPaneWidth?: string,
};

export const DataGrid: React.FC<DataGridProps> = (props: DataGridProps) => {

    // Default props
    const {
        keyField = '',
        data = [],
        columns = [],
        actions = [],
        showView = true,
        showEdit = true,
        showDelete = true,
        onView = null,
        onEdit = null,
        onDelete = null,
        canEdit = null,
        canDelete = null,
        actionsPaneWidth = "120px",
    } = props;

    const [showModal, setShowModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    library.add(faEdit, faEye, faClose);

    const handleDeleteConfirmation = (row: any) => {
        setSelectedRow(row);
        setShowModal(true);
    };

    const handleDelete = () => {
        onDelete && onDelete(selectedRow);
    };

    const checkCanEdit = (row: any) => {
        return canEdit ? canEdit(row) : true;
    };

    const checkCanDelete = (row: any) => {
        return (canEdit && canEdit(row)) ? canDelete?.(row) : true;
    };

    // const actionsFormatter = (data: any[], row: any[]) => {
    //     const standardActions = [
    //         {
    //             id: 1,
    //             title: "View",
    //             icon: faEye,
    //             visible: showView,
    //             enabled: true,
    //             onClick: () => onView?.(row),
    //         },
    //         {
    //             id: 2,
    //             title: "Edit",
    //             icon: faEdit,
    //             visible: showEdit,
    //             enabled: checkCanEdit(row),
    //             onClick: () => onEdit?.(row),
    //         },
    //         {
    //             id: 3,
    //             title: "Delete",
    //             icon: faClose,
    //             visible: showDelete,
    //             enabled: checkCanDelete(row),
    //             onClick: () => handleDeleteConfirmation(row),
    //         },
    //     ];

    //     const actionsArr = [...standardActions, ...actions];

    //     return (
    //         <></>
    //     );
    //     // return (
    //     //     <div>
    //     //         {actionsArr.map((action) => {
    //     //             return action.visible ? (
    //     //                 action.enabled ? (
    //     //                     <Button
    //     //                         key={action.id}
    //     //                         className="icon-button"
    //     //                         title={action.title}
    //     //                         onClick={action.onClick}
    //     //                     >
    //     //                         <FontAwesomeIcon icon={action.icon} />
    //     //                     </Button >
    //     //                 ) : (
    //     //                     <Button
    //     //                         key={action.id}
    //     //                         className="icon-button disabled"
    //     //                     >
    //     //                         <FontAwesomeIcon icon={action.icon} />
    //     //                     </Button>
    //     //                 )
    //     //             ) : (
    //     //                 <></>
    //     //             );
    //     //         })}
    //     //     </div>
    //     // );
    // };

    // const actionsColumn = {
    //     isDummyField: true,
    //     dataField: "actions_id",
    //     text: "",
    //     headerStyle: (column: any, colIndex: number) => {
    //         return {
    //             width: actionsPaneWidth,
    //             minWidth: actionsPaneWidth,
    //             maxWidth: actionsPaneWidth,
    //             textAlign: "center",
    //         };
    //     },
    //     formatter: actionsFormatter,
    // };

    // const [dataColumns] = useState(() => {
    // if (columns && columns.indexOf(actionsColumn) === -1) {
    //     columns.push(actionsColumn);
    // }

    // return columns;
    // });

    return (
        <>
            <BootstrapTable
                keyField={keyField}
                data={data}
                columns={columns}
                striped={true}
                bordered={true}
                hover={true}
                condensed={true}
                bootstrap4={true}
                pagination={paginationFactory({})}
                filter={filterFactory()}
                noDataIndication={() => (
                    <div className="flex justify-center">
                        <p>No Data</p>
                    </div>
                )}
            />
            {/* <DeleteDialog
                visible={showModal}
                row={selectedRow}
                onHide={() => setShowModal(false)}
                onConfirm={handleDelete}
            /> */}
        </>
    );
}

