import { useState, useRef, FormEvent, useEffect } from "react";
import { College } from "../models/college";
import { Country } from "../models/country";
import { University } from "../models/university";
import { fetchCountryUniversities, fetchUniversityColleges, getAllCountries } from "../services/api";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface SelectCollegeDialogProps {
    show: boolean,
    onHide?: () => void,
    onSelect?: (country: Country, university: University, college: College) => void,
}

export default function SelectCollegeDialog(props: SelectCollegeDialogProps) {
    let { show = false, onHide = undefined, onSelect = undefined } = props;
    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(show);

    const [countryList, setCountryList] = useState<Country[]>([]);
    const [universityList, setUniversityList] = useState<University[]>([]);
    const [collegeList, setCollegeList] = useState<College[]>([]);

    const countryRef = useRef<HTMLSelectElement>(null);
    const universityRef = useRef<HTMLSelectElement>(null);
    const collegeRef = useRef<HTMLSelectElement>(null);

    const handleCountryChange = (event: FormEvent<HTMLSelectElement>) => {
        const cid = event.currentTarget?.value;

        if (universityRef.current?.value) {
            universityRef.current.value = "";
        }

        if (collegeRef.current?.value) {
            collegeRef.current.value = "";
        }

        updateUniversityList(cid);
    }

    const updateUniversityList = async (cid: string) => {
        const country: Country | undefined = countryList.find((c: Country) => c.id === cid);

        if (country) {
            const universityList = await fetchCountryUniversities(country);
            setUniversityList(universityList);
        }
    }

    const handleUniversityChange = (event: FormEvent<HTMLSelectElement>) => {
        const uid = event.currentTarget?.value;

        if (collegeRef.current?.value) {
            collegeRef.current.value = "";
        }

        updateCollegeList(uid);
    }

    const updateCollegeList = async (cid: string) => {
        const university: University | undefined = universityList.find((c: University) => c.id === cid);

        if (university) {
            const colleges = await fetchUniversityColleges(university);
            setCollegeList(colleges);
        }
    }

    const handleSelectCollege = () => {
        if (onSelect) {
            if (countryRef.current?.selectedIndex && countryRef.current?.selectedIndex > 0 &&
                universityRef.current?.selectedIndex && universityRef.current?.selectedIndex > 0 &&
                collegeRef.current?.selectedIndex && collegeRef.current?.selectedIndex > 0) {

                const selectedCountry = countryList[countryRef.current?.selectedIndex - 1];
                const selectedUniversity = universityList[universityRef.current?.selectedIndex - 1];
                const selectedCollege = collegeList[collegeRef.current?.selectedIndex - 1];

                onSelect(selectedCountry, selectedUniversity, selectedCollege);
            }
        }
    }

    useEffect(() => {
        getAllCountries().then((res) => {
            setCountryList(res);
        });

        setUniversityList([]);
        setCollegeList([]);

        setShowModal(show);
    }, [show])

    return (
        <Modal show={showModal} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t("select_college_title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    <select
                        name="countries"
                        id="countries"
                        className="w-100"
                        ref={countryRef}
                        onChange={handleCountryChange}
                    >
                        <option key={0} value="">{t("select_country_title")}</option>
                        {countryList && countryList.map((country: Country) => (
                            <option key={country.id} value={country.id}>
                                {country.englishName} | {country.arabicName}
                            </option>
                        ))}
                    </select>
                </p>
                <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    <select
                        name="universities"
                        id="universities"
                        className="w-100"
                        ref={universityRef}
                        onChange={handleUniversityChange}
                    >
                        <option key={0} value="">{t("select_university_title")}</option>
                        {universityList && universityList.map((university: University) => (
                            <option key={university.id} value={university.id}>
                                {university.englishName} | {university.arabicName}
                            </option>
                        ))}
                    </select>
                </p>
                <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    <select
                        name="colleges"
                        id="colleges"
                        className="w-100"
                        ref={collegeRef}
                    >
                        <option key={0} value="">{t("select_college_title")}</option>
                        {collegeList && collegeList.map((college) => (
                            <option key={college.id} value={college.id}>
                                {college.englishName} | {college.arabicName}
                            </option>
                        ))}
                    </select>
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    {t("cancel_button_label")}
                </Button>
                <Button variant="primary" onClick={handleSelectCollege}>
                    {t("select_button_label")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}