import { zodResolver } from "@hookform/resolvers/zod";
import { doc, collection, getDocs, query, orderBy, QueryDocumentSnapshot } from "firebase/firestore";
import { uploadBytes, ref } from "firebase/storage";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useForm, SubmitHandler, SubmitErrorHandler } from "react-hook-form";
import { z } from "zod";
import { db, storage } from "../firebase";
import { College } from "../models/college";
import Course from "../models/course";
import { Tutor } from "../models/tutor";
import { CourseSchema } from "../services/forms-schemas";
import { v4 as uuidv4 } from "uuid";
import CollegePicker from "./college-picker";
import TutorsPicker from "./tutors-picker";
import { Button, Table } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { getTutor } from "../services/api";
import Price, { priceConverter } from "../models/price";
import { useTranslation } from "react-i18next";

interface CourseFormProps {
    target: Course | null,
    onSubmit?(data: any): void,
    onCancel?(): void,
}

export default function CourseForm(props: CourseFormProps) {
    const {
        target = null
    } = props;

    const { t } = useTranslation();
    const { currentUser, userRole } = useAuth();
    const bannerRef = useRef<HTMLInputElement>(null);
    const [college, setCollege] = useState<College | null>(null);
    const [file, setFile] = useState<File>()
    const [prices, setPrices] = useState<any[]>([])

    const tutors: Tutor[] = target?.tutors ? target.tutors as Tutor[] : [];

    type Inputs = z.infer<typeof CourseSchema>;

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors } } = useForm<Inputs>({
            resolver: zodResolver(CourseSchema)
        });

    useEffect(() => {
        if (userRole === "tutor") {
            if (currentUser != null) {
                getTutor(currentUser.uid).then((tutor) => {
                    tutors.push(tutor as Tutor);
                    setValue("tutors", tutors.map((e) => e.id as string));
                })
            }
        }

        const pricesRef = collection(db, "prices").withConverter(priceConverter);
        const q = query(pricesRef, orderBy("usd"));

        getDocs(q).then((docs) => {
            setPrices(docs.docs);
        });

        setValue("active", target?.active ?? false);
    })

    const handlePriceChange = (e: FormEvent<HTMLSelectElement>) => {

        const selectedPrice = prices.filter((price: QueryDocumentSnapshot) => {
            const data = price.data();
            return data.usd === parseFloat(e.currentTarget.value);
        });

        const priceData = selectedPrice[0].data();

        setValue("iosCode", priceData.id);
        setValue("androidCode", priceData.id.toLowerCase());
    }

    const processForm: SubmitHandler<Inputs> = async data => {
        // We need only to check file for new courses
        // existing courses alread has a file uploaded
        if (!target && !file) return

        let bannerUrl = target?.banner;

        if (file) {
            const fileId = uuidv4()
            bannerUrl = `banners/${fileId}-${file.name}`
            const storageRef = ref(storage, bannerUrl)
            // const { metadata } = 
            await uploadBytes(storageRef, file);
            // bannerUrl = await getDownloadURL(storageRef);
            // const { fullPath } = metadata
        }

        const tutorsRefs = data.tutors.map((t) => {
            return doc(collection(db, "tutors"), t);
        });

        const collegeRef = doc(collection(db, "colleges"),
            college ? college?.id : target?.college?.id);

        const objectData = {
            title: data.title,
            subtitle: data.subtitle,
            description: data.description,
            price: data.price,
            discount: data.discount,
            type: data.type,
            banner: bannerUrl,
            active: data.active,
            deleted: data.deleted,
            iosCode: data.iosCode,
            androidCode: data.androidCode?.toLowerCase(),
            tutors: tutorsRefs,
            college: collegeRef,
        };

        if (props.onSubmit) {
            props?.onSubmit(objectData);
        }

        reset();
    }

    const handleSelectCollege = (college: College) => {
        if (college) {
            setValue("college", college?.id as string);
            setCollege(college);
        }
    }

    const handleAddTuter = (tutor?: Tutor[]) => {
        if (tutor) {
            tutors.push(...tutor);
            setValue("tutors", tutors.map((e) => e.id as string));
        }
    }

    const handleRemoveTutor = (tutor?: Tutor) => {
        if (tutor) {
            const index = tutors.indexOf(tutor);
            if (index > -1) {
                tutors.splice(index, 1);
            }

            setValue("tutors", tutors.map((e) => e.id as string));
        }
    }

    const handleFormErrors: SubmitErrorHandler<Inputs> = errors => {
        console.log(errors);
    }

    return (
        <>
            <form className="w-100" method="post" onSubmit={handleSubmit(processForm, handleFormErrors)}>
                <input
                    type="hidden"
                    {...register("id", { value: target?.id ?? undefined })}
                />
                <input
                    type="hidden"
                    {...register("discount", { value: target?.discount ?? 0 })}
                />
                <input
                    type="hidden"
                    {...register("deleted", { value: target?.deleted ?? false })}
                />
                <input
                    type="hidden"
                    {...register("iosCode", { value: target?.iosCode })}
                />
                <input
                    type="hidden"
                    {...register("androidCode", { value: target?.androidCode })}
                />
                <input
                    type="hidden"
                    {...register("college", { value: target?.college?.id })}
                />
                <input
                    type="hidden"
                    {...register("tutors", { value: target?.tutors.map((e) => e.id as string) })}
                />
                <Table bordered striped responsive>
                    <tbody>
                        <tr>
                            <td className="field-label" colSpan={2}>
                                {t("title_column_header")}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <input
                                    className="w-100"
                                    id="title"
                                    type="text"
                                    defaultValue={target?.title}
                                    placeholder="Enter title"
                                    {...register("title", { value: target?.title, required: true })}
                                />
                                {errors.title?.message && (
                                    <p className="small text-danger">{errors.title.message}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="field-label" colSpan={2}>
                                {t("subtitle_column_header")}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <input
                                    className="w-100"
                                    type="text"
                                    defaultValue={target?.subtitle ?? ""}
                                    placeholder="Enter subtitle"
                                    {...register("subtitle", { value: target?.subtitle })}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="field-label" colSpan={2}>
                                {t("description_column_header")}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <textarea
                                    className="w-100"
                                    defaultValue={target?.description ?? ""}
                                    placeholder="Enter description"
                                    {...register("description", { value: target?.description })}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="field-label" colSpan={2}>
                                {t("banner_column_header")}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <input
                                    type="hidden"
                                    {...register("banner", { value: target?.banner })}
                                />
                                <input type="file"
                                    accept=".png,.jpg,.jpeg"
                                    className="w-100"
                                    name="bannerUploader"
                                    placeholder="Select banner"
                                    ref={bannerRef}
                                    onChange={(e) => {
                                        if (e.target.files?.[0]) {
                                            setFile(e.target.files?.[0]);
                                            setValue("banner", e.target.files[0]?.name);
                                        }
                                    }}
                                />
                                {target && target.banner &&
                                    <div>{target?.banner.substring(0, 100)}...</div>
                                }
                                {errors.banner?.message && (
                                    <p className="small text-danger">{errors.banner.message}</p>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="field-label" colSpan={2}>
                                {t("price_column_header")}
                            </td>
                            {/* <td className="field-label">
                                Discount
                            </td> */}
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <select
                                    className="w-100"
                                    {...register("price", { required: true, value: target?.price, valueAsNumber: true, })}
                                    defaultValue={target?.price}
                                    onChange={handlePriceChange}
                                >
                                    <option key={0} value="">{t("select_price_title")}</option>
                                    {prices && prices.map((e) => {
                                        var price: Price = e.data();

                                        return (
                                            <option
                                                key={price.id}
                                                value={price.usd}
                                                selected={price.usd === target?.price}
                                            >
                                                ${price.usd.toFixed(2)} =&gt; JOD {price.jod.toFixed(3)}
                                            </option>
                                        )
                                    })}
                                </select>
                                {errors.price?.message && (
                                    <p className="small text-danger">{errors.price.message}</p>
                                )}
                            </td>
                        </tr>
                        <CollegePicker
                            data={target?.college}
                            onSelect={handleSelectCollege}
                        />
                        {errors.college?.message && (
                            <tr>
                                <td colSpan={2}>
                                    <p className="small text-danger">{errors.college.message}</p>
                                </td>
                            </tr>
                        )}
                        {userRole !== "tutor" &&
                            <TutorsPicker
                                data={target?.tutors}
                                onAdd={handleAddTuter}
                                onRemove={handleRemoveTutor}
                            />
                        }
                        {/* <tr>
                            <td className="field-label" colSpan={2}>
                                Active
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <input type="checkbox"
                                    defaultChecked={target?.active}
                                    {...register("active", {
                                        value: target?.active,
                                        // setValueAs: (v) => v == "" ? null : parseFloat(v)
                                    })}
                                />
                            </td>
                        </tr> */}
                    </tbody>
                </Table >

                {errors.tutors?.message && (
                    <p className="small text-danger">{errors.tutors.message}</p>
                )}
                <div className="mt-3 float-end">
                    <Button type="submit" variant="primary" className="px-4 py-2 me-3">{t("save_button_label")}</Button>
                    <Button type="button" variant="secondary" className="px-4 py-2"
                        onClick={props.onCancel}>
                        {t("cancel_button_label")}
                    </Button>
                </div>
            </form >
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
        </>
    );
}