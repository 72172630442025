import { zodResolver } from "@hookform/resolvers/zod";
import { uploadBytes, getDownloadURL, ref } from "firebase/storage";
import { useRef, useState, useEffect, FormEvent } from "react";
import { useForm, SubmitHandler, SubmitErrorHandler } from "react-hook-form";
import { z } from "zod";
import { CourseItemType } from "../enums/CourseItemType";
import { CourseItem } from "../models/course_item";
import { CourseItemSchema } from "../services/forms-schemas";
import { v4 as uuidv4 } from "uuid";
import { storage } from "../firebase";
import { Button, Table } from "react-bootstrap";
import { Quiz, quizConverter } from "../models/quiz";
import QuestionEditor from "./question-editor";
import { Question } from "../models/question";
import { QuizMode } from "../enums/QuizMode";
import { QuizStyle } from "../enums/QuizStyle";
import { useTranslation } from "react-i18next";
import { QuestionType } from "../enums/QuestionType";
import md5 from "md5";

interface CourseContentFormProps {
    target: CourseItem | null,
    courseId: string | null,
    onSubmit?(data: any): void,
    onCancel?(): void,
}

export default function CourseContentForm(props: CourseContentFormProps) {
    const {
        target = null,
        courseId = null,
    } = props;
    const { t, i18n } = useTranslation();

    const contentRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);
    const [file, setFile] = useState<File>()

    const [documentType, setDocumentType] = useState<CourseItemType | null>(null);
    const [acceptedTypes, setAcceptedTypes] = useState<string>("");
    const [calculatedLength, setCalculatedLength] = useState<string>("0.00")

    const [typesMap, setTypesMap] = useState<{ id: any; value: any }[] | null>(null);
    const [modesMap, setModesMap] = useState<{ id: any; value: any }[] | null>(null);
    const [stylesMap, setStylesMap] = useState<{ id: any; value: any }[] | null>(null);
    let [payload, setPayload] = useState<Quiz | null>(null);

    const newQuiz: Quiz = new Quiz();

    type Inputs = z.infer<typeof CourseItemSchema>;

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors }
    } = useForm<Inputs>({
        resolver: zodResolver(CourseItemSchema)
    });

    const buildModesMap = () => {
        const modes: { id: any; value: any }[] = [];

        let keys = Object.keys(QuizMode);
        let values = Object.values(QuizMode);

        for (var i = 0; i < keys.length; i++) {
            modes?.push({ id: keys[i], value: values[i] });
        }

        modes.sort((a, b) => (a.value < b.value ? -1 : 1));

        setModesMap(modes);
    }

    const buildStylesMap = () => {
        const styles: { id: any; value: any }[] = [];

        let keys = Object.keys(QuizStyle);
        let values = Object.values(QuizStyle);

        for (var i = 0; i < keys.length; i++) {
            styles?.push({ id: keys[i], value: values[i] });
        }

        styles.sort((a, b) => (a.value < b.value ? -1 : 1));

        setStylesMap(styles);
    }

    useEffect(() => {
        const m: { id: any; value: any }[] = [];

        for (var n in CourseItemType) {
            m?.push({ id: n, value: n });
        }

        m.sort((a, b) => (a.value < b.value ? -1 : 1));

        setTypesMap(m);

        buildModesMap();
        buildStylesMap();

        if (target != null) {
            if (target?.type === CourseItemType.quiz) {
                setPayload(target?.payload as Quiz);
            }

            setValue("type", target.type);
            setValue("place", target.place);
            setValue("length", !target?.length || isNaN(target?.length) ? 0 : target?.length);
            setCalculatedLength(target?.length.toFixed(2))

            updateAccepetedTypes();
        }
    }, [false])

    const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || ""

    const updateAccepetedTypes = () => {
        if (!target) {
            setAcceptedTypes("");
            return;
        }

        setDocumentType(target.type);

        switch (target.type) {
            case CourseItemType.video:
                setAcceptedTypes("video/*");
                break;

            case CourseItemType.audio:
                setAcceptedTypes("audio/*");
                break;

            case CourseItemType.pdf:
                setAcceptedTypes("application/pdf");
                break;

            case CourseItemType.document:
                setAcceptedTypes("application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/epub+zip");
                break;

            case CourseItemType.html:
                setAcceptedTypes("");
                break;

            default:
                break;
        }
    }

    const updateForm = (e: FormEvent<HTMLSelectElement>) => {
        setDocumentType(e.currentTarget.value as CourseItemType);
        setValue("url", "");
        setValue("length", 0);
        setCalculatedLength("0.00");

        if (contentRef.current) {
            contentRef.current.value = "";
        }

        switch (e.currentTarget.value) {
            case "video":
                setAcceptedTypes("video/*");
                break;

            case "audio":
                setAcceptedTypes("audio/*");
                break;

            case "pdf":
                setAcceptedTypes("application/pdf");
                break;

            case "document":
                setAcceptedTypes("application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/epub+zip");
                break;

            case "html":
                setAcceptedTypes("");
                break;

            default:
                break;
        }
    }

    const setVideoDuration = (e: FormEvent<HTMLInputElement>) => {

        var video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function () {
            var duration = video.duration;
            console.log(duration);
            setValue("length", parseFloat(duration.toFixed(2)));
            setCalculatedLength(duration.toFixed(2));
            window.URL.revokeObjectURL(video.src);
        }

        const input = e.target as HTMLInputElement;

        if (input.files) {
            video.src = URL.createObjectURL(input?.files[0]);
        }
    }

    const setAudioDuration = (e: FormEvent<HTMLInputElement>) => {

        var audio = document.createElement('audio');
        audio.preload = 'metadata';

        audio.onloadedmetadata = function () {
            window.URL.revokeObjectURL(audio.src);
            var duration = audio.duration;
            setValue("length", parseFloat(duration.toFixed(2)));
        }

        const input = e.target as HTMLInputElement;

        if (input.files) {
            audio.src = URL.createObjectURL(input?.files[0]);
        }
    }

    const handleErrors: SubmitErrorHandler<Inputs> = errors => {
        console.log(errors);
    }

    const processForm: SubmitHandler<Inputs> = async data => {
        // We need only to check file for new courses
        // existing courses alread has a file uploaded
        if (!target && !file && data.type !== CourseItemType.quiz) return

        let contentUrl = target?.url;

        if (data.type !== CourseItemType.quiz && file) {
            const fileId = uuidv4()
            contentUrl = `contents/${courseId}/${fileId}-${file.name}`
            const storageRef = ref(storage, contentUrl)
            const { metadata } = await uploadBytes(storageRef, file);
            //  await getDownloadURL(storageRef);
            const { fullPath } = metadata
        }

        if (payload) {
            payload.description = descriptionRef.current?.value as string;
        }

        const objectData = {
            title: data.title,
            url: data.type === CourseItemType.quiz ? null : contentUrl,
            payload: data.type === CourseItemType.quiz ? quizConverter.toFirestore(payload as Quiz) : null,
            length: data.length,
            type: data.type,
            place: data.place,
            free: data.free,
            active: data.active,
        };

        if (props.onSubmit) {
            props?.onSubmit(objectData);
        }

        reset();
    }

    const handleAddQuestion = (q: Question) => {
        if (!payload) {
            payload = new Quiz();
        }

        q.id = uuidv4();

        payload?.questions.push(q);
    }

    const handleRemoveQuestion = (q: Question) => {
        const index = payload?.questions.indexOf(q);

        if (index && index > -1) {
            payload?.questions.splice(index, 1);
        }
    }

    return (
        <>
            <form key="contentForm" id="contentForm" className="w-100" method="post" onSubmit={handleSubmit(processForm, handleErrors)}>
            </form>

            <input
                type="hidden"
                form="contentForm"
                {...register("id", { value: target?.id })}
            />
            <input
                type="hidden"
                form="contentForm"
                defaultValue={!target?.length || isNaN(target?.length) ? 0 : target?.length}
                {...register("length", { valueAsNumber: true, value: target?.length })}
            />
            <Table bordered striped>
                <tbody>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("title_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input
                                type="text"
                                form="contentForm"
                                className="w-100"
                                defaultValue={target?.title}
                                placeholder="Enter title"
                                {...register("title", { value: target?.title, required: true })}
                            />
                            {errors.title?.message && (
                                <p className="small text-danger">{errors.title.message}</p>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("type_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <select
                                form="contentForm"
                                className="w-100"
                                {...register("type", { required: true, value: target?.type ?? "" })}
                                onChange={updateForm}
                                defaultValue={target?.type}
                            >
                                <option key={0} value="">{t("select_type_title")}</option>
                                {typesMap && typesMap.map((e) => {
                                    return (
                                        <option
                                            key={e.id}
                                            value={e.id}
                                            selected={e.id === target?.type}
                                        >
                                            {t(capitalize(e.value))}
                                        </option>
                                    )
                                })}
                            </select>
                            {errors.type?.message && (
                                <p className="small text-danger">{errors.type.message}</p>
                            )}
                        </td>
                    </tr>
                    {documentType !== CourseItemType.quiz ?
                        <>
                            <tr>
                                <td className="field-label" colSpan={2}>
                                    {t("content_column_header")}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    {documentType != CourseItemType.html ? (
                                        <>
                                            <input
                                                type="hidden"
                                                form="contentForm"
                                                {...register("url", { value: target?.url })}
                                            />
                                            <input type="file"
                                                form="contentForm"
                                                accept={acceptedTypes}
                                                className="w-100"
                                                name="contentUploader"
                                                placeholder="Select Content"
                                                ref={contentRef}
                                                disabled={acceptedTypes === ""}
                                                onChange={(e) => {
                                                    if (e.target.files?.[0]) {
                                                        setFile(e.target.files?.[0]);
                                                        setValue("url", e.target.files[0]?.name);

                                                        if (documentType == CourseItemType.video) {
                                                            setVideoDuration(e);
                                                        } else if (documentType == CourseItemType.audio) {
                                                            setAudioDuration(e);
                                                        } else {
                                                            setValue("length", e.target.files[0]?.size);
                                                        }
                                                    }
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <input
                                            type="text"
                                            form="contentForm"
                                            placeholder="Enter Url"
                                            className="w-100"
                                            {...register("url", { value: target?.url })}
                                        />
                                    )}
                                    {target && target.url &&
                                        <p>{target?.url}</p>
                                    }
                                    {errors.url?.message && (
                                        <p className="small text-danger">{errors.url.message}</p>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className="field-label" colSpan={2}>
                                    {t("size_column_header")}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <input
                                        type="number"
                                        form="contentForm"
                                        className="w-100"
                                        placeholder="Enter length"
                                        defaultValue={target ? target?.length : 0}
                                        value={calculatedLength}
                                        disabled
                                    />
                                    {errors.length?.message && (
                                        <p className="small text-danger">{errors.length.message}</p>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td className="field-label" colSpan={2}>
                                    {t("free_column_header")}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <input
                                        type="checkbox"
                                        form="contentForm"
                                        {...register("free", {
                                            value: target?.free,
                                        })}
                                        defaultChecked={target?.free}
                                    />
                                    {errors.free?.message && (
                                        <p className="small text-danger">{errors.free.message}</p>
                                    )}
                                </td>
                            </tr>
                        </>
                        :
                        <>
                            <tr>
                                <td className="field-label" colSpan={2}>
                                    {t("description_column_header")}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <textarea
                                        form="contentForm"
                                        ref={descriptionRef}
                                        className="w-100"
                                        defaultValue={payload?.description}
                                        placeholder="Enter description"
                                    />
                                    {errors.title?.message && (
                                        <p className="small text-danger">{errors.title.message}</p>
                                    )}
                                </td>
                            </tr>
                            {/* <tr>
                                <td className="field-label w-50">
                                    Mode
                                </td>
                                <td className="field-label w-50">
                                    Style
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <select
                                        form="contentForm"
                                        className="w-100"
                                    // onChange={updateForm}
                                    // {...register("mode", { value: target?.mode, required: true, onChange: updateForm })}
                                    >
                                        <option key={0} value="">Select mode</option>
                                        {modesMap && modesMap.map((e) => {
                                            return (
                                                <option
                                                    key={e.id}
                                                    value={e.value}
                                                    selected={e.value === target?.type}
                                                >
                                                    {capitalize(e.value)}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </td>
                                <td>
                                    <select
                                        form="contentForm"
                                        className="w-100"
                                    // onChange={updateForm}
                                    // {...register("mode", { value: target?.mode, required: true, onChange: updateForm })}
                                    >
                                        <option key={0} value="">Select style</option>
                                        {stylesMap && stylesMap.map((e) => {
                                            return (
                                                <option
                                                    key={e.id}
                                                    value={e.value}
                                                    selected={e.value === target?.type}
                                                >
                                                    {capitalize(e.value)}
                                                </option>
                                            )
                                        })}
                                    </select>
                                </td>
                            </tr> */}
                            <QuestionEditor
                                data={payload?.questions as Question[]}
                                onAdd={handleAddQuestion}
                                onRemove={handleRemoveQuestion}
                            />
                        </>

                    }
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("place_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <input
                                type="number"
                                form="contentForm"
                                className="w-100"
                                placeholder="Enter place"
                                {...register("place", {
                                    valueAsNumber: true,
                                    value: target?.place,
                                    required: true,
                                })}
                            />
                            {errors.place?.message && (
                                <p className="small text-danger">{errors.place.message}</p>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("active_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <input
                                type="checkbox"
                                form="contentForm"
                                {...register("active", {
                                    value: target?.active,
                                })}
                                defaultChecked={target?.active}
                            />
                            {errors.active?.message && (
                                <p className="small text-danger">{errors.active.message}</p>
                            )}
                        </td>
                    </tr>
                </tbody>
            </Table >
            <div className="mt-3 float-end">
                <Button type="submit" form="contentForm" variant="primary" className="px-4 py-2 me-3">{t("save_button_label")}</Button>
                <Button type="button" variant="secondary" className="px-4 py-2"
                    onClick={props.onCancel}>
                    {t("cancel_button_label")}
                </Button>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </>
    );
}