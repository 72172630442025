import React, { useState } from 'react';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons'
import GridAction from './grid-action';

interface ActionPaneProps {
    onView?(): void,
    onEdit?(): void,
    onDelete?(): void,
    showView?: boolean,
    showEdit?: boolean,
    showDelete?: boolean,
    children?: React.ReactNode,
};

export default function ActionPane(props: ActionPaneProps): JSX.Element {

    // Default props
    const {
        onView = null,
        onEdit = null,
        onDelete = null,
        showView = true,
        showEdit = true,
        showDelete = true,
        children = [],
    } = props;

    const handleViewClick = () => {
        if (typeof onView === 'function') {
            onView?.()
        }
    }

    const handleEditClick = () => {
        if (typeof onEdit === 'function') {
            onEdit?.()
        }
    }

    const handleDeleteClick = () => {
        if (onDelete) {
            onDelete();
        }
    }

    return (
        <div className='w-100 d-flex justify-content-between px-1'>
            {showView &&
                <GridAction icon={faEye} className="action-icon" onClick={handleViewClick} />
            }

            {showEdit &&
                <GridAction icon={faEdit} className="action-icon" onClick={handleEditClick} />
            }

            {showDelete &&
                <GridAction icon={faTrash} className="action-icon text-danger" onClick={handleDeleteClick} />
            }

            {children}
        </div>
    )
}

