import { db } from "../firebase";
import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { query, collection, onSnapshot, where } from "firebase/firestore";
import { TicketStatus } from "../enums/TicketStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMessage, faTicket } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

interface NotificationsIconProps {
    onClick?(): void,
}

export default function NotificationsIcon(props: NotificationsIconProps) {

    const [count, setCount] = useState(0);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const q = query(collection(db, `tickets`), where("status", "==", TicketStatus.created));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let itemsArr: any = [];

            querySnapshot.forEach((doc) => {
                itemsArr.push({ ...doc.data(), id: doc.id });
            });

            setCount(itemsArr.length);
        })

        return () => unsubscribe();
    }, [])

    const handleClick = () => {
        if (props.onClick) {
            props?.onClick();
        }
    }

    return (
        <>
            <div className="position-relative" title={t("support_tickets")} onClick={handleClick}>
                <FontAwesomeIcon color="black" icon={faTicket} style={{ marginTop: "13px" }} size="lg" />
                {count > 0 && <Badge bg="danger" className="position-absolute start-100 translate-middle" style={{ top: "10px" }}>{count}</Badge>}
            </div>

            {/* <button type="button" className="btn btn-primary position-relative" onClick={handleClick}>
                Tickets
                <Badge bg="danger" className="position-absolute top-0 start-100 translate-middle">{count}</Badge>
                <span className="visually-hidden">new tickets</span>
            </button> */}

        </>
    )
}