import { faPen } from "@fortawesome/free-solid-svg-icons";
import { deleteDoc, doc, collection, updateDoc, query, onSnapshot, where, Query, DocumentSnapshot, getDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import Course from "../models/course";
import ActionPane from "./action-pane";
import { DataGrid } from "./data-grid";
import FilterBox from "./filter-box";
import GridAction from "./grid-action";
import { getAllCourses, getCollegeCourses, getTutorCourses } from "../services/api";
import { ColorRing } from "react-loader-spinner";
import DeleteDialog from "./delete-dialog";
import { StudyMaterialType } from "../enums/StudeyMaterialType";
import PublishDialog from "./publish-dialog";
import { collegeConverter } from "../models/college";
import { universityConverter } from "../models/university";
import { countryConverter } from "../models/country";
import { useTranslation } from "react-i18next";

interface CourseDataGridProps {
    mode?: "course" | "tutor" | "college" | "student",
    type?: "courses" | "reviews",
    id?: string,
    enableFiltering?: boolean,
    enableCreating?: boolean,
}

export default function CoursesDataGrid(props: CourseDataGridProps) {

    const {
        type = "courses",
        mode = "course",
        id = null,
        enableCreating = true,
        enableFiltering = true,
    } = props;

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [data, setData] = useState<Course[] | null>(null);
    const [filteredData, setFilteredData] = useState<Course[]>([]);
    const [countryFilter, setCountryFilter] = useState("");
    const [universityFilter, setUniversityFilter] = useState("");
    const [collegeFilter, setCollegeFilter] = useState("");

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showPublishDialog, setShowPublishDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleEdit = (row: any) => {
        navigate(`/admin/${type}/edit/${row.id}`);
    }

    const handleView = (row: any) => {
        navigate(`/admin/${type}/${row.id}`);
    }

    const handleDelete = async (row: any) => {
        const courseRef = doc(collection(db, "courses"), row.id);
        await updateDoc(courseRef, { deleted: true });
        setShowDeleteDialog(false);
    }

    const handlePublish = async (row: any) => {
        await updateDoc(doc(collection(db, "courses"), row.id), { "active": !row.active });
        setShowPublishDialog(false);
    }

    const handleEditItems = (row: any) => {
        navigate(`/admin/${type}/${row.id}/content`);
    }

    const applyFilter = (countryId: string, universityId: string, collegeId: string) => {

        // We need to save filters for later
        setCountryFilter(countryId);
        setUniversityFilter(universityId);
        setCollegeFilter(collegeId);

        if (data) {
            const items = data.filter((course: Course) => {
                const collegeFilter = collegeId ? course.college?.id == collegeId : true;
                const universityFilter = universityId ? course.college?.university?.id == universityId : true;
                const countryFilter = countryId ? course.college?.university?.country?.id == countryId : true;

                return collegeFilter && universityFilter && countryFilter;
            })

            setFilteredData(items);
        }
    }

    const buildQuery = () => {
        let q: Query | null = null;
        const coursesCol = collection(db, "courses");

        switch (mode) {
            case "course":
                q = query(coursesCol,
                    where("type", "==",
                        type == "courses" ? StudyMaterialType.course : StudyMaterialType.review),
                    where("deleted", "==", false));
                break;

            case "tutor":
                const tutorRef = doc(db, `tutors/${id}`);
                q = query(coursesCol,
                    where("type", "==",
                        type == "courses" ? StudyMaterialType.course : StudyMaterialType.review),
                    where("tutors", "array-contains", tutorRef),
                    where("deleted", "==", false));
                break;

            case "college":
                const collegeRef = doc(db, `colleges/${id}`);
                q = query(coursesCol,
                    where("type", "==",
                        type == "courses" ? StudyMaterialType.course : StudyMaterialType.review),
                    where("college", "==", collegeRef),
                    where("deleted", "==", false));
                break;

            case "student":
                const coursesRef = collection(db, "student_courses");
                const studentRef = doc(db, `students/${id}`);
                q = query(coursesRef,
                    where("student", "==", studentRef));
                break;
        }

        return q;
    }

    useEffect(() => {
        setIsLoading(true);

        const q = buildQuery();

        if (q) {
            const unsubscribe = onSnapshot(q, async (querySnapshot) => {
                let itemsArr: any = [];

                const promises = querySnapshot.docs.map(async (newDoc: any) => {
                    const newCourse = { ...newDoc.data(), id: newDoc.id };

                    if (newDoc.data()?.college) {
                        const collegeSnapshot: DocumentSnapshot = await getDoc(newDoc.data()?.college);
                        newCourse.college = collegeConverter.fromFirestore(collegeSnapshot, {});

                        if (collegeSnapshot.data()?.university) {
                            const universitySnapshot: DocumentSnapshot = await getDoc(collegeSnapshot.data()?.university);
                            newCourse.college.university = universityConverter.fromFirestore(universitySnapshot, {});

                            if (universitySnapshot.data()?.country) {
                                const countrySnapshot: DocumentSnapshot = await getDoc(universitySnapshot.data()?.country);
                                newCourse.college.university.country = countryConverter.fromFirestore(countrySnapshot, {});
                            }
                        }
                    }

                    return newCourse;
                });

                var newData = await Promise.all(promises);

                itemsArr.push(...newData);

                setData(itemsArr);
                setFilteredData(itemsArr);
            })

            return () => unsubscribe();
        }
    }, [i18n.language])

    return data ? (
        <>
            <div className="mb-2">
                <div className="d-inline">
                    {enableCreating &&
                        <Button variant="success" className='d-inline px-4 py-2' onClick={() => navigate('create')}>
                            {type === "courses" ? t("new_course_button_label") : t("new_review_button_label")}
                        </Button>
                    }
                </div>
                <div className="d-inline mb-2">
                    {enableFiltering &&
                        <FilterBox
                            countryFilter={true}
                            universityFilter={true}
                            collegeFilter={true}
                            onFilter={applyFilter}
                        />
                    }
                </div>
            </div>
            <DataGrid
                keyField='id'
                data={filteredData}
                actions={[]}
                actionsPaneWidth='120px'
                columns={[
                    {
                        dataField: 'title',
                        text: t("title_column_header"),
                        sort: true,
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '25%',
                                textAlign: "center",
                            };
                        },
                    },
                    {
                        dataField: i18n.language === "en" ? 'college.englishName' : 'college.arabicName',
                        text: t("college_column_header"),
                        sort: true,
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '25%',
                                textAlign: "center",
                            };
                        },
                    },
                    {
                        dataField: i18n.language === "en"
                            ? 'college.university.englishName'
                            : 'college.university.arabicName',
                        text: t("university_column_header"),
                        sort: true,
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '25%',
                                textAlign: "center",
                            };
                        },
                    },
                    {
                        dataField: i18n.language === "en"
                            ? 'college.university.country.englishName'
                            : 'college.university.country.arabicName',
                        text: t("country_column_header"),
                        sort: true,
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '25%',
                                textAlign: "center",
                            };
                        },
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return i18n.language === "en"
                                ? row.college?.university?.country?.englishName
                                : row.college?.university?.country?.arabicName;
                        }
                    },
                    {
                        dataField: 'dummy_field',
                        isDummyField: true,
                        text: t("actions_column_header"),
                        sort: false,
                        headerStyle: (column: any, colIndex: number) => {
                            return { width: '125px', minWidth: '125px', textAlign: 'center' };
                        },
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return (mode === "course" || mode === "tutor" ?
                                <div style={{ textAlign: 'center' }}>
                                    <ActionPane
                                        onView={() => handleView(row)}
                                        onEdit={() => handleEdit(row)}
                                        onDelete={() => {
                                            setSelectedRow(row);
                                            setShowDeleteDialog(true);
                                        }}
                                    >
                                        {row.active ?
                                            <GridAction
                                                className="text-danger"
                                                icon={faPen}
                                                title={t("unpublish_hint")}
                                                onClick={() => {
                                                    setSelectedRow(row);
                                                    setShowPublishDialog(true)
                                                }}
                                            />
                                            : <GridAction
                                                className="text-success"
                                                icon={faPen}
                                                title={t("publish_hint")}
                                                onClick={() => {
                                                    setSelectedRow(row);
                                                    setShowPublishDialog(true)
                                                }}
                                            />}
                                    </ActionPane>
                                </div>
                                : <></>
                            )
                        }
                    },
                ]}
            />

            < DeleteDialog
                visible={showDeleteDialog}
                row={selectedRow}
                onConfirm={handleDelete}
                onHide={() => setShowDeleteDialog(false)}
            />

            < PublishDialog
                visible={showPublishDialog}
                row={selectedRow}
                onConfirm={handlePublish}
                onHide={() => setShowPublishDialog(false)}
            />
        </>
    ) : (
        <div className="d-flex justify-content-center mt-5">
            {isLoading ?
                <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                />
                :
                <div>No data</div>
            }
        </div>

    )
}