import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActionPane from "../../components/action-pane";
import { DataGrid } from "../../components/data-grid";
import PreviewDialog from "../../components/preview-dialog";
import PageHeader from "../../components/page-header";
import Course from "../../models/course";
import { getCourse } from "../../services/api";
import { Button, Table } from "react-bootstrap";
import { Layout } from "../../Layout";
import { useTranslation } from "react-i18next";

export default function ContentsPage() {
    const { courseId } = useParams<string>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [course, setCourse] = useState<Course | null>(null);
    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        if (courseId) {
            getCourse(courseId).then((res) => {
                setCourse(res);
            });
        }
    }, [])

    const showPreviewDialog = () => {
        setShowPreview(true);
    }

    function handleItemView(rowIndex: number): void {
        navigate(`${rowIndex}`);
    }

    function handleItemEdit(rowIndex: number): void {
        navigate(`edit/${rowIndex}`);
    }

    function handleItemDelete(rowIndex: number): void {
    }

    return course ? (
        <>
            <PageHeader title="Course" />
            <Table bordered striped>
                <tbody>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("title_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            {course.title}
                        </td>
                    </tr>
                    {course.subtitle &&
                        <tr>
                            <td className="no-top-border" colSpan={2}>
                                {course.subtitle}
                            </td>
                        </tr>
                    }
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("college_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td className="no-bottom-border">
                            {course.college?.englishName}
                        </td>
                        <td className="text-end no-bottom-border">
                            {course.college?.arabicName}
                        </td>
                    </tr>
                    <tr>
                        <td className="no-top-border no-bottom-border">
                            {course.college?.university?.englishName}
                        </td>
                        <td className="text-end no-top-border no-bottom-border">
                            {course.college?.university?.arabicName}
                        </td>
                    </tr>
                    <tr>
                        <td className="no-top-border">
                            {course.college?.university?.country?.englishName}
                        </td>
                        <td className="text-end no-top-border">
                            {course.college?.university?.country?.arabicName}
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("tutors_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {
                                course.tutors
                                    ? course.tutors.map((t) => <p>{t.englishName}</p>)
                                    : "\u00A0"
                            }
                        </td>
                        <td className="text-end">
                            {
                                course.tutors
                                    ? course.tutors.map((t) => <p>{t.arabicName}</p>)
                                    : "\u00A0"
                            }
                        </td>
                    </tr>
                </tbody>
            </Table>
            <p>&nbsp;</p>
            <PageHeader title="Content" />
            <div className="mb-2">
                <div className="inline">
                    <Button variant="success" className='d-inline px-4 py-2' onClick={() => navigate('create')}>
                        {t("new_content_button_label")}
                    </Button>
                </div>
            </div>
            <DataGrid
                keyField='englishTitle'
                data={course?.items ?? []}
                actions={[]}
                actionsPaneWidth='120px'
                columns={[
                    {
                        dataField: 'combined_title',
                        isDummyField: true,
                        text: 'Title',
                        sort: false,
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '70%',
                                textAlign: "center",
                            };
                        },
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return (
                                <div className="bidi-field">
                                    <div className="english">{row.englishTitle} </div>
                                    <div className="arabic" >{row.arabicTitle}</div>
                                </div>
                            )
                        },

                    },
                    {
                        dataField: 'type',
                        isDummyField: true,
                        text: 'Type',
                        sort: false,
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '15%',
                                textAlign: "center",
                            };
                        },
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return (
                                <div className="bidi-field">
                                    <div className="english">{row.type} </div>
                                    <div className="arabic" >{row.type}</div>
                                </div>
                            )
                        },

                    },
                    {
                        dataField: 'free',
                        isDummyField: true,
                        text: 'Is Free?',
                        sort: false,
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '15%',
                                textAlign: "center",
                            };
                        },
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return (
                                <div className="bidi-field">
                                    <div className="english">{row.free && "Yes"} </div>
                                    <div className="arabic" >{row.free && "نعم"}</div>
                                </div>
                            )
                        },

                    },

                    {
                        dataField: 'dummy_field',
                        isDummyField: true,
                        text: 'Actions',
                        sort: false,
                        headerStyle: (column: any, colIndex: number) => {
                            return { width: '120px', minWidth: '100px', textAlign: 'center' };
                        },
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return (
                                <div style={{ textAlign: 'center' }}>
                                    <ActionPane
                                        onView={() => handleItemView(rowIndex)}
                                        onEdit={() => handleItemEdit(rowIndex)}
                                        onDelete={() => handleItemDelete(rowIndex)}
                                    />
                                </div>
                            )
                        }
                    },
                ]}
            />
            <p>&nbsp;</p>
            <div className="mt-3 float-end">
                <Button type="button" variant="secondary" className="px-4 py-2"
                    onClick={() => { navigate("/admin/courses") }}>
                    Back
                </Button>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>

            <PreviewDialog
                show={showPreview}
                url={course?.banner}
                onHide={() => setShowPreview(false)}
            />
        </>
    ) : (
        <div>
            Loading...
        </div>
    );
};