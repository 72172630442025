import { useState, useRef } from "react";
import { Country } from "../models/country";
import { University } from "../models/university";
import SelectUniversityDialog from "./select-university-dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface UniversityPickerProps {
    data?: University | undefined | null,
    onSelect?: ((country?: Country, university?: University) => void) | undefined,
}

export default function UniversityPicker(props: UniversityPickerProps): JSX.Element {
    const { data = null, onSelect = null } = props;
    const { t, i18n } = useTranslation();

    const [showDialog, setShowDialog] = useState(false);
    const [university, setUniversity] = useState<University | null>(data);
    const [country, setCountry] = useState<Country | null | undefined>(data?.country);

    const universityRef = useRef<HTMLInputElement>(null);

    const handleSelect = (selectedCountry?: Country, selectedUniversity?: University) => {

        setCountry(selectedCountry as Country);
        setUniversity(selectedUniversity as University);

        if (props?.onSelect) {
            props?.onSelect(undefined, selectedUniversity);
        }

        setShowDialog(false);
    }

    return (
        <>
            <tr>
                <td className="field-label" colSpan={2}>
                    <p className="d-inline me-3">{t("university_column_header")}</p>
                    <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        title="Select College"
                        onClick={() => setShowDialog(true)} />

                    <SelectUniversityDialog
                        show={showDialog}
                        onHide={() => setShowDialog(false)}
                        onSelect={handleSelect}
                    />
                </td>
            </tr>
            {university ?
                <>
                    <tr>
                        {i18n.language === "en"
                            ? <>
                                <td className="w-50 no-bottom-border">
                                    <p>{university?.englishName}</p>
                                    <p>{country ? country?.englishName : university?.country?.englishName}</p>
                                </td>
                                <td className="w-50 no-top-border text-end">
                                    <p>{university?.arabicName}</p>
                                    <p>{country ? country?.arabicName : university?.country?.arabicName}</p>
                                </td>
                            </>
                            : <>
                                <td className="w-50 no-bottom-border">
                                    <p>{university?.arabicName}</p>
                                    <p>{country ? country?.arabicName : university?.country?.arabicName}</p>
                                </td>
                                <td className="w-50 no-top-border text-end">
                                    <p>{university?.englishName}</p>
                                    <p>{country ? country?.englishName : university?.country?.englishName}</p>
                                </td>
                            </>
                        }
                    </tr>
                </>
                :
                <tr>
                    <td colSpan={2}>
                        &nbsp;
                    </td>
                </tr>
            }
        </>
    );

}