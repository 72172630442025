import { useState, useRef, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Course from "../models/course";

interface AddCourseDialogProps {
    data: Course[],
    show: boolean,
    onHide?: (() => void) | undefined,
    onAdd?: ((course?: Course) => void) | undefined,
}

export default function AddCourseDialog(props: AddCourseDialogProps) {
    let { data = [], show = false, onHide = undefined, onAdd = undefined } = props;
    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(show);
    const [courses, setCourses] = useState<Course[]>([]);
    const [coursesList, setCoursesList] = useState<Course[]>(data);

    const coursesRef = useRef<HTMLSelectElement>(null);

    const handleAddCourse = () => {
        if (onAdd) {
            const course = data[coursesRef.current?.selectedIndex!];
            onAdd(course);
        }
    }

    const handleQueryChange = (e: React.FormEvent<HTMLInputElement>) => {
        const query = e.currentTarget.value;
        let filteredItems = courses;

        if (query !== "") {
            filteredItems = coursesList.filter((e) => {
                if (e.titleLower) {
                    return e.titleLower.includes(query);
                }

                return e.title.includes(query);
            });

            setCoursesList(filteredItems);
        } else {
            setCoursesList(data);
        }
    };

    useEffect(() => {
        setCoursesList(data);
        setShowModal(show);

        // getAllCourses().then((data) => {
        //     setCoursesList(data);
        // })
    }, [show]);

    return (
        <Modal show={showModal} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t("add_course_title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    <input
                        type="text"
                        onChange={handleQueryChange}
                        className="w-100"
                        placeholder="Search..."
                    />
                    <select
                        name="coursesList"
                        id="coursesList"
                        size={6}
                        multiple={false}
                        className="w-100"
                        ref={coursesRef}
                    >
                        {/* <option value="">Select Tutor</option> */}
                        {coursesList && coursesList.map((course) => (
                            <option value={course.id!}>
                                {course.title}
                            </option>
                        ))}
                    </select>
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    {t("cancel_button_label")}
                </Button>
                <Button variant="primary" onClick={handleAddCourse}>
                    {t("add_button_label")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}