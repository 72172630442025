import { User, UserCredential, signInWithEmailAndPassword } from "firebase/auth";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { auth } from "../firebase";

interface AuthProviderProps {
    children: ReactNode
}

export interface IAuth {
    currentUser?: User | null,
    userRole?: string | null,
    signIn(email: string, password: string): Promise<UserCredential>,
    signOut(): Promise<void>,
}

function signIn(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
}

async function signOut() {
    auth.signOut();
}

const AuthContext = React.createContext<IAuth>({
    currentUser: null,
    userRole: null,
    signIn: signIn,
    signOut: signOut
});

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider(props: AuthProviderProps) {
    const [currentUser, setCurrentUser] = useState<User | null>();
    const [userRole, setUserRole] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsub = auth.onAuthStateChanged(async (user) => {
            setCurrentUser(user);

            const idTokenResult = await user?.getIdTokenResult(true);
            // .then((idTokenResult) => {
            if (idTokenResult && idTokenResult.claims && idTokenResult.claims["role"]) {
                setUserRole(idTokenResult?.claims["role"] as string);
            }
            // });

            setLoading(false)
        })

        return unsub;
    }, [])

    const value: IAuth = {
        currentUser,
        userRole,
        signIn,
        signOut,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && props.children}
        </AuthContext.Provider>
    )
}