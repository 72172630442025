import { DocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import Course from "./course";
import { University } from "./university";

export class College {
    id?: string;
    arabicName: string = "";
    englishName: string = "";
    active: boolean = false;
    deleted: boolean = false;
    timeCreated?: Date;
    timeModified?: Date;

    courses?: Course[];
    university?: University | null;
}

export const collegeConverter = {
    toFirestore: (college: College) => {
        return {
            arabicName: college?.arabicName,
            englishName: college?.englishName,
            active: college?.active,
            deleted: college?.deleted,
        };
    },
    fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
        const data = snapshot.data(options);

        let college = new College();
        college.id = snapshot.id;
        college.arabicName = data?.arabicName;
        college.englishName = data?.englishName;
        college.active = data?.active;
        college.deleted = data?.deleted;

        if (data?.timeCreated) {
            college.timeCreated = data?.timeCreated.toDate();
        }

        if (data?.timeModified) {
            college.timeModified = data?.timeModified.toDate();
        }

        return college;
    }
};