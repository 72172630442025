import { useEffect, useState } from "react";
import { Country } from "../../models/country";
import { useNavigate, useParams } from "react-router-dom";
import { fetchCountryUniversities, getCountry } from "../../services/api";
import { Layout } from "../../Layout";
import { University } from "../../models/university";
import PageHeader from "../../components/page-header";
import { Button, Container, Table } from "react-bootstrap";
import LoadingIndicator from "../../components/loading-indicator";
import { useTranslation } from "react-i18next";

export default function CountryDetailsPage() {
    const { id } = useParams<string>();
    const { t, i18n } = useTranslation();

    const [country, setCountry] = useState<Country | null>(null);
    const [universities, setUniversities] = useState<University[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        getCountry(id).then((res) => {
            if (res) {
                setCountry(res);

                fetchCountryUniversities(res as Country).then((list) => {
                    setUniversities(list);
                })
            }
        })
    }, [])

    return country ? (
        <>
            <PageHeader title={t("country_title")} />
            <Container fluid>
                <>
                    {country ? (
                        <Table bordered striped>
                            <tbody>
                                <tr>
                                    <td className="w-100 field-label" colSpan={2}>
                                        {t("name_column_header")}
                                    </td>
                                </tr>
                                <tr>
                                    {i18n.language === "en" &&
                                        <>
                                            <td className="w-50 text-start">
                                                {country.englishName}
                                            </td>
                                            <td className="w-50 text-end">
                                                {country.arabicName}
                                            </td>
                                        </>
                                    }
                                    {i18n.language === "ar" &&
                                        <>
                                            <td className="w-50 text-start">
                                                {country.arabicName}
                                            </td>
                                            <td className="w-50 text-end">
                                                {country.englishName}
                                            </td>
                                        </>
                                    }
                                </tr>
                                <tr>
                                    <td className="w-100 field-label" colSpan={2}>
                                        {t("country_code")}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-100" colSpan={2}>
                                        {country.countryCode}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-100 field-label" colSpan={2}>
                                        {t("active_column_header")}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-100" colSpan={2}>
                                        {country.active ? t("yes") : t("no")}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="field-label">
                                        {t("time_created_column_header")}
                                    </td>
                                    <td className="field-label">
                                        {t("time_modified_column_header")}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {
                                            country.timeCreated
                                                ? country.timeCreated.toISOString()
                                                : "\u00A0"
                                        }
                                    </td>
                                    <td>
                                        {
                                            country.timeModified
                                                ? country.timeModified.toISOString()
                                                : "\u00A0"
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-100 field-label" colSpan={2}>
                                        {t("universities_column_header")}
                                    </td>
                                </tr>
                                {universities.map((u: University) => (
                                    <tr key={u.id}>
                                        {i18n.language === "en" &&
                                            <>
                                                <td className="w-50 text-start">
                                                    {u.englishName}
                                                </td>
                                                <td className="w-50 text-end">
                                                    {u.arabicName}
                                                </td>
                                            </>
                                        }
                                        {i18n.language === "ar" &&
                                            <>
                                                <td className="w-50 text-start">
                                                    {u.arabicName}
                                                </td>
                                                <td className="w-50 text-end">
                                                    {u.englishName}
                                                </td>
                                            </>
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <LoadingIndicator />
                    )}
                    <div className="mt-3 float-end">
                        <Button
                            type="button"
                            variant="secondary"
                            className="px-4 py-2"
                            onClick={() => { navigate("/admin/countries") }}
                        >
                            {t("back_button_label")}
                        </Button>
                    </div>
                </>
            </Container>

        </>
    ) : (
        <div>Loading...</div>
    );
};