import { Suspense, lazy } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";

const CoursesDataGrid = lazy(() =>
    import("../../components/courses-data-grid")
);

export default function CoursesPage() {
    const { userRole, currentUser } = useAuth();
    const { t } = useTranslation();

    return (
        <>
            <h2 className="mb-4">{t("courses_title")}</h2>
            <Suspense fallback={<h2>Loading...</h2>}>
                <CoursesDataGrid
                    mode={userRole === "tutor" ? "tutor" : "course"}
                    id={currentUser?.uid}
                    enableFiltering={userRole !== "tutor"}
                />
            </Suspense>
        </>
    );
}