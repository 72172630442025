import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBoxesStacked,
    faBuildingColumns,
    faBuildingShield,
    faFlag,
    faHome,
    faMessage,
    faMoneyBill,
    faPeopleArrows,
    faPerson,
    faTicket,
    faVideoCamera,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";

export const SideBar = () => {
    const location = useLocation();
    const { userRole } = useAuth();

    const { t, i18n } = useTranslation();

    const links = userRole === "admin" ? [
        { name: t('home'), href: '/admin/dashboard', icon: faHome },
        { name: t('countries'), href: '/admin/countries', icon: faFlag, },
        { name: t('universities'), href: '/admin/universities', icon: faBuildingShield, },
        { name: t('colleges'), href: '/admin/colleges', icon: faBuildingColumns, },
        { name: t('tutors'), href: '/admin/tutors', icon: faPerson },
        { name: t('courses'), href: '/admin/courses', icon: faVideoCamera },
        { name: t('reviews'), href: '/admin/reviews', icon: faBoxesStacked },
        { name: t('students'), href: '/admin/students', icon: faPeopleArrows },
        { name: t('orders'), href: '/admin/orders', icon: faMoneyBill },
        { name: 'separator', href: "", icon: faMoneyBill },
        { name: t('prices'), href: '/admin/prices', icon: faMoneyBill },
        { name: t('messaging'), href: '/admin/messaging', icon: faMessage },
        { name: t('support_tickets'), href: '/admin/tickets', icon: faTicket },
        // { name: 'Vouchers', href: '/admin/vouchers', icon: faMoneyBill },
    ] : [
        { name: t('home'), href: '/admin/dashboard', icon: faHome },
        { name: t('courses'), href: '/admin/courses', icon: faVideoCamera },
        { name: t('reviews'), href: '/admin/reviews', icon: faBoxesStacked },
    ];

    return (
        <>
            <Nav id="sidebarMenu" className="collapse d-lg-block sidebar collapse bg-white flex-nowrap overflow-y-auto">
                <div className="position-sticky">
                    <div className="list-group list-group-flush mx-3 mt-4">
                        {links.map((link) => {

                            return link.name !== "separator"
                                ?
                                <Link
                                    key={link.name}
                                    to={link.href}
                                    className={`list-group-item list-group-item-action py-2 ripple ${location.pathname.startsWith(link.href) ? "active" : ""}`}
                                >
                                    <FontAwesomeIcon icon={link.icon} className="col-2 me-3" />
                                    <span>{link.name}</span>
                                </Link>
                                : <hr key={link.name} style={{ height: "4px", color: "black" }} />

                        })}
                    </div >
                </div>
                <div className="mt-5 small text-center">v {process.env.REACT_APP_VERSION}</div>
            </Nav>
        </>
    )
}
