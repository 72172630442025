import { DocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { TicketStatus } from "../enums/TicketStatus";
import { Gender } from "../enums/Gender";

export class Student {
    id?: string;
    email: string = "";
    displayName: string = "";
    gender?: Gender;
    birthdate?: Date;
    timeCreated: Date = new Date()
    timeModified: Date = new Date()
}

export const studentConverter = {
    toFirestore: (student: Student) => {
        return {
            email: student?.email,
            displayName: student?.displayName,
            gender: student?.gender,
            birthdate: student?.birthdate,
            timeCreated: student?.timeCreated,
            timeModified: student?.timeModified,
        };
    },
    fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
        const data = snapshot.data(options);

        let student = new Student();
        student.id = snapshot.id;
        student.email = data?.email;
        student.displayName = data?.displayName;
        student.gender = data?.gender;
        student.birthdate = data?.birthdate;

        if (data?.timeCreated) {
            student.timeCreated = data?.timeCreated.toDate();
        }

        if (data?.timeModified) {
            student.timeModified = data?.timeModified.toDate();
        }

        return student;
    }
}