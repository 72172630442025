import { zodResolver } from "@hookform/resolvers/zod";
import { storage } from "../firebase";
import { uploadBytes, getDownloadURL, ref } from "firebase/storage";
import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { z } from "zod";
import { Tutor } from "../models/tutor";
import { FcmMessageSchema, TutorSchema } from "../services/forms-schemas";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface FcmMessageFormProps {
    onSubmit?(data: any): void,
    onCancel?(): void,
}

export default function FcmMessageForm(props: FcmMessageFormProps) {
    const { t, i18n } = useTranslation();

    type Inputs = z.infer<typeof FcmMessageSchema>;

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors } } = useForm<Inputs>({
            resolver: zodResolver(FcmMessageSchema)
        });

    const processForm: SubmitHandler<Inputs> = async data => {

        const objectData = {
            title: data.title,
            body: data.body,
            topic: data.topic,
        }

        if (props.onSubmit) {
            props?.onSubmit(objectData);
        }

        reset();
    }

    return (
        <form className="w-100" method="post" onSubmit={handleSubmit(processForm)}>
            <Table bordered striped>
                <tbody>
                    <tr>
                        <td className="field-label" style={{ width: "120px" }}>
                            {t("title_column_header")}
                        </td>
                        <td>
                            <input
                                className="w-100"
                                placeholder="Enter title"
                                defaultValue={""}
                                {...register("title", { required: true })}
                            />
                            {errors.title?.message && (
                                <p className="small text-danger">{errors.title?.message}</p>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label">
                            {t("message_column_header")}
                        </td>
                        <td>
                            <input
                                className="w-100"
                                placeholder="Enter message"
                                defaultValue={""}
                                {...register("body", { required: true })}
                            />
                            {errors.body?.message && (
                                <p className="small text-danger">{errors.body?.message}</p>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label">
                            {t("topic_column_header")}
                        </td>
                        <td>
                            <select
                                className="w-100"
                                defaultValue={""}
                                {...register("topic", { required: true })}
                            >
                                <option value="">-- {t("select_topic_title")} --</option>
                                <option value="general">General</option>
                            </select>
                            {errors.topic?.message && (
                                <p className="small text-danger">{errors.topic?.message}</p>
                            )}
                        </td>
                    </tr>
                </tbody>
            </Table>
            <div className="mt-3 float-end">
                <Button type="submit" variant="primary" className="px-4 py-2 me-3">{t("send_button_label")}</Button>
                <Button type="button" variant="secondary" className="px-4 py-2"
                    onClick={props.onCancel}>
                    {t("cancel_button_label")}
                </Button>
            </div>
        </form >
    );
}