import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, IconDefinition } from '@fortawesome/free-solid-svg-icons'

interface GridActionProps {
    icon: IconDefinition,
    className: string,
    title?: string,
    onClick?(): void,
};

export default function GridAction(props: GridActionProps) {
    // Default props
    const {
        icon = faEye,
        className = "",
        title = "",
        onClick = null
    } = props;

    const handleOnClick = () => {
        if (typeof onClick === 'function') {
            onClick?.()
        }
    }

    return (
        <FontAwesomeIcon icon={icon}
            className={className}
            onClick={handleOnClick}
            title={title}
        />
    )
}
