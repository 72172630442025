import { CourseItemType } from "../enums/CourseItemType";
import { DocumentSnapshot, SnapshotOptions } from "firebase/firestore";

export class CourseItem {
    id?: string
    title: string = "";
    url?: string;
    payload?: object;
    length: number = 0.0;
    type: CourseItemType = CourseItemType.video;
    free: boolean = false;
    active: boolean = false;
    place: number = 0;
    timeCreated?: Date;
    children?: CourseItem[];
}

export const courseItemConverter = {
    toFirestore: (courseItem: CourseItem) => {
        return {
            title: courseItem?.title,
            url: courseItem?.url,
            payload: courseItem?.payload,
            length: courseItem?.length,
            type: courseItem?.type,
            place: courseItem?.place,
            free: courseItem?.free,
            active: courseItem?.active,
        };
    },
    fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
        const data = snapshot.data(options);

        let courseItem = new CourseItem();
        courseItem.id = snapshot.id;
        courseItem.title = data?.title;
        courseItem.url = data?.url;
        courseItem.payload = data?.payload;
        courseItem.length = parseFloat(data?.length as string);
        courseItem.place = parseInt(data?.place as string);
        courseItem.type = data?.type;
        courseItem.free = data?.free;
        courseItem.active = data?.active;

        if (data?.timeCreated)
            courseItem.timeCreated = data?.timeCreated.toDate();

        return courseItem;
    }
};