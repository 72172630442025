import { z } from "zod";
import { db } from "../firebase";
import { Country } from "../models/country";
import { University } from "../models/university";
import { UniversitySchema } from "../services/forms-schemas";
import { doc, collection } from "firebase/firestore";
import { useState, useEffect } from "react";
import { getAllCountries } from "../services/api";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface UniversityFormProps {
    target: University | null,
    onSubmit?(data: any): void,
    onCancel?(): void,
}

export default function UniversityForm(props: UniversityFormProps) {

    const { target = new University() } = props;

    const { t, i18n } = useTranslation();

    const [countryList, setCountryList] = useState<Country[]>([]);

    type Inputs = z.infer<typeof UniversitySchema>;

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors } } = useForm<Inputs>({
            resolver: zodResolver(UniversitySchema)
        });

    useEffect(() => {
        getAllCountries().then((rows) => {
            setCountryList(rows);
        })
    }, [])

    const processForm: SubmitHandler<Inputs> = data => {

        const countryRef = doc(collection(db, "countries"), data.country);

        const objectData = {
            arabicName: data.arabicName,
            englishName: data.englishName,
            country: countryRef,
            active: data.active,
        }

        if (props.onSubmit) {
            props?.onSubmit(objectData);
        }

        reset();
    }

    return (
        <form className="w-100" method="post" onSubmit={handleSubmit(processForm)}>
            <Table bordered striped>
                <tbody>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("name_column_header")}
                        </td>
                    </tr>
                    <tr>
                        {i18n.language === "en"
                            ? <>
                                <td>
                                    <input
                                        className="w-100"
                                        type="text"
                                        defaultValue={target?.englishName}
                                        placeholder="Enter name (English)"
                                        {...register("englishName", { value: target?.englishName, required: true })}
                                    />

                                    {errors.englishName?.message && (
                                        <p className="small text-danger">{errors.englishName.message}</p>
                                    )}
                                </td>
                                <td>
                                    <input
                                        className="w-100 text-end"
                                        dir="rtl"
                                        type="text"
                                        defaultValue={target?.arabicName}
                                        placeholder="Enter name (Arabic)"
                                        {...register("arabicName", { value: target?.arabicName, required: true })}
                                    />

                                    {errors.arabicName?.message && (
                                        <p className="small text-danger">{errors.arabicName.message}</p>
                                    )}
                                </td>
                            </>

                            : <>
                                <td>
                                    <input
                                        className="w-100 text-start"
                                        dir="rtl"
                                        type="text"
                                        defaultValue={target?.arabicName}
                                        placeholder="Enter name (Arabic)"
                                        {...register("arabicName", { value: target?.arabicName, required: true })}
                                    />

                                    {errors.arabicName?.message && (
                                        <p className="small text-danger">{errors.arabicName.message}</p>
                                    )}
                                </td>
                                <td className="text-end">
                                    <input
                                        className="w-100 text-end"
                                        type="text"
                                        defaultValue={target?.englishName}
                                        placeholder="Enter name (English)"
                                        {...register("englishName", { value: target?.englishName, required: true })}
                                    />

                                    {errors.englishName?.message && (
                                        <p className="small text-danger">{errors.englishName.message}</p>
                                    )}
                                </td>
                            </>
                        }
                    </tr>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("country_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <select
                                className="w-100"
                                {...register("country", { value: target?.country?.id, required: true })}
                            >
                                <option key={0} value="">{t("select_country")}</option>
                                {
                                    countryList.map((e: Country) => (
                                        <option key={e.id} value={e.id} selected={e.id === target?.country?.id}>{e.englishName} | {e.arabicName}</option>
                                    ))
                                }
                            </select>

                            {errors.country?.message && (
                                <p className="small text-danger">{errors.country.message}</p>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("active_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <input type="checkbox"
                                defaultChecked={false}
                                {...register("active", { value: target?.active, required: true })}
                            />

                            {errors.active?.message && (
                                <p className="small text-danger">{errors.active.message}</p>
                            )}
                        </td>
                    </tr>
                </tbody>
            </Table>
            <div className="mt-3 float-end">
                <Button type="submit" variant="primary" className="px-4 py-2 me-3">{t("save_button_label")}</Button>
                <Button type="button" variant="secondary" className="px-4 py-2"
                    onClick={props.onCancel}>
                    {t("cancel_button_label")}
                </Button>
            </div>
        </form>
    );
}