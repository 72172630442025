import { collection, doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/page-header";
import TutorForm from "../../components/tutor-form";
import { db } from "../../firebase";
import { Tutor } from "../../models/tutor";
import { getTutor } from "../../services/api";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SavingIndicator from "../../components/saving-indicator";

export default function TutorEditPage() {
    const { id } = useParams<string>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [tutor, setTutor] = useState<Tutor | null>(null);

    useEffect(() => {
        if (id) {
            getTutor(id).then((t) => {
                if (t) {
                    setTutor(t);
                } else {
                    setTutor(null);
                    setError("Tutor Not found!!");
                }
            })
        }
    })

    const handleSubmit = async (data: any) => {
        setLoading(true);

        // Add timeModified
        data.timeModified = serverTimestamp();

        // Temp Solution for missing timeCreated
        if (!tutor?.timeCreated) {
            data["timeCreated"] = serverTimestamp();
        }

        if (!tutor?.deleted) {
            data["deleted"] = false;
        }

        if (tutor) {
            var newData = { ...tutor, ...data };

            // Remove unneeded fields
            delete newData["id"];
            delete newData["courses"]
            delete newData['email'];
            delete newData['password'];

            try {
                const tutorsCollection = collection(db, 'tutors');
                await updateDoc(doc(tutorsCollection, id), newData);

                navigate("/admin/tutors");
            } catch (e) {
                setLoading(false);
                console.log(e);
            }
        }
    }

    return (
        <>
            <PageHeader title={t("edit_tutor_title")} />
            <Container fluid>
                {tutor
                    ? (
                        <>
                            {error &&
                                <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                                    {error}
                                </div>
                            }
                            {loading
                                ? <SavingIndicator />
                                : <TutorForm
                                    target={tutor}
                                    onSubmit={handleSubmit}
                                    onCancel={() => navigate("/admin/tutors")
                                    }
                                />}
                        </>
                    )
                    : <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                        {error}
                    </div>
                }
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </Container >
        </>
    )
};