import { DocumentReference, DocumentSnapshot, FieldValue, SnapshotOptions, Timestamp, doc, getDoc } from "firebase/firestore";
import { StudyMaterialType } from "../enums/StudeyMaterialType";
import { Collection } from "./collection";
import { College } from "./college";
import { CourseItem } from "./course_item";
import { Tutor, tutorConverter } from "./tutor";
import Course, { courseConverter } from "./course";

export default class Order {
    id?: string | null;
    invoiceNo: string = '';
    date: Date = new Date();
    total: number = 0.0;
    items: Course[] = [];

    constructor() { }
}

export const orderConverter = {
    toFirestore: (order: Order) => {
        return {
            date: Timestamp.fromDate(order?.date),
            invoiceNo: order?.total,
            total: order?.total,
        };
    },
    fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
        const data = snapshot.data(options);

        let order = new Order();
        order.id = snapshot.id;
        order.invoiceNo = data?.invoiceNo;
        order.date = data?.date.toDate();
        order.total = parseFloat(data?.total as string);
        order.items = data?.items;

        return order;
    }
};