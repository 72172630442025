import { DocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { QuizMode } from "../enums/QuizMode";
import { QuizStyle } from "../enums/QuizStyle";
import { Question, quesgtionConverter } from "./question";

export class Quiz {
    id?: string;
    description: string = "";
    mode: QuizMode = QuizMode.free;
    style: QuizStyle = QuizStyle.free;
    allowedTime?: number;
    passScore?: number;

    questions: Question[] = [];
}

export const quizConverter = {
    toFirestore: (quiz: Quiz) => {

        const questions = quiz?.questions.map((q: Question) => quesgtionConverter.toFirestore(q))

        return {
            description: quiz?.description,
            mode: quiz?.mode,
            style: quiz?.style,
            allowedTime: quiz?.allowedTime ?? null,
            passScore: quiz?.passScore ?? null,
            questions: questions,
        };
    },
    fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
        const data = snapshot.data(options);

        const questions = data?.questions.map((q: DocumentSnapshot) => {
            return quesgtionConverter.fromFirestore(q, {});
        })

        const quiz = new Quiz();
        quiz.id = snapshot.id;
        quiz.description = data?.description;
        quiz.mode = data?.mode;
        quiz.style = data?.style;
        quiz.allowedTime = parseInt(data?.allowedTime as string);
        quiz.passScore = parseInt(data?.passScore as string);
        quiz.questions = questions;

        // if (data?.timeCreated) {
        //     quiz.timeCreated = data?.timeCreated.toDate();
        // }

        // if (data?.timeModified) {
        //     quiz.timeModified = data?.timeModified.toDate();
        // }

        return quiz;
    }
};