import { query, collection, onSnapshot } from "firebase/firestore";
import { useState, useEffect, Suspense } from "react";
import { DataGrid } from "../../components/data-grid";
import { db } from "../../firebase";
import ActionPane from "../../components/action-pane";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function StudentsPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [data, setData] = useState([]);

    useEffect(() => {
        const q = query(collection(db, "students"));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let itemsArr: any = [];

            querySnapshot.forEach((doc) => {
                itemsArr.push({ ...doc.data(), id: doc.id });
            });

            setData(itemsArr);
        })

        return () => unsubscribe();
    }, []);

    const handleView = (row: any) => {
        navigate(`${row.id}`);
    }

    return (
        <>
            <h2 className="mb-4">{t("students_title")}</h2>
            <Suspense fallback={<p>Loading feed...</p>}>

                <DataGrid
                    keyField='id'
                    data={data}
                    columns={[
                        {
                            dataField: 'email',
                            text: t("email_column_header"),
                            sort: true,
                            headerStyle: (column: any, colIndex: number) => {
                                return {
                                    width: '50%',
                                    textAlign: "center",
                                };
                            },
                        },
                        {
                            dataField: 'displayName',
                            text: t("name_column_header"),
                            sort: true,
                            headerStyle: (column: any, colIndex: number) => {
                                return {
                                    width: '50%',
                                    textAlign: "center",
                                };
                            },
                        },
                        {
                            dataField: 'dummy_field',
                            isDummyField: true,
                            text: t("actions_column_header"),
                            sort: true,
                            headerStyle: (column: any, colIndex: number) => {
                                return { width: '120px', minWidth: '100px', textAlign: 'center' };
                            },
                            formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                                return (
                                    <div style={{ textAlign: 'center' }}>
                                        <ActionPane
                                            showEdit={false}
                                            showDelete={false}
                                            onView={() => handleView(row)}
                                        />
                                    </div>
                                )
                            }
                        },
                    ]}
                />

            </Suspense>
        </>
    );
}