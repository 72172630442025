import { useNavigate, useParams } from "react-router-dom";
import { University } from "../../models/university";
import { useEffect, useState } from "react";
import { collection, doc, serverTimestamp, updateDoc } from "firebase/firestore";
import PageHeader from "../../components/page-header";
import { db } from "../../firebase";
import { getUniversity } from "../../services/api";
import UniversityForm from "../../components/university-form";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SavingIndicator from "../../components/saving-indicator";

export default function UniversityEditPage() {
    const { id } = useParams<string>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [university, setUnivrsity] = useState<University | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        getUniversity(id).then((res) => {
            if (res) {
                setUnivrsity(res);
            } else {
                setError("University not found!");
            }
        })
    })

    const handleSubmit = async (data: any) => {
        setLoading(true);

        // Add timeModified
        data.timeModified = serverTimestamp();

        // Temp Solution for missing timeCreated
        if (!university?.timeCreated) {
            data["timeCreated"] = serverTimestamp();
        }

        if (university) {
            var newData = { ...university, ...data };

            // Add logoUrl (not used yet)
            newData["logoUrl"] = null;

            // Remove unneeded fields
            delete newData['id'];
            delete newData['colleges'];

            try {
                const universitiesCollection = collection(db, 'universities');
                await updateDoc(doc(universitiesCollection, id), newData);

                navigate("/admin/universities");
            } catch (e) {
                console.log(e);
                setError("Error!!");
                setLoading(false);
            }
        }
    }

    return university ? (
        <>
            <PageHeader title={t("edit_university_title")} />
            <Container fluid>
                {university
                    ? (
                        <>
                            {error &&
                                <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                                    {error}
                                </div>
                            }
                            {loading
                                ? <SavingIndicator />
                                : <UniversityForm
                                    target={university}
                                    onSubmit={handleSubmit}
                                    onCancel={() => navigate("/admin/universities")}
                                />
                            }
                        </>
                    )
                    : <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                        {error}
                    </div>
                }
            </Container>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </>
    ) : (
        <div>Loading...</div>
    );
};