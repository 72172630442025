import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface DeleteDialogProps {
    row?: any,
    visible: boolean,
    note?: string,
    onHide?(): void,
    onConfirm?(row: any): void,
};

export default function DeleteDialog(props: DeleteDialogProps) {

    const [visible, setVisible] = useState(props.visible);
    const { t } = useTranslation();

    useEffect(() => {
        setVisible(props.visible);
    }, [props.visible]);

    return (
        <Modal show={visible} onHide={() => props.onHide && props.onHide()}>
            <Modal.Header closeButton>
                <Modal.Title>{t("warning_title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {t("delete_dialog_message")}
                    <div className="text-danger">{props.note}</div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={() => props.onConfirm && props.onConfirm(props.row)}
                >
                    {t("delete_button_label")}
                </Button>
                <Button
                    variant="secondary"
                    onClick={() => props.onHide && props.onHide()}
                >
                    {t("cancel_button_label")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
