import { collection, doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CourseContentForm from "../../components/course-content-form";
import PageHeader from "../../components/page-header";
import { db } from "../../firebase";
import { CourseItem } from "../../models/course_item";
import { getCourseContent } from "../../services/api";
import { useTranslation } from "react-i18next";
import { QuizMode } from "../../enums/QuizMode";
import { QuizStyle } from "../../enums/QuizStyle";

export default function ContentEditPage() {
    const location = useLocation();
    const navigate = useNavigate();

    const { courseId, id } = useParams();
    const { t, i18n } = useTranslation();

    const [item, setItem] = useState<CourseItem | null>(null);

    useEffect(() => {
        if (courseId && id) {
            getCourseContent(courseId, id).then((content) => setItem(content));
        }
    }, [])

    const handleSave = async (data: any) => {
        // Add timeModified
        data.timeModified = serverTimestamp();

        // Temp Solution for missing timeCreated
        if (!item?.timeCreated) {
            data.timeCreated = serverTimestamp();
        }

        if (data.payload) {
            data.payload.mode = QuizMode.free;
            data.payload.style = QuizStyle.free;
            data.payload.allowedTime = null;
            data.payload.passScore = null;
        }

        if (item) {
            var newData = { ...item, ...data };

            // Remove unneeded fields
            delete newData['id'];
            delete newData['children'];

            newData.deleted = false;

            try {
                const ref = collection(db, `courses/${courseId}/contents`);
                await updateDoc(doc(ref, id), newData);
            } catch (e) {
                console.log(e);
            }

            if (location.pathname.startsWith("/admin/reviews")) {
                navigate(`/admin/reviews/${courseId}`);
            } else {
                navigate(`/admin/courses/${courseId}`);
            }
        }
    }

    return item ? (
        <>
            <PageHeader title={t("edit_content_title")} />
            {courseId ?
                <CourseContentForm
                    target={item}
                    courseId={courseId}
                    onSubmit={handleSave}
                    onCancel={() => navigate(location.pathname.startsWith("/reviews") ? `/reviews/${courseId}` : `/courses/${courseId}`)}
                />
                :
                <div>Not Found</div>
            }
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </>
    ) : (
        <></>
    );
};
