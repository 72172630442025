import { addDoc, collection, doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import CourseContentForm from "../../components/course-content-form";
import { Container } from "react-bootstrap";
import PageHeader from "../../components/page-header";
import { useTranslation } from "react-i18next";

export default function ContentCreatePage() {
    const location = useLocation();
    const { courseId } = useParams<string>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleSubmit = async (data: any) => {
        // Add dates
        data.timeCreated = serverTimestamp();
        data.timeModified = serverTimestamp();

        data.deleted = false;

        try {
            const coursesCollection = collection(db, `/courses/${courseId}/contents`);
            await addDoc(coursesCollection, data);
        } catch (e) {
            console.log(e);
        }

        if (location.pathname.startsWith("/reviews")) {
            navigate(`/admin/reviews/${courseId}`);
        } else {
            navigate(`/admin/courses/${courseId}`);
        }
    }

    return (
        <>
            <PageHeader title={t("add_content_title")} />
            <Container fluid>
                {courseId ? (
                    <CourseContentForm
                        target={null}
                        courseId={courseId}
                        onSubmit={handleSubmit}
                        onCancel={() => navigate(`/admin/courses/${courseId}/content`)}
                    />
                ) : (
                    <div>Invalid course</div>
                )}
            </Container>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </>
    )
}