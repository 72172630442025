import { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface ILayoutProvider {
    isRTL(): boolean,
    changeLanguage(lang: string): void;
}

const LayoutDirectionContext = createContext<ILayoutProvider>({ isRTL: () => false, changeLanguage: (a) => { } });

export const useLayoutDirection = () => {
    return useContext(LayoutDirectionContext);
};

interface LayoutDirectionProviderProps {
    children: ReactNode
}

export const LayoutDirectionProvider = (props: LayoutDirectionProviderProps) => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(() => {
        const storedLanguage = localStorage.getItem("lang") ?? "ar";
        i18n.changeLanguage(storedLanguage);
        return storedLanguage;
    });

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng).then((value) => {
            localStorage.setItem("lang", lng);

            document.documentElement.dir = i18n.dir();
            document.documentElement.lang = i18n.language;
        })
    };

    const isRTL = () => i18n.dir() === "rtl"

    useEffect(() => {
        localStorage.setItem("lang", language);
    }, [language]);

    useEffect(() => {
        // After the language direction changes, update the root HTML element
        changeLanguage(language);
        document.documentElement.dir = i18n.dir();
        document.documentElement.lang = i18n.language;
    }, [language]);

    return (
        <LayoutDirectionContext.Provider value={{ isRTL, changeLanguage }}>
            {props.children}
        </LayoutDirectionContext.Provider>
    );
};