import { deleteDoc, doc, collection, query, onSnapshot, orderBy, updateDoc, where } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ActionPane from "../../components/action-pane";
import { DataGrid } from "../../components/data-grid";
import { db } from "../../firebase";
import { Button } from "react-bootstrap";
import DeleteDialog from "../../components/delete-dialog";
import Price, { priceConverter } from "../../models/price";
import { useTranslation } from "react-i18next";

export default function PricesPage() {
    const navigate = useNavigate()

    const { t } = useTranslation();

    const [data, setData] = useState<Price[]>([]);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleEdit = (row: any) => {
        navigate(`edit/${row.id}`);
    }

    const handleView = (row: any) => {
        navigate(`${row.id}`);
    }

    const handleDelete = async (row: any) => {
        const tutorRef = doc(collection(db, "prices"), row.id);
        await updateDoc(tutorRef, { deleted: true });

        setShowDeleteDialog(false);
    }

    useEffect(() => {
        const pricesRef = collection(db, "prices").withConverter(priceConverter);
        const q = query(pricesRef,
            where("deleted", "!=", true),
            orderBy("deleted"), orderBy("usd"));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let itemsArr: Price[] = [];

            querySnapshot.forEach((doc) => {
                itemsArr.push(doc.data());
            });

            setData(itemsArr);
        })

        return () => unsubscribe();
    }, [])

    return (
        <>
            <h2 className="mb-4">{t("prices_title")}</h2>
            <div className="mb-4">
                <div className="d-inline">
                    <Button variant="success" className='d-inline px-4 py-2' onClick={() => navigate('create')}>
                        {t("new_price_button_label")}
                    </Button>
                </div>
            </div>
            <DataGrid
                keyField='id'
                data={data}
                actions={[]}
                actionsPaneWidth='120px'
                columns={[
                    {
                        dataField: 'id',
                        text: t("app_store_code_column_header"),
                        sort: false,
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '100%',
                                textAlign: "center",
                            };
                        },
                    },
                    {
                        dataField: 'usd',
                        text: 'USD',
                        sort: false,
                        style: { textAlign: "center" },
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '150px',
                                minWidth: '150px',
                                textAlign: "center",
                            };
                        },
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return (
                                <span>{cell.toFixed(2)}</span>
                            )
                        }
                    },
                    {
                        dataField: 'jod',
                        text: 'JOD',
                        sort: false,
                        style: { textAlign: "center" },
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '150px',
                                minWidth: '150px',
                                textAlign: "center",
                            };
                        },
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return (
                                <span>{cell.toFixed(2)}</span>
                            )
                        }
                    },
                    {
                        dataField: 'dummy_field',
                        isDummyField: true,
                        text: t("actions_column_header"),
                        sort: true,
                        headerStyle: (column: any, colIndex: number) => {
                            return { width: '120px', minWidth: '100px', textAlign: 'center' };
                        },
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return (
                                <div style={{ textAlign: 'center' }}>
                                    <ActionPane
                                        onView={() => handleView(row)}
                                        onEdit={() => handleEdit(row)}
                                        onDelete={() => {
                                            setSelectedRow(row);
                                            setShowDeleteDialog(true);
                                        }}
                                    />
                                </div>
                            )
                        }
                    },
                ]}
            />

            <DeleteDialog
                visible={showDeleteDialog}
                row={selectedRow}
                onConfirm={handleDelete}
                onHide={() => setShowDeleteDialog(false)}
            />
        </>
    );
}