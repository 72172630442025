import { DocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import { College } from "./college";
import { Country } from "./country";

export class University {
    id?: string;
    arabicName: string = "";
    englishName: string = "";
    logoUrl?: string;
    active: boolean = true;
    deleted: boolean = false;
    timeCreated?: Date
    timeModified?: Date

    country?: Country | null;
    colleges?: College[];
}

export const universityConverter = {
    toFirestore: (university: University) => {
        return {
            arabicName: university?.arabicName,
            englishName: university?.englishName,
            logoUrl: university?.logoUrl,
            active: university?.active,
            deleted: university?.deleted,
        };
    },
    fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
        const data = snapshot.data(options);

        let university = new University();
        university.id = snapshot.id;
        university.arabicName = data?.arabicName;
        university.englishName = data?.englishName;
        university.logoUrl = data?.logoUrl;
        university.active = data?.active;
        university.deleted = data?.deleted;

        if (data?.timeCreated) {
            university.timeCreated = data?.timeCreated.toDate();
        }

        if (data?.timeModified) {
            university.timeModified = data?.timeModified.toDate();
        }

        return university;
    }
}