import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Question } from "../models/question";
import AddQuestionDialog from "./add-question-dialog";
import { DataGrid } from "./data-grid";
import ActionPane from "./action-pane";
import DeleteDialog from "./delete-dialog";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

interface QuestionEditorProps {
    data: Question[],
    onAdd?: ((question: Question) => void) | undefined,
    onRemove?: ((question: Question) => void) | undefined,
}

export default function QuestionEditor(props: QuestionEditorProps): JSX.Element {
    const { data = [], onAdd = null, onRemove = null } = props;
    const { t, i18n } = useTranslation();

    const [showDialog, setShowDialog] = useState(false);
    const [questions, setQuestions] = useState<Question[]>(data);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState<Question | undefined>(undefined);

    const handleDelete = async (row: any) => {
        console.log(row);
        if (onRemove) {
            onRemove(row);
        }

        setShowDeleteDialog(false);
    }

    const handleUpdate = (q: Question) => {
        const idx = questions.findIndex((e) => {
            return e.id == q.id;
        })

        questions[idx] = q;
        setQuestions([...questions]);

        setShowDialog(false);
    }

    const handleAdding = (q: Question) => {
        q.id = uuidv4();

        setQuestions([...questions, q]);

        if (onAdd) {
            onAdd(q);
        }

        setShowDialog(false);
    }

    return (
        <>
            <tr>
                <td className="field-label" colSpan={2}>
                    <p className="d-inline me-3">{t("questions_column_header")}</p>
                    <FontAwesomeIcon
                        icon={faPlus}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setShowDialog(true);
                        }}
                    />
                    <AddQuestionDialog
                        target={selectedRow}
                        show={showDialog}
                        onHide={() => setShowDialog(false)}
                        onAdd={handleAdding}
                        onUpdate={handleUpdate}
                    />
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <DataGrid
                        data={questions}
                        keyField={"body"}
                        columns={[
                            {
                                dataField: 'body',
                                text: 'Body',
                                sort: false,
                                headerStyle: (column: any, colIndex: number) => {
                                    return {
                                        width: '100%',
                                    };
                                },
                            },
                            {
                                dataField: 'dummy_field',
                                isDummyField: true,
                                text: t("actions_column_header"),
                                sort: false,
                                headerStyle: (column: any, colIndex: number) => {
                                    return { width: '80px', minWidth: '80px', textAlign: 'center' };
                                },
                                formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                                    return (
                                        <div style={{ textAlign: 'center' }}>
                                            <ActionPane
                                                showView={false}
                                                onEdit={() => {
                                                    setSelectedRow(row);
                                                    setShowDialog(true);
                                                }}
                                                onDelete={() => {
                                                    setSelectedRow(row);
                                                    setShowDeleteDialog(true);
                                                }}
                                            />
                                        </div>
                                    )
                                }
                            },
                        ]} />
                    {/* <tbody>
                        {questions.map((e, i) => (
                            <tr key={i}>
                                <td className="w-50">
                                    {e.englishBody}
                                </td>
                                <td dir="rtl" className="w-50">
                                    {e.arabicBody}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table> */}
                    <DeleteDialog
                        visible={showDeleteDialog}
                        row={selectedRow}
                        onConfirm={handleDelete}
                        onHide={() => setShowDeleteDialog(false)}
                    />
                </td>
            </tr >
        </>
    );

}