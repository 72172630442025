import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import PageHeader from "../../components/page-header";
import { Container } from "react-bootstrap";
import PriceForm from "../../components/price-form";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import SavingIndicator from "../../components/saving-indicator";

export default function PriceCreatePage() {
    const navigate = useNavigate();

    const { t } = useTranslation();
    const [error, setError] = useState<string | null>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleError = async (msg: string) => {
        setError(msg);
    }

    const handleSubmit = async (data: any) => {
        setLoading(true);

        // Add dates
        data.timeCreated = serverTimestamp();
        data.timeModified = serverTimestamp();

        data.deleted = false;

        try {
            const pricesRef = collection(db, 'prices');
            const newPrice = await addDoc(pricesRef, data);

            navigate("/admin/prices");
        } catch (e) {
            console.log(e);

            setError("Error!!");
            setLoading(true);
        }
    }

    return (
        <>
            <PageHeader title={t("add_price_title")} />
            <Container fluid>
                {error &&
                    <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                        {error}
                    </div>
                }
                {loading
                    ? <SavingIndicator />
                    : <PriceForm
                        target={null}
                        onSubmit={handleSubmit}
                        onCancel={() => navigate("/admin/prices")}
                        onError={handleError}
                    />
                }
            </Container>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </>
    )
}