import { useNavigate } from "react-router-dom";
import { College } from "../../models/college";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import CollegeForm from "../../components/college-form";
import PageHeader from "../../components/page-header";
import { db } from "../../firebase";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import SavingIndicator from "../../components/saving-indicator";

export default function CollegeCreatePage() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const college = new College();

    const handleSubmit = async (data: any) => {
        setLoading(true);

        try {
            // Add dates
            data.timeCreated = serverTimestamp();
            data.timeModified = serverTimestamp();

            data.deleted = false;

            const collegesCollection = collection(db, 'colleges');

            const newCollege = await addDoc(collegesCollection, data);

            navigate("/admin/colleges");
        } catch (e) {
            console.log(e);
            setError("Error!!");
            setLoading(false);
        }
    }

    return (
        <>
            <PageHeader title={t("add_college_title")} />
            <Container fluid>
                {error &&
                    <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                        {error}
                    </div>
                }
                {loading
                    ? <SavingIndicator />
                    : <CollegeForm
                        targetCollege={new College()}
                        onSubmit={handleSubmit}
                        onCancel={() => navigate("/admin/colleges")}
                    />
                }
            </Container>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </>
    )
};