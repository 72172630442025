import { query, collection, onSnapshot, Timestamp } from "firebase/firestore";
import { useState, useEffect, Suspense } from "react";
import { DataGrid } from "../../components/data-grid";
import { db } from "../../firebase";
import ActionPane from "../../components/action-pane";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function OrdersPage() {
    const navigate = useNavigate()
    const { t } = useTranslation();

    const [data, setData] = useState([]);

    useEffect(() => {
        const q = query(collection(db, "orders"));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let itemsArr: any = [];

            querySnapshot.forEach((doc) => {
                itemsArr.push({ ...doc.data(), id: doc.id });
            });

            setData(itemsArr);
        })

        return () => unsubscribe();
    });

    const handleView = (row: any) => {
        navigate(`/admin/orders/${row.id}`);
    }

    return (
        <>
            <h2 className="mb-4">{t("orders_title")}</h2>
            <Suspense fallback={<p>Loading feed...</p>}>

                <DataGrid
                    keyField='id'
                    data={data}
                    columns={[
                        {
                            dataField: 'invoiceNo',
                            text: t("invoice_column_header"),
                            sort: true,
                            headerStyle: (column: any, colIndex: number) => {
                                return {
                                    width: '50%',
                                    textAlign: "center",
                                };
                            },
                        },
                        {
                            dataField: 'date',
                            text: t("date_column_header"),
                            sort: true,
                            headerStyle: (column: any, colIndex: number) => {
                                return {
                                    width: '50%',
                                    textAlign: "center",
                                };
                            },
                            formatter: (cell: Timestamp, row: any, rowIndex: number, extraData: any) => {
                                return (
                                    <span>{cell.toDate().toLocaleString()}</span>
                                )
                            },
                        },
                        {
                            dataField: 'total',
                            text: t("total_column_header"),
                            sort: true,
                            headerStyle: (column: any, colIndex: number) => {
                                return {
                                    width: '33%',
                                    textAlign: "center",
                                };
                            },
                            formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                                return (
                                    Math.round((cell + Number.EPSILON) * 100) / 100
                                ).toFixed(3)
                            },
                        },
                        {
                            dataField: 'dummy_field',
                            isDummyField: true,
                            text: t("actions_column_header"),
                            sort: false,
                            headerStyle: (column: any, colIndex: number) => {
                                return { width: '125px', minWidth: '125px', textAlign: 'center' };
                            },
                            formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {


                                return (
                                    <div style={{ textAlign: 'center' }}>
                                        <ActionPane
                                            showDelete={false}
                                            showEdit={false}
                                            onView={() => handleView(row)}

                                        />
                                    </div>
                                )
                            }
                        },

                    ]}
                />

            </Suspense>
        </>
    );
}