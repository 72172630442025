import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/page-header";
import { getPrice } from "../../services/api";
import { Button, Container, Table } from "react-bootstrap";
import Price from "../../models/price";
import { useTranslation } from "react-i18next";

export default function PriceDetailsPage() {
    const { id } = useParams<string>();
    const { t } = useTranslation();

    const [price, setPrice] = useState<Price | null>(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            getPrice(id).then((res) => {
                setPrice(res);
            })
        }
    })

    return (
        <>
            <PageHeader title={t("price_title")} />
            <Container fluid>
                {price ? (
                    <>
                        <Table bordered striped>
                            <tbody>
                                <tr>
                                    <td className="w-100 field-label" colSpan={2}>
                                        {t("app_store_code_column_header")}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-100" colSpan={2}>
                                        {price.id}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="w-50 field-label">
                                        USD
                                    </td>
                                    <td className="w-50 field-label">
                                        JOD
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        ${price.usd.toFixed(2)}
                                    </td>
                                    <td>
                                        {price.jod.toFixed(3)}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="field-label">
                                        {t("time_created_column_header")}
                                    </td>
                                    <td className="field-label">
                                        {t("time_modified_column_header")}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {
                                            price.timeCreated
                                                ? price.timeCreated.toISOString()
                                                : "\u00A0"
                                        }
                                    </td>
                                    <td>
                                        {
                                            price.timeModified
                                                ? price.timeModified.toISOString()
                                                : "\u00A0"
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <div className="mt-3 float-end">
                            <Button type="button" variant="secondary" className="px-4 py-2"
                                onClick={() => { navigate("/admin/prices") }}>
                                {t("back_button_label")}
                            </Button>
                        </div>
                    </>
                ) : (
                    <div>Loading...</div>
                )}
            </Container>
        </>
    )
};