import { collection, doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/page-header";
import { db } from "../../firebase";
import { getPrice } from "../../services/api";
import { Container } from "react-bootstrap";
import Price from "../../models/price";
import PriceForm from "../../components/price-form";
import SavingIndicator from "../../components/saving-indicator";
import { useTranslation } from "react-i18next";

export default function PriceEditPage() {
    const { id } = useParams<string>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [price, setPrice] = useState<Price | null>(null);
    const [error, setError] = useState<string | null>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleError = async (msg: string) => {
        setError(msg);
    }

    useEffect(() => {
        if (id) {
            getPrice(id).then((t) => {
                if (t) {
                    setPrice(t);
                } else {
                    setError("Price not found!");
                }
            })
        }
    })

    const handleSubmit = async (data: any) => {
        setLoading(true);

        // Add timeModified
        data.timeModified = serverTimestamp();

        // Temp Solution for missing timeCreated
        if (!price?.timeCreated) {
            data["timeCreated"] = serverTimestamp();
        }

        if (price) {
            var newData = { ...price, ...data };

            // Remove unneeded fields
            delete newData["id"];
            delete newData["courses"]

            try {
                const tutorsCollection = collection(db, 'prices');
                await updateDoc(doc(tutorsCollection, id), newData);

                navigate("/admin/prices");
            } catch (e) {
                console.log(e);
                setError("Error!!");
                setLoading(false);
            }
        }
    }

    return (
        <>
            <PageHeader title={t("edit_price_title")} />
            <Container fluid>
                {price
                    ? (
                        <>
                            {error &&
                                <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                                    {error}
                                </div>
                            }
                            {loading
                                ? <SavingIndicator />
                                : <PriceForm
                                    target={price}
                                    onSubmit={handleSubmit}
                                    onCancel={() => navigate("/admin/prices")}
                                    onError={handleError}
                                />
                            }
                        </>
                    )
                    : <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                        {error}
                    </div>
                }
            </Container>

            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </>
    )
};