import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Login } from './Login';
import './App.css';
import CountriesPage from './pages/countries/CountriesPage';
import UniversitiesPage from './pages/universities/UniversitiesPage';
import CollegesPage from './pages/colleges/CollegesPage';
import TutorsPage from './pages/tutors/TutorsPage';
import CoursesPage from './pages/courses/CoursesPage';
import ReviewsPage from './pages/reviews/ReviewsPage';
import CountryDetailsPage from './pages/countries/CountryDetailsPage';
import CountryEditPage from './pages/countries/CountryEditPage';
import UnivertyDetailsPage from './pages/universities/UniversityDetailsPage';
import UniversityEditPage from './pages/universities/UniversityEditPage';
import UniversityCreatePage from './pages/universities/UniversityCreatePage';
import CountryCreatePage from './pages/countries/CountryCreatePage';
import CollegeEditPage from './pages/colleges/CollegeEditPage';
import CollegeDetailsPage from './pages/colleges/CollegeDetailsPage';
import CollegeCreatePage from './pages/colleges/CollegeCreatePage';
import TutorCreatePage from './pages/tutors/TutorCreatePage';
import TutorEditPage from './pages/tutors/TutorEditPage';
import TutorDetailsPage from './pages/tutors/TutorDetailsPage';
import CourseDetailsPage from './pages/courses/CourseDetailsPage';
import CourseEditPage from './pages/courses/CourseEditPage';
import CourseCreatePage from './pages/courses/CourseCreatePage';
import ReviewDetailsPage from './pages/reviews/ReviewDetailsPage';
import ReviewEditPage from './pages/reviews/ReviewEditPage';
import ReviewCreatePage from './pages/reviews/ReviewCreatePage';
import ContentsPage from './pages/contents/ContentsPage';
import ContentCreatePage from './pages/contents/ContentCreatePage';
import ContentDetailsPage from './pages/contents/ContentDetailsPage';
import ContentEditPage from './pages/contents/ContentEditPage';
import { DashboardPage } from './pages/dashboard/DashboardPage';
import { auth } from './firebase';
import { User } from 'firebase/auth';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/private-route';
import OrdersPage from './pages/orders/OrdersPage';
import OrderDetailsPage from './pages/orders/OrderDetailsPage';
import MessagingPage from './pages/messaging/messages_page';
import TicketsPage from './pages/tickets/TicketsPage';
import TicketDetailsPage from './pages/tickets/TicketDetailsPage';
import StudentsPage from './pages/students/StudentsPage';
import StudentDetailsPage from './pages/students/StudentDetailsPage';
import PricesPage from './pages/prices/PricesPage';
import PriceDetailsPage from './pages/prices/PriceDetailsPage';
import PriceEditPage from './pages/prices/PriceEditPage';
import PriceCreatePage from './pages/prices/PriceCreatePage';
import { useTranslation } from 'react-i18next';
import HomePage from './pages/home/HomePage';

function App() {
  const { i18n } = useTranslation();

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return unsubscribe;
  }, [i18n.language]);

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="/course" element={<HomePage />} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/admin">
            <Route index element={<PrivateRoute />} />
            <Route path="dashboard" element={<PrivateRoute />}>
              <Route path="" element={<DashboardPage />} />
            </Route>
            <Route path="countries" element={<PrivateRoute />}>
              <Route path="" element={<CountriesPage />} />
              <Route path=":id" element={<CountryDetailsPage />} />
              <Route path="create" element={<CountryCreatePage />} />
              <Route path="edit/:id" element={<CountryEditPage />} />
            </Route>
            <Route path="universities" element={<PrivateRoute />}>
              <Route path="" element={<UniversitiesPage />} />
              <Route path=":id" element={<UnivertyDetailsPage />} />
              <Route path="create" element={<UniversityCreatePage />} />
              <Route path="edit/:id" element={<UniversityEditPage />} />
            </Route>
            <Route path="colleges" element={<PrivateRoute />}>
              <Route path="" element={<CollegesPage />} />
              <Route path=":id" element={<CollegeDetailsPage />} />
              <Route path="create" element={<CollegeCreatePage />} />
              <Route path="edit/:id" element={<CollegeEditPage />} />
            </Route>
            <Route path="tutors" element={<PrivateRoute />}>
              <Route path="" element={<TutorsPage />} />
              <Route path=":id" element={<TutorDetailsPage />} />
              <Route path="create" element={<TutorCreatePage />} />
              <Route path="edit/:id" element={<TutorEditPage />} />
            </Route>
            <Route path="courses" element={<PrivateRoute />}>
              <Route path="" element={<CoursesPage />} />
              <Route path=":id" element={<CourseDetailsPage />} />
              <Route path="create" element={<CourseCreatePage />} />
              <Route path="edit/:id" element={<CourseEditPage />} />
              <Route path=":courseId/content" element={<ContentsPage />} />
              <Route path=":courseId/content/create" element={<ContentCreatePage />} />
              <Route path=":courseId/content/:id" element={<ContentDetailsPage />} />
              <Route path=":courseId/content/edit/:id" element={<ContentEditPage />} />
            </Route>
            <Route path="reviews" element={<PrivateRoute />}>
              <Route path="" element={<ReviewsPage />} />
              <Route path=":id" element={<ReviewDetailsPage />} />
              <Route path="create" element={<ReviewCreatePage />} />
              <Route path="edit/:id" element={<ReviewEditPage />} />
              <Route path=":courseId/content/create" element={<ContentCreatePage />} />
              <Route path=":courseId/content/edit/:id" element={<ContentEditPage />} />
            </Route>
            <Route path="students" element={<PrivateRoute />}>
              <Route path="" element={<StudentsPage />} />
              <Route path=":id" element={<StudentDetailsPage />} />
            </Route>
            <Route path="prices" element={<PrivateRoute />}>
              <Route path="" element={<PricesPage />} />
              <Route path=":id" element={<PriceDetailsPage />} />
              <Route path="create" element={<PriceCreatePage />} />
              <Route path="edit/:id" element={<PriceEditPage />} />
            </Route>
            <Route path="orders" element={<PrivateRoute />}>
              <Route path="" element={<OrdersPage />} />
              <Route path=":id" element={<OrderDetailsPage />} />
            </Route>
            <Route path="tickets" element={<PrivateRoute />}>
              <Route path="" element={<TicketsPage />} />
              <Route path=":id" element={<TicketDetailsPage />} />
            </Route>
            <Route path="messaging" element={<PrivateRoute />}>
              <Route path="" element={<MessagingPage />} />
            </Route>
            {/* <Route path="/vouchers" element={<PrivateRoute />}>
            <Route path="/vouchers" element={<VouchersPage />} />
            <Route path=":id" element={<VoucherDetailsPage />} />
            <Route path="create" element={<VoucherCreatePage />} />
            <Route path="edit/:id" element={<VoucherEditPage />} />
          </Route> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App;
