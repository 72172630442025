import { DocumentSnapshot, SnapshotOptions } from "@firebase/firestore";
import { University } from "./university";

export class Country {
    id?: string;
    arabicName: string = "";
    englishName: string = "";
    countryCode: string = "";
    active: boolean = true;
    deleted: boolean = false;
    timeCreated?: Date;
    timeModified?: Date;

    universities?: University[];
}

export const countryConverter = {
    toFirestore: (country: Country) => {
        return {
            arabicName: country?.arabicName,
            englishName: country?.englishName,
            countryCode: country?.countryCode,
            deleted: country?.deleted,
            active: country?.active,
        };
    },
    fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
        const data = snapshot.data(options);

        let country = new Country();
        country.id = snapshot.id;
        country.arabicName = data?.arabicName;
        country.englishName = data?.englishName;
        country.countryCode = data?.countryCode;
        country.deleted = data?.deleted;
        country.active = data?.active;

        if (data?.timeCreated) {
            country.timeCreated = data?.timeCreated.toDate();
        }

        if (data?.timeModified) {
            country.timeModified = data?.timeModified.toDate();
        }

        return country;
    }
}