import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import { HomeLayout } from "../../HomeLayout";
import logo from "../../logo.png";
import { Col } from "react-bootstrap";
import get_ios from "../../get_ios.png";
import get_android from "../../get_android.png";

export default function HomePage() {
    return (
        <HomeLayout>
            <Row className="text-center">
                <Col className="mb-5">
                    <img src={logo} alt="Coursat" style={{ width: "165px" }} />
                    <Row>
                        <p>منصة تعليمية -
                            An Educational Platform</p>
                    </Row>
                </Col>
                <Row className="mb-5">
                    <Col>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=app.meeshosoft.coursat">
                            <img src={get_android} alt="Get App" style={{ width: "200px" }} />
                        </a>
                        <a target="_blank" href="https://apps.apple.com/us/app/coursat/id6474066670">
                            <img src={get_ios} alt="Get App" style={{ width: "200px" }} />
                        </a>
                    </Col>
                </Row>
                <p> لأية استفسارات أو للدعم الفني يرجى مراسلتنا على  <a href="mailto: support@coursat.app">support@coursat.app</a></p>
                <p>For inquiries or support requests, Please contact <a href="mailto: support@coursat.app">support@coursat.app</a></p>
            </Row >
        </HomeLayout >
    )
}