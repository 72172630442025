import { DocumentSnapshot, SnapshotOptions } from "firebase/firestore";

export class Answer {
    id?: string;
    body: string = "";
    correct: boolean = false;
}

export const answerConverter = {
    toFirestore: (answer: Answer) => {
        return {
            id: answer?.id,
            body: answer?.body,
            correct: answer?.correct,
        };
    },
    fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
        const data = snapshot.data(options);

        const answers = data?.answers.map((a: DocumentSnapshot) => answerConverter.fromFirestore(a, {}));

        let answer = new Answer();
        answer.id = snapshot.id;
        answer.body = data?.body;
        answer.correct = data?.correct;

        // if (data?.timeCreated) {
        //     quiz.timeCreated = data?.timeCreated.toDate();
        // }

        // if (data?.timeModified) {
        //     quiz.timeModified = data?.timeModified.toDate();
        // }

        return answer;
    }
};