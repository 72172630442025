import { zodResolver } from "@hookform/resolvers/zod";
import { doc, collection } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";
import { db } from "../firebase";
import { College } from "../models/college";
import { Country } from "../models/country";
import { University } from "../models/university";
import { getAllCountries } from "../services/api";
import { CollegeSchema, TicketMessageSchema } from "../services/forms-schemas";
import UniversityPicker from "./university-picker";
import { Button, Card, Table } from "react-bootstrap";
import { TicketReply } from "../models/TicketReply";
import { useTranslation } from "react-i18next";

interface TicketMessageFormProps {
    target: TicketReply | null,
    ticketId: string,
    onSubmit?(data: any): void,
    onCancel?(): void,
}

export default function TicketReplyForm(props: TicketMessageFormProps) {

    const { target = new TicketReply() } = props;
    const { t } = useTranslation();

    type Inputs = z.infer<typeof TicketMessageSchema>;

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors } } = useForm<Inputs>({
            resolver: zodResolver(TicketMessageSchema)
        });

    const processForm: SubmitHandler<Inputs> = data => {

        const objectData = {
            message: data.message,
            ticketId: props.ticketId
        }

        if (props.onSubmit) {
            props?.onSubmit(objectData);
        }

        reset();
    }

    return (
        <form className="w-100" method="post" onSubmit={handleSubmit(processForm)}>
            <input
                type="hidden"
                value={`${props.ticketId}`}
            />
            <Card>
                <Card.Body>
                    <textarea
                        className="w-100"
                        rows={6}
                        placeholder="Enter message"
                        defaultValue={target?.message}
                        {...register("message", { value: target?.message, required: true })}
                    />
                    {errors.message?.message && (
                        <p className="small text-danger">{errors.message.message}</p>
                    )}
                </Card.Body>
                <Card.Footer>
                    <Button
                        variant="primary"
                        type="submit"
                        className="me-2 py-1 px-4"
                    >
                        {t("send_button_label")}
                    </Button>
                </Card.Footer>
            </Card>
        </form >
    );
}