import { DocumentReference, DocumentSnapshot, FieldValue, SnapshotOptions, doc, getDoc, serverTimestamp } from "firebase/firestore";
import { StudyMaterialType } from "../enums/StudeyMaterialType";
import { Collection } from "./collection";
import { College } from "./college";
import { CourseItem } from "./course_item";
import { Tutor, tutorConverter } from "./tutor";

export default class Course {
    id?: string | null;
    title: string = "";
    titleLower: string = "";
    subtitle?: string | null;
    description?: string | null;
    price: number = 0.0;
    rating: number = 0.0;
    banner: string = "";
    active: boolean = false;
    deleted: boolean = false;
    tutors: Tutor[] = [];
    collection?: Collection;
    college?: College | null;
    discount?: number;
    iosCode?: string;
    androidCode?: string;
    type: StudyMaterialType = StudyMaterialType.course;
    items?: CourseItem[] = [];
    timeCreated?: Date;
    timeModified?: Date;

    constructor() { }
}

export const courseConverter = {
    toFirestore: (course: Course) => {
        return {
            title: course?.title,
            titleLower: course?.titleLower,
            price: course?.price,
            discount: course?.discount,
            rating: course?.rating,
            type: course?.type as string,
            banner: course?.banner,
            active: course?.active,
            deleted: course?.deleted,
            iosCode: course?.iosCode,
            androidCode: course?.androidCode,
        };
    },
    fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
        const data = snapshot.data(options);

        let course = new Course();
        course.id = snapshot.id;
        course.title = data?.title;
        course.titleLower = data?.titleLower;
        course.subtitle = data?.subtitle;
        course.description = data?.description;
        course.price = parseFloat(data?.price as string);
        course.discount = data?.discount ? parseFloat(data?.discount as string) : 0;
        course.rating = parseFloat(data?.rating as string);
        course.type = data?.type;
        course.banner = data?.banner;
        course.active = data?.active;
        course.deleted = data?.deleted;
        course.iosCode = data?.iosCode;
        course.androidCode = data?.androidCode;

        if (data?.timeCreated) {
            course.timeCreated = data?.timeCreated.toDate();
        }

        if (data?.timeModified) {
            course.timeModified = data?.timeModified.toDate();
        }

        return course;
    }
};