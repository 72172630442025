import { useState, useEffect } from "react";
import PageHeader from "../../components/page-header";
import { College } from "../../models/college";
import { getCollege } from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import { Layout } from "../../Layout";
import CoursesDataGrid from "../../components/courses-data-grid";
import { useTranslation } from "react-i18next";

export default function CollegeDetailsPage() {
    const { id } = useParams<string>();
    const { t, i18n } = useTranslation();

    const [college, setCollege] = useState<College | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        getCollege(id).then((res) => {
            setCollege(res);
        })
    }, [])

    return college ? (
        <>
            <PageHeader title={t("college_title")} />
            <Table bordered striped>
                <tbody>
                    <tr>
                        <td className="w-100 field-label" colSpan={2}>
                            {t("name_column_header")}
                        </td>
                    </tr>
                    <tr>
                        {i18n.language === "en" &&
                            <>
                                <td className="w-50 text-start">
                                    {college.englishName}
                                    <br />
                                    {college?.university ? college?.university.englishName : "\u00A0"}
                                    <br />
                                    {college?.university ? college?.university?.country?.englishName : "\u00A0"}
                                </td>
                                <td className="w-50 text-end">
                                    {college.arabicName}
                                    <br />
                                    {college?.university ? college?.university.arabicName : "\u00A0"}
                                    <br />
                                    {college?.university ? college?.university?.country?.arabicName : "\u00A0"}
                                </td>
                            </>
                        }
                        {i18n.language === "ar" &&
                            <>
                                <td className="w-50 text-start">
                                    {college.arabicName}
                                    <br />
                                    {college?.university ? college?.university.arabicName : "\u00A0"}
                                    <br />
                                    {college?.university ? college?.university?.country?.arabicName : "\u00A0"}
                                </td>
                                <td className="w-50 text-end">
                                    {college.englishName}
                                    <br />
                                    {college?.university ? college?.university.englishName : "\u00A0"}
                                    <br />
                                    {college?.university ? college?.university?.country?.englishName : "\u00A0"}
                                </td>
                            </>
                        }
                    </tr>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("active_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            {college.active ? t("yes") : t("no")}
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label">
                            {t("time_created_column_header")}
                        </td>
                        <td className="field-label">
                            {t("time_modified_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {
                                college.timeCreated
                                    ? college.timeCreated.toISOString()
                                    : "\u00A0"
                            }
                        </td>
                        <td>
                            {
                                college.timeModified
                                    ? college.timeModified.toISOString()
                                    : "\u00A0"
                            }
                        </td>
                    </tr>
                </tbody>
            </Table >
            <PageHeader title={t("courses_title")} />
            <CoursesDataGrid
                mode="college"
                id={college.id}
                enableCreating={false}
                enableFiltering={false}
            />
            <div className="mt-3 float-end">
                <Button
                    type="button"
                    variant="secondary"
                    onClick={() => { navigate("/admin/colleges") }}
                >
                    {t("back_button_label")}
                </Button>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </>
    ) : (
        <div>Loading...</div>
    );
};

function useParam<T>(): { id: any; } {
    throw new Error("Function not implemented.");
}
