import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface PublishDialogProps {
    row?: any,
    visible: boolean,
    note?: string,
    onHide?(): void,
    onConfirm?(row: any): void,
};

export default function PublishDialog(props: PublishDialogProps) {
    const { t } = useTranslation();

    const [visible, setVisible] = useState(props.visible);
    const [mode, setMode] = useState(props.row?.active);

    useEffect(() => {
        setVisible(props.visible);
        setMode(props.row?.active);
    }, [props.visible, props.row]);

    return (
        <Modal show={visible} onHide={() => props.onHide && props.onHide()}>
            <Modal.Header closeButton>
                <Modal.Title>{t("warning_title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {t("publish_dialog_message", { action: mode ? t("unpublish") : t("publish") })}
                    <div className="text-danger">{props.note}</div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="danger"
                    onClick={() => props.onConfirm && props.onConfirm(props.row)}
                >
                    {mode ? <span>{t("unpublish_button_label")}</span> : <span>{t("publish_button_label")}</span>}
                </Button>
                <Button
                    variant="secondary"
                    onClick={() => props.onHide && props.onHide()}
                >
                    {t("cancel_button_label")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
