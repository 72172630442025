import { useState, useRef, FormEvent, useEffect } from "react";
import { Country } from "../models/country";
import { University } from "../models/university";
import { fetchCountryUniversities, getAllCountries } from "../services/api";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface SelectUniversityDialogProps {
    show: boolean,
    onHide?: () => void,
    onSelect?: (country?: Country, university?: University) => void,
}

export default function SelectUniversityDialog(props: SelectUniversityDialogProps) {
    let { show = false, onHide = undefined, onSelect = undefined } = props;
    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(show);

    const [countryList, setCountryList] = useState<Country[]>([]);
    const [universityList, setUniversityList] = useState<University[]>([]);

    const countryRef = useRef<HTMLSelectElement>(null);
    const universityRef = useRef<HTMLSelectElement>(null);

    const handleCountryChange = (event: FormEvent<HTMLSelectElement>) => {
        const cid = event.currentTarget?.value;

        if (universityRef.current?.value) {
            universityRef.current.value = "";
        }

        updateUniversityList(cid);
    }

    const updateUniversityList = async (cid: string) => {
        const country: Country | undefined = countryList.find((c: Country) => c.id === cid);

        if (country) {
            const universityList = await fetchCountryUniversities(country);
            setUniversityList(universityList);
        }
    }

    const handleSelectUniversity = () => {
        if (onSelect) {
            if (universityRef.current?.selectedIndex && universityRef.current?.selectedIndex > 0 &&
                countryRef.current?.selectedIndex && countryRef.current?.selectedIndex > 0) {
                const selectedCountry = countryList[countryRef.current?.selectedIndex - 1];
                const selectedUniversity = universityList[universityRef.current?.selectedIndex - 1];
                onSelect(selectedCountry, selectedUniversity);

            }
        }
    }

    useEffect(() => {
        getAllCountries().then((res) => {
            setCountryList(res);
        });

        setUniversityList([]);

        setShowModal(show);
    }, [show])

    return (
        <Modal show={showModal} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t("select_university_title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    <select
                        name="countries"
                        id="countries"
                        className="w-100"
                        ref={countryRef}
                        onChange={handleCountryChange}
                    >
                        <option key="0" value="">{t("select_country_title")}</option>
                        {countryList && countryList.map((country: Country) => (
                            <option key={country.id} value={country.id}>
                                {country.englishName} | {country.arabicName}
                            </option>
                        ))}
                    </select>
                </p>
                <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    <select
                        name="universities"
                        id="universities"
                        className="w-100"
                        ref={universityRef}
                    >
                        <option key="0" value="">{t("select_university_title")}</option>
                        {universityList && universityList.map((university: University) => (
                            <option key={university.id} value={university.id}>
                                {university.englishName} | {university.arabicName}
                            </option>
                        ))}
                    </select>
                </p>            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    {t("cancel_button_label")}
                </Button>
                <Button variant="primary" onClick={handleSelectUniversity}>
                    {t("select_button_label")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}