import React, { ReactNode } from "react";
import PropTypes from "prop-types";

interface PageHeaderProps {
    title: string;
    children?: ReactNode;
};

export function PageHeader({ title, children, ...rest }: PageHeaderProps) {
    // const { title = "", children = [] } = props;

    return (
        <div className="page-header">
            <h2>{title}</h2>
            <div>{children}</div>
        </div>
    );
};

export default PageHeader;
