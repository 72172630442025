import { doc, collection, updateDoc, onSnapshot, orderBy, query, where, DocumentSnapshot, getDoc, getDocs, writeBatch } from "firebase/firestore";
import { useState, useEffect, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import ActionPane from "../../components/action-pane";
import { DataGrid } from "../../components/data-grid";
import FilterBox from "../../components/filter-box";
import { db } from "../../firebase";
import { College, collegeConverter } from "../../models/college";
import { Button } from "react-bootstrap";
import DeleteDialog from "../../components/delete-dialog";
import { countryConverter } from "../../models/country";
import { universityConverter } from "../../models/university";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../components/loading-indicator";

const CollegesDataGrid = lazy(() =>
    import("../../components/colleges-data-grid")
);

export default function CollegesPage() {

    const { t } = useTranslation();

    return (
        <>
            <h2 className="mb-4">{t("colleges_title")}</h2>
            <Suspense fallback={<LoadingIndicator />}>
                <CollegesDataGrid />
            </Suspense>
        </>
    );
}