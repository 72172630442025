import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { CourseItemType } from "../enums/CourseItemType";
import { Link } from "react-router-dom";
import { storage } from "../firebase";
import { StorageReference, getDownloadURL, ref } from "firebase/storage";

interface PreviewDialogProps {
    show: boolean,
    url: string,
    type?: CourseItemType,
    onHide?: () => void,
}

export default function PreviewDialog(props: PreviewDialogProps) {
    const {
        show = false,
        url = "",
        type = CourseItemType.image,
        onHide = undefined
    } = props;

    const [showModal, setShowModal] = useState(show);
    const [realURL, setRealURL] = useState("")

    useEffect(() => {

        if (url) {
            const fileUrl: StorageReference = ref(storage, url)
            if (type !== CourseItemType.quiz && type !== CourseItemType.html) {
                getDownloadURL(fileUrl).then((fullUrl) => {
                    console.log(fullUrl)
                    setRealURL(fullUrl)
                }, (errors) => console.log('File not found'))
            }
        }

        setShowModal(show)
    }, [show])

    const buildPreviewControls = () => {
        switch (type) {
            case CourseItemType.image:
                return <img src={realURL} className="img-fluid" />

            case CourseItemType.video:
                return (
                    <video controls className="embed-responsive-item" style={{ width: "750px" }}>
                        <source src={realURL} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )

            case CourseItemType.audio:
                return (
                    <audio controls className="embed-responsive-item">
                        <source src={realURL} />
                        Your browser does not support the audio tag.
                    </audio>
                )

            default:
                return (
                    <Link to={url ?? ""} target="_blank">
                        Open preview in new window
                    </Link>
                )
        }
    }

    return (
        <Modal show={show} size="lg" onHide={() => props.onHide && props.onHide()}>
            <Modal.Header closeButton>
                <Modal.Title>Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body className="align-items-center justify-content-center">
                <div className="section d-flex justify-content-center embed-responsive embed-responsive-16by9">
                    {buildPreviewControls()}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => props.onHide && props.onHide()}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}