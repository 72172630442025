import { collection, doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/page-header";
import { db } from "../../firebase";
import Course from "../../models/course";
import { getCourse } from "../../services/api";
import CourseForm from "../../components/course-form";
import { useTranslation } from "react-i18next";
import { setErrorMap } from "zod";
import SavingIndicator from "../../components/saving-indicator";

export default function CourseEditPage() {
    const { id } = useParams<string>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [course, setCourse] = useState<Course | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (data: any) => {
        setLoading(true);

        // Add timeModified
        data.timeModified = serverTimestamp();

        // Temp Solution for missing timeCreated
        if (!course?.timeCreated) {
            data["timeCreated"] = serverTimestamp();
        }

        if (course) {
            var newData = { ...course, ...data };

            if (isNaN(newData["rating"])) {
                newData["rating"] = 0;
            }

            if (isNaN(newData["discount"])) {
                newData["discount"] = null;
            }

            if (newData["collection"] === undefined) {
                newData["collection"] = null;
            }

            // Remove unneeded fields
            delete newData['id'];
            delete newData['items'];

            newData.titleLower = newData.title.toLowerCase();

            try {
                const ref = collection(db, 'courses');
                await updateDoc(doc(ref, id), newData);
                navigate("/admin/courses");
            } catch (e) {
                console.log(e);
                setError('Error!!');
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        if (id) {
            getCourse(id).then((course: Course | null) => {
                if (course) {
                    setCourse(course);
                } else {
                    setError("Course not found!!");
                }
            });
        } else {
            setCourse(null);
        }
    }, [id]);

    return (
        <>
            <PageHeader title={t("edit_course_title")} />
            {course
                ? (
                    <>
                        {error &&
                            <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                                {error}
                            </div>
                        }
                        {loading
                            ? <SavingIndicator />
                            : <CourseForm
                                target={course}
                                onSubmit={handleSubmit}
                                onCancel={() => navigate("/admin/courses")}
                            />
                        }
                    </>
                )
                : <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                    {error}
                </div>
            }
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </>
    );
};
