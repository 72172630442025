import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./logo.png";
import { IAuth, useAuth } from "./contexts/AuthContext";
import { User } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { useLayoutDirection } from "./LayoutDirectionProvider";

export const Login = () => {
    const { signIn, currentUser } = useAuth() as IAuth;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isRTL } = useLayoutDirection();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [notice, setNotice] = useState("");

    const getUserClaims = async (user: User) => {

        // If refresh is set to true, a refresh of the id token is forced.
        const idTokenResult = await user?.getIdTokenResult(true);

        return idTokenResult?.claims;
    }
    const loginWithUsernameAndPassword = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        try {
            const credentials = await signIn(email, password);
            if (credentials) {
                const claims = await getUserClaims(credentials.user);
                if (claims.role === "admin" || claims.role === "tutor") {
                    navigate("/admin/dashboard");
                } else {
                    setNotice("You are not authorized to access this website.");
                }
            } else {
                setNotice("You entered a wrong username or password.");
            }
        } catch {
            setNotice("You entered a wrong username or password.");
        }
    }

    return (
        <>
            <link
                rel="stylesheet"
                type="text/css"
                href={`/css/${isRTL() ? "bootstrap.rtl.min.css" : "bootstrap.min.css"}`}
            />

            <div className="container">
                <div className="row justify-content-center mt-3 pt-3 pb-3">
                    {/* <div className="col-md-4 mt-3 pt-3 pb-3"> */}
                    <img src={logo} alt="Coursat" style={{ width: "165px" }} />
                    {/* </div> */}
                </div>
                <div className="row justify-content-center">
                    <form className="col-md-4 mt-3 pt-3 pb-3">
                        {"" !== notice &&
                            <div className="alert alert-warning" role="alert">
                                {notice}
                            </div>
                        }
                        <div className="form-floating mb-3">
                            <input
                                type="email"
                                className="form-control"
                                id="emailInput"
                                placeholder="name@example.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <label
                                htmlFor="emailInput"
                                className="form-label"
                            >
                                {t("email_label")}
                            </label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                type="password"
                                className="form-control"
                                id="passwordInput"
                                placeholder={t("password_hint")}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <label
                                htmlFor="passwordInput"
                                className="form-label"
                            >
                                {t("password_label")}
                            </label>
                        </div>
                        <div className="d-grid">
                            <button
                                type="submit"
                                className="btn btn-primary pt-3 pb-3"
                                onClick={(e) => loginWithUsernameAndPassword(e)}
                            >
                                {t("login_button_label")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}