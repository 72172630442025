import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/page-header";
import { getTicket, getTicketReplies, setTicketStatus } from "../../services/api";
import { Button, Card, Container } from "react-bootstrap";
import { Ticket } from "../../models/ticket";
import TicketReplyForm from "../../components/ticket-reply-form";
import { addDoc, collection, doc, onSnapshot, orderBy, query, serverTimestamp } from "firebase/firestore";
import { auth, db } from "../../firebase";
import { TicketReply, ticketReplyConverter } from "../../models/TicketReply";
import { TicketStatus } from "../../enums/TicketStatus";
import { useTranslation } from "react-i18next";

export default function TicketDetailsPage() {
    const { id } = useParams<string>();
    const { t } = useTranslation();
    const [ticket, setTicket] = useState<Ticket | null>(null);
    const [messages, setMessages] = useState<TicketReply[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        getTicket(id).then((res: Ticket | null) => {

            if (res) {
                setTicket(res);
                if (res.status === TicketStatus.created) {
                    setTicketStatus(res.id!, TicketStatus.open);
                }

                getTicketReplies(res.id ?? "").then((replies) => {
                    const newReplies: TicketReply[] = [];

                    const q = query(collection(db, `tickets/${res.id}/messages`), orderBy("timeCreated")).withConverter(ticketReplyConverter);

                    const unsubscribe = onSnapshot(q, (querySnapshot) => {
                        let itemsArr: any = [];

                        querySnapshot.forEach((doc) => {
                            itemsArr.push({ ...doc.data(), id: doc.id });
                        });

                        setMessages(itemsArr);
                    })

                    return () => unsubscribe();
                })
            }
        })
    }, [])

    const handleSendReply = async (data: any) => {
        try {
            const userRef = doc(db, `students/${auth.currentUser?.uid}`);

            // Add dates
            data.timeCreated = serverTimestamp();
            data.timeModified = serverTimestamp();

            data.owner = userRef;
            data.status = TicketStatus.created;

            let ticketId = data.ticketId;

            delete data.ticketId;

            const ticketsCollection = collection(db, 'tickets', ticketId, 'messages');

            const newReply = await addDoc(ticketsCollection, data);

            navigate(`/admin/tickets/${ticketId}`);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <PageHeader title={t("ticket_title")} />
            <Container fluid>
                {ticket ? (
                    <>
                        <Card>
                            <Card.Header>
                                {ticket.timeCreated.toDateString()}
                            </Card.Header>
                            <Card.Body>
                                <Card.Title>{ticket.subject}</Card.Title>
                                <Card.Text>
                                    {ticket.message}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        {messages.map((msg) => (
                            <>
                                <Card key={msg.id} className="mt-2">
                                    <Card.Header>
                                        On {msg.timeCreated.toDateString()}&nbsp;
                                        {msg.owner?.displayName} wrote
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            {msg.message}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </>
                        ))}
                        <div className="mt-4">
                            <TicketReplyForm
                                target={null}
                                ticketId={`${ticket.id}`}
                                onSubmit={handleSendReply}
                            />
                        </div>
                        <div className="mt-3 float-end">
                            <Button type="button" variant="secondary" className="px-4 py-2"
                                onClick={() => { navigate("/admin/tickets") }}>
                                {t("back_button_label")}
                            </Button>
                        </div>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </>
                ) : (
                    <div>Loading...</div>
                )}
            </Container >
        </>
    )
};