import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/page-header";
import { College } from "../../models/college";
import { University } from "../../models/university";
import { getUniversity, fetchUniversityColleges } from "../../services/api";
import { Button, Container, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function UnivertyDetailsPage() {
    const { id } = useParams<string>();
    const { t, i18n } = useTranslation();

    const [university, setUniversity] = useState<University | null>(null);
    const [colleges, setColleges] = useState<College[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        getUniversity(id).then((res: University | null) => {
            setUniversity(res);

            if (res) {
                fetchUniversityColleges(res).then((res) => {
                    setColleges(res);
                })
            }
        })
    })

    return (
        <>
            <PageHeader title={t("university_title")} />
            <Container fluid>
                {university ? (
                    <>
                        <Table bordered striped>
                            <tbody>
                                <tr>
                                    <td className="field-label" colSpan={2}>
                                        {t("name_column_header")}
                                    </td>
                                </tr>
                                <tr>
                                    {i18n.language === "en" &&
                                        <>
                                            <td className="w-50 text-start">
                                                {university.englishName}
                                            </td>
                                            <td className="w-50 text-end" >
                                                {university.arabicName}
                                            </td>
                                        </>
                                    }
                                    {i18n.language === "ar" &&
                                        <>
                                            <td className="w-50 text-start">
                                                {university.arabicName}
                                            </td>
                                            <td className="w-50 text-end" >
                                                {university.englishName}
                                            </td>
                                        </>
                                    }
                                </tr>
                                <tr>
                                    <td className="field-label" colSpan={2}>
                                        {t("logo_column_header")}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        {university.logoUrl ? university.logoUrl : "\u00A0"}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="field-label" colSpan={2}>
                                        {t("country_column_header")}
                                    </td>
                                </tr>

                                <tr>
                                    {i18n.language === "en" &&
                                        <>
                                            <td className="w-50 text-start">
                                                {university?.country ? `${university?.country.englishName}` : "\u00A0"}
                                            </td>
                                            <td className="w-50 text-end">
                                                {university?.country ? `${university?.country.arabicName}` : "\u00A0"}
                                            </td>
                                        </>
                                    }
                                    {i18n.language === "ar" &&
                                        <>
                                            <td className="w-50 text-start">
                                                {university?.country ? `${university?.country.arabicName}` : "\u00A0"}
                                            </td>
                                            <td className="w-50 text-end">
                                                {university?.country ? `${university?.country.englishName}` : "\u00A0"}
                                            </td>
                                        </>
                                    }
                                </tr>

                                <tr>
                                    <td className="field-label" colSpan={2}>
                                        {t("colleges_column_header")}
                                    </td>
                                </tr>
                                {colleges.map((college) => (
                                    <tr key={college.id}>
                                        {i18n.language === "en" &&
                                            <>
                                                <td className="w-50 text-start">
                                                    {college.englishName}
                                                </td>
                                                <td className="w-50 text-end">
                                                    {college.arabicName}
                                                </td>
                                            </>
                                        }
                                        {i18n.language === "ar" &&
                                            <>
                                                <td className="w-50 text-start">
                                                    {college.arabicName}
                                                </td>
                                                <td className="w-50 text-end">
                                                    {college.englishName}
                                                </td>
                                            </>
                                        }
                                    </tr>
                                ))}
                                <tr>
                                    <td className="field-label" colSpan={2}>
                                        {t("active_column_header")}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        {university.active ? t("yes") : t("no")}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="field-label">
                                        {t("time_created_column_header")}
                                    </td>
                                    <td className="field-label">
                                        {t("time_modified_column_header")}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {
                                            university.timeCreated
                                                ? university.timeCreated.toISOString()
                                                : "\u00A0"
                                        }
                                    </td>
                                    <td>
                                        {
                                            university.timeModified
                                                ? university.timeModified.toISOString()
                                                : "\u00A0"
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <div className="mt-3 float-end">
                            <Button type="button" variant="secondary" className="px-4 py-2"
                                onClick={() => { navigate("/admin/universities") }}>
                                {t("back_button_label")}
                            </Button>
                        </div>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                    </>
                ) : (
                    <div>Loading...</div>
                )}
            </Container >
        </>
    )
};