import { QuestionType } from "../enums/QuestionType";
import { StudyMaterialType } from "../enums/StudeyMaterialType";
import { z } from "zod";


export const TicketMessageSchema = z.object({
    id: z.string().optional(),
    message: z.string().min(1, "Message is required"),
})

export const CountrySchema = z.object({
    id: z.string().optional(),
    arabicName: z.string().min(1, "Name is required"),
    englishName: z.string().min(1, "Name is required"),
    countryCode: z.string().min(1, "Code is required"),
    active: z.boolean().default(false),
})

export const UniversitySchema = z.object({
    id: z.string().optional(),
    arabicName: z.string().min(1, "Name is required"),
    englishName: z.string().min(1, "Name is required"),
    country: z.string().min(1, "Country is required"),
    logoUrl: z.string().optional(),
    active: z.boolean().default(false),
})

export const CollegeSchema = z.object({
    id: z.string().optional(),
    arabicName: z.string().min(1, "Name is required"),
    englishName: z.string().min(1, "Name is required"),
    university: z.string().min(1, "University is required"),
    active: z.boolean().default(false),
})

export const QuizSchema = z.object({
    id: z.string().optional(),
    description: z.string().min(1, "Description is required"),
})

export const QuestionSchema = z.object({
    id: z.string().optional(),
    body: z.string().min(1, "Body is required"),
    type: z.nativeEnum(QuestionType),
    answers: z.object({
        id: z.string().optional(),
        body: z.string().min(1, "Body is required"),
        correct: z.boolean().default(false),
    }).array().min(2, "Not enough answers")
}).refine((val) =>
    val.answers.filter(e => e.correct).length > 0,
    {
        message: "No correct answer",
        path: ["answers"],
    }
)

export const AnswerSchema = z.object({
    id: z.string().optional(),
    body: z.string().min(1, "Body is required"),
    correct: z.boolean().default(false),
})

export const VoucherSchema = z.object({
    id: z.string().optional(),
    code: z.string().min(4, "Code must 4 or more characters"),
    startDate: z.date().nullable(),
    endDate: z.date().nullable(),
    discount: z.number(),
    times: z.number().nullable(),
    datesEnabled: z.boolean().default(false),
    timesEnabled: z.boolean().default(false),
    active: z.boolean().default(false),
}).superRefine((data, ctx) => {
    if (!data.datesEnabled && !data.timesEnabled) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["startDate"],
            message: "Please specify dates or times or both",
        });
    }

    if (data.datesEnabled) {
        if (!data.startDate) {
            ctx.addIssue({
                code: z.ZodIssueCode.invalid_date,
                path: ["startDate"],
                message: "Please specify a start date",
            });
        }
        if (!data.endDate) {
            ctx.addIssue({
                code: z.ZodIssueCode.invalid_date,
                path: ["endDate"],
                message: "Please specify an end date",
            });
        }
    }

    if (data.timesEnabled) {
        if (!data.times) {
            ctx.addIssue({
                code: z.ZodIssueCode.invalid_date,
                path: ["times"],
                message: "Please specify times",
            });
        }
    }

    return z.NEVER;
});

export const FcmMessageSchema = z.object({
    title: z.string().min(1, "Title is required"),
    body: z.string().min(1, "Body is required"),
    topic: z.string().min(1, "topic is required"),
})

export const PriceSchema = z.object({
    id: z.string().optional(),
    usd: z.number().min(0, "price is required"),
    jod: z.number().min(0, "price is required"),
})

export const TutorEditSchema = z.object({
    id: z.string().optional(),
    arabicName: z.string().min(1, "Name is required"),
    englishName: z.string().min(1, "Name is required"),
    photoUrl: z.string().min(1, "Please upload photo"),
    active: z.boolean().default(false),
})

export const TutorSchema = z.object({
    id: z.string().optional(),
    arabicName: z.string().min(1, "Name is required"),
    englishName: z.string().min(1, "Name is required"),
    email: z.string()
        .min(1, "Email is required")
        .email("Please enter a valid email."),
    password: z.string()
        .min(8, "Password must be 8 characters at least"),
    photoUrl: z.string().min(1, "Please upload photo"),
    active: z.boolean().default(false),
}).superRefine(({ password }, checkPassComplexity) => {
    const containsUppercase = (ch: string) => /[A-Z]/.test(ch);
    const containsLowercase = (ch: string) => /[a-z]/.test(ch);
    const containsSpecialChar = (ch: string) =>
        /[`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~ ]/.test(ch);

    let countOfUpperCase = 0,
        countOfLowerCase = 0,
        countOfNumbers = 0,
        countOfSpecialChar = 0;

    for (let i = 0; i < password.length; i++) {
        let ch = password.charAt(i);
        if (!isNaN(+ch)) countOfNumbers++;
        else if (containsUppercase(ch)) countOfUpperCase++;
        else if (containsLowercase(ch)) countOfLowerCase++;
        else if (containsSpecialChar(ch)) countOfSpecialChar++;
    }

    if (
        countOfLowerCase < 1 ||
        countOfUpperCase < 1 ||
        countOfSpecialChar < 1 ||
        countOfNumbers < 1
    ) {
        checkPassComplexity.addIssue({
            code: "custom",
            path: ["password"],
            message: "password does not meet complexity requirements",
        });
    }
});

export const CourseItemSchema = z.object({
    id: z.string().optional(),
    title: z.string().min(1, "Title is required"),
    url: z.string().nullable(),
    length: z.number().default(0),
    place: z.number().default(999),
    type: z.string().min(1, "Type is required"),
    free: z.boolean().default(false),
    active: z.boolean().default(false),
})

export const CourseSchema = z.object({
    id: z.string().optional(),
    title: z.string().min(1, "Title is required"),
    subtitle: z.string().nullable(),
    description: z.string().nullable(),
    banner: z.string().min(1, "Banner is required"),
    price: z.number().min(0, "price is required"),
    discount: z.number().nullable(),
    active: z.boolean().default(false),
    deleted: z.boolean().default(false),
    type: z.string().default(StudyMaterialType.course),
    tutors: z.string().array(),
    college: z.string().min(1, "College is required"),
    iosCode: z.string().optional(),
    androidCode: z.string().optional(),
})