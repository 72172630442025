import Course, { courseConverter } from "../models/course";
import {
    DocumentData,
    DocumentReference, DocumentSnapshot,
    QueryDocumentSnapshot,
    addDoc, collection, doc, getDoc, getDocs,
    orderBy,
    query, setDoc, updateDoc, where
} from "firebase/firestore";
import { db } from "../firebase";
import { College, collegeConverter } from "../models/college";
import { Country, countryConverter } from "../models/country";
import { University, universityConverter } from "../models/university";
import { Tutor, tutorConverter } from "../models/tutor";
import { CourseItem, courseItemConverter } from "../models/course_item";
import { StudyMaterialType } from "../enums/StudeyMaterialType";
import Review from "../models/reviewe";
import Order, { orderConverter } from "../models/order";
import Voucher, { voucherConverter } from "../models/voucher";
import { Ticket, ticketConverter } from "../models/ticket";
import { TicketReply, ticketReplyConverter } from "../models/TicketReply";
import { TicketStatus } from "../enums/TicketStatus";
import { Student, studentConverter } from "../models/Student";
import Price, { priceConverter } from "../models/price";

export const getCourse = (async (id: string): Promise<Course | null> => {
    const ref = doc(db, "courses", id);
    const snapshot = await getDoc(ref);
    let course: Course | null = null;

    if (snapshot.exists()) {
        course = courseConverter.fromFirestore(snapshot, {});
        if (course.type !== StudyMaterialType.course) {
            return null;
        }

        course?.tutors.push(...await fetchCourseTutors(snapshot));

        const courseData = snapshot?.data();

        if (courseData) {
            if (courseData.items !== undefined) {
                const courseItems = courseData.items.map((e: any) => {
                    const courseItem = new CourseItem();
                    courseItem.title = e?.title;
                    courseItem.url = e?.url;
                    courseItem.length = parseFloat(e?.length as string);
                    courseItem.type = e?.type;
                    courseItem.free = e?.free;
                    courseItem.active = e?.active;

                    return courseItem;
                })

                course.items = courseItems;
            }

            if (courseData?.college) {
                const collegeSnapshot: DocumentSnapshot = await getDoc(courseData.college);
                course.college = collegeConverter.fromFirestore(collegeSnapshot, {});

                if (collegeSnapshot.data()?.university) {
                    const universitySnapshot: DocumentSnapshot = await getDoc(collegeSnapshot.data()?.university);
                    course.college.university = universityConverter.fromFirestore(universitySnapshot, {});

                    if (universitySnapshot.data()?.country) {
                        const countrySnapshot: DocumentSnapshot = await getDoc(universitySnapshot.data()?.country);
                        course.college.university.country = countryConverter.fromFirestore(countrySnapshot, {});
                    }
                }
            }
        }
    }

    return course;
})

export const getReview = (async (id: string): Promise<Review | null> => {
    const ref = doc(db, "courses", id);
    const snapshot = await getDoc(ref);
    let course: Course | null = null;

    if (snapshot.exists()) {
        course = courseConverter.fromFirestore(snapshot, {});
        if (course.type !== StudyMaterialType.review) {
            return null;
        }

        course?.tutors.push(...await fetchCourseTutors(snapshot));

        const courseData = snapshot?.data();

        if (courseData) {
            if (courseData.items !== undefined) {
                const courseItems = courseData.items.map((e: any) => {
                    const courseItem = new CourseItem();
                    courseItem.title = e?.title;
                    courseItem.url = e?.url;
                    courseItem.length = parseFloat(e?.length as string);
                    courseItem.type = e?.type;
                    courseItem.free = e?.free;
                    courseItem.active = e?.active;

                    return courseItem;
                })

                course.items = courseItems;
            }

            if (courseData?.college) {
                const collegeSnapshot: DocumentSnapshot = await getDoc(courseData.college);
                course.college = collegeConverter.fromFirestore(collegeSnapshot, {});

                if (collegeSnapshot.data()?.university) {
                    const universitySnapshot: DocumentSnapshot = await getDoc(collegeSnapshot.data()?.university);
                    course.college.university = universityConverter.fromFirestore(universitySnapshot, {});

                    if (universitySnapshot.data()?.country) {
                        const countrySnapshot: DocumentSnapshot = await getDoc(universitySnapshot.data()?.country);
                        course.college.university.country = countryConverter.fromFirestore(countrySnapshot, {});
                    }
                }
            }
        }
    }

    return course;
})


export const fetchCourseTutors = (async (courseSnapshot: DocumentSnapshot) => {
    const data = courseSnapshot.data();

    if (data?.tutors) {
        let tutors = await data?.tutors && data?.tutors.map(async (t: DocumentReference) => {
            const snapshot = await getDoc(t);
            const tutor = tutorConverter.fromFirestore(snapshot, {});
            return tutor;
        });

        return await Promise.all(tutors);
    }

    return [];
})

export const fetchCourseCollege = (async (courseSnapshot: DocumentSnapshot) => {
    const data = courseSnapshot.data();

    if (data?.college) {
        const tx = await getDoc(data?.college.withConverter(collegeConverter));
        return tx.data() as College;
    }

    return null;
})

export const getAllCourses = (async (type: StudyMaterialType = StudyMaterialType.course) => {
    const data: DocumentSnapshot[] = [];

    const dataRef = collection(db, "courses");
    const q = query(dataRef, where("type", "==", type));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        const c = doc.data();
        if (!c.deleted) {
            data.push(doc);
        }
    });

    let promises = data.map(async (doc: DocumentSnapshot) => {
        const course = courseConverter.fromFirestore(doc, {});

        if (doc.data()?.college) {
            const collegeSnapshot: DocumentSnapshot = await getDoc(doc.data()?.college); //.withConverter(collegeConverter));
            course.college = collegeConverter.fromFirestore(collegeSnapshot, {}); //.data() as College;

            if (collegeSnapshot.data()?.university) {
                const universitySnapshot: DocumentSnapshot = await getDoc(collegeSnapshot.data()?.university);
                course.college.university = universityConverter.fromFirestore(universitySnapshot, {});

                if (universitySnapshot.data()?.country) {
                    const countrySnapshot: DocumentSnapshot = await getDoc(universitySnapshot.data()?.country);
                    course.college.university.country = countryConverter.fromFirestore(countrySnapshot, {});
                }
            }
        }

        return course;
    });

    return await Promise.all(promises);
})

export const getTutorCourses = (async (id: string, type: StudyMaterialType = StudyMaterialType.course) => {
    const tutorRef = doc(collection(db, "tutors"), id);

    const data: DocumentSnapshot[] = [];

    const dataRef = collection(db, "courses");
    const q = query(dataRef,
        where("tutors", "array-contains", tutorRef),
        where("type", "==", type)
    );

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        data.push(doc);
    });

    let promises = data.map(async (doc: DocumentSnapshot) => {
        const course = courseConverter.fromFirestore(doc, {});

        if (doc.data()?.college) {
            const collegeSnapshot: DocumentSnapshot = await getDoc(doc.data()?.college); //.withConverter(collegeConverter));
            course.college = collegeConverter.fromFirestore(collegeSnapshot, {}); //.data() as College;

            if (collegeSnapshot.data()?.university) {
                const universitySnapshot: DocumentSnapshot = await getDoc(collegeSnapshot.data()?.university);
                course.college.university = universityConverter.fromFirestore(universitySnapshot, {});

                if (universitySnapshot.data()?.country) {
                    const countrySnapshot: DocumentSnapshot = await getDoc(universitySnapshot.data()?.country);
                    course.college.university.country = countryConverter.fromFirestore(countrySnapshot, {});
                }
            }
        }

        return course;
    });

    return await Promise.all(promises);
})

export const getTutorReviews = (async (id: string, type: StudyMaterialType = StudyMaterialType.course) => {
    const tutorRef = doc(collection(db, "tutors"), id);

    const data: DocumentSnapshot[] = [];

    const dataRef = collection(db, "courses");
    const q = query(dataRef,
        where("tutors", "array-contains", tutorRef),
        where("type", "==", "review")
    );

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        data.push(doc);
    });

    let promises = data.map(async (doc: DocumentSnapshot) => {
        const course = courseConverter.fromFirestore(doc, {});

        if (doc.data()?.college) {
            const collegeSnapshot: DocumentSnapshot = await getDoc(doc.data()?.college); //.withConverter(collegeConverter));
            course.college = collegeConverter.fromFirestore(collegeSnapshot, {}); //.data() as College;

            if (collegeSnapshot.data()?.university) {
                const universitySnapshot: DocumentSnapshot = await getDoc(collegeSnapshot.data()?.university);
                course.college.university = universityConverter.fromFirestore(universitySnapshot, {});

                if (universitySnapshot.data()?.country) {
                    const countrySnapshot: DocumentSnapshot = await getDoc(universitySnapshot.data()?.country);
                    course.college.university.country = countryConverter.fromFirestore(countrySnapshot, {});
                }
            }
        }

        return course;
    });

    return await Promise.all(promises);
})

export const getCollegeCourses = (async (id: string, type: StudyMaterialType = StudyMaterialType.course) => {
    const collegeRef = doc(collection(db, "colleges"), id);

    const data: DocumentSnapshot[] = [];

    const dataRef = collection(db, "courses");
    const q = query(dataRef, where("college", "==", collegeRef));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        data.push(doc);
    });

    let promises = data.map(async (doc: DocumentSnapshot) => {
        const course = courseConverter.fromFirestore(doc, {});

        if (doc.data()?.college) {
            const collegeSnapshot: DocumentSnapshot = await getDoc(doc.data()?.college); //.withConverter(collegeConverter));
            course.college = collegeConverter.fromFirestore(collegeSnapshot, {}); //.data() as College;

            if (collegeSnapshot.data()?.university) {
                const universitySnapshot: DocumentSnapshot = await getDoc(collegeSnapshot.data()?.university);
                course.college.university = universityConverter.fromFirestore(universitySnapshot, {});

                if (universitySnapshot.data()?.country) {
                    const countrySnapshot: DocumentSnapshot = await getDoc(universitySnapshot.data()?.country);
                    course.college.university.country = countryConverter.fromFirestore(countrySnapshot, {});
                }
            }
        }

        return course;
    });

    return await Promise.all(promises);
})

export const getTutor = (async (id: string): Promise<Tutor | null> => {
    const ref = doc(db, "tutors", id).withConverter(tutorConverter);
    let res = await getDoc(ref);
    return res.exists() ? res.data() as Tutor : null;
})

export const getPrice = (async (id: string): Promise<Price | null> => {
    const ref = doc(db, "prices", id).withConverter(priceConverter);
    let res = await getDoc(ref);
    return res.exists() ? res.data() as Price : null;
})

export const getAllTutors = (async () => {
    const snapshot = await getDocs(collection(db, "tutors")
        .withConverter(tutorConverter));

    const data: any = [];

    snapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
    });

    return data;
})

export const getUniversity = (async (id?: string): Promise<University | null> => {
    if (id) {
        const ref = doc(db, "universities", id);
        let res = await getDoc(ref);

        let university: University | null = null;

        if (res.exists()) {
            university = universityConverter.fromFirestore(res, {});

            if (res.data()["country"]) {
                university.country = await fetchUniversityCountry(res);
            }

            return university;
        }
    }

    return null;
})

export const fetchUniversityCountry = (async (universitySnapshot: DocumentSnapshot) => {
    const data = universitySnapshot.data();

    if (data?.country) {
        const country = await getDoc(data?.country.withConverter(countryConverter));
        return country.data() as Country;
    }

    return null;
})

export const getAllUniversities = (async () => {
    const querySnapshot = await getDocs(collection(db, "universities"));

    const data: QueryDocumentSnapshot[] = [];

    querySnapshot.forEach(async (d) => {
        var c = d.data();
        if (!c.deleted) {
            data.push(d);
        }
    });

    let promises = data.map(async (universityDoc: DocumentSnapshot) => {
        const university = universityConverter.fromFirestore(universityDoc, {});

        if (universityDoc.data()?.country) {
            const countrySnapshot = await getDoc(universityDoc.data()?.country.withConverter(countryConverter));
            university.country = countrySnapshot.data() as Country;
        }

        return university;
    });

    return await Promise.all(promises);
})

export const getAllColleges2 = (async () => {
    const snapshot = await getDocs(collection(db, "colleges"));

    const colleges: College[] = [];

    snapshot.forEach(async (collegeSnapshot) => {
        const college = collegeConverter.fromFirestore(collegeSnapshot, {});

        if (collegeSnapshot.data()?.university) {
            const universitySnapshot = await getDoc(collegeSnapshot.data()?.university) as DocumentSnapshot;
            college.university = universityConverter.fromFirestore(universitySnapshot, {});

            if (universitySnapshot.data()?.country) {
                const countrySnapshot = await getDoc(universitySnapshot.data()?.country) as DocumentSnapshot;
                college.university.country = countryConverter.fromFirestore(countrySnapshot, {});
            }
        }

        colleges.push(college);
    });

    return colleges;
})

export const getAllColleges = (async () => {
    const snapshot = await getDocs(collection(db, "colleges"));

    const data: DocumentSnapshot[] = [];

    snapshot.forEach(async (doc) => {
        const c = doc.data();
        if (!c.deleted) {
            data.push(doc);
        }
    });

    let promises = data.map(async (doc: DocumentSnapshot) => {
        const college = collegeConverter.fromFirestore(doc, {});

        if (doc.data()?.university) {
            const universitySnapshot: DocumentSnapshot = await getDoc(doc.data()?.university);
            college.university = universityConverter.fromFirestore(universitySnapshot, {});

            if (universitySnapshot.data()?.country) {
                const countrySnapshot: DocumentSnapshot = await getDoc(universitySnapshot.data()?.country);
                college.university.country = countryConverter.fromFirestore(countrySnapshot, {});
            }
        }

        return college;
    });

    return await Promise.all(promises);
})

export const getCollege = (async (id?: string) => {
    if (id) {
        const ref = doc(db, "colleges", id);
        let collegeSnapshot = await getDoc(ref);

        let college: College | null = null;

        if (collegeSnapshot.exists()) {
            college = collegeConverter.fromFirestore(collegeSnapshot, {});

            if (collegeSnapshot.data()?.university) {
                const universitySnapshot = await getDoc(collegeSnapshot.data()?.university) as DocumentSnapshot;
                college.university = universityConverter.fromFirestore(universitySnapshot, {});

                if (universitySnapshot.data()?.country) {
                    const countrySnapshot = await getDoc(universitySnapshot.data()?.country) as DocumentSnapshot;
                    college.university.country = countryConverter.fromFirestore(countrySnapshot, {});
                }
            }
        }

        return college;
    }

    return null;
})

export const fetchCollegeUniversity = (async (collegeSnapshot: DocumentSnapshot) => {
    const data = collegeSnapshot.data();

    if (data?.university) {
        const res = await getDoc(data?.university as DocumentReference);;

        let university: University | null = null;

        if (res.exists()) {
            university = universityConverter.fromFirestore(res, {});

            if (res?.data().country) {
                university.country = await fetchUniversityCountry(res);
            }

            return university;
        }
    }

    return null;
})

export const getAllCountries = (async () => {
    const snapshot = await getDocs(collection(db, "countries")
        .withConverter(countryConverter));

    const data: any = [];

    snapshot.forEach((doc) => {
        var c = doc.data();
        if (!c.deleted) {
            data.push({ ...c, id: doc.id });
        }
    });

    return data;
})

export const getCountry = (async (id?: string) => {
    if (id) {
        const ref = doc(db, "countries", id);
        let res = await getDoc(ref);

        let country: Country | null = null;

        if (res.exists()) {
            country = countryConverter.fromFirestore(res, {});
            // course?.tutors.push(...await fetchCoursetutors(res));

            // if (res.data()["college"]) {
            //     course.college = await fetchCourseCollege(res);
            // }
        }

        return country;
    }

    return null;
})

export const fetchCountryUniversities = (async (country: Country) => {
    const universities: University[] = [];

    const countryRef = doc(collection(db, "countries"), country.id);
    const universityCollection = collection(db, "universities");

    const q = query(universityCollection, where("country", "==", countryRef));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        let u = universityConverter.fromFirestore(doc, {});
        u.id = doc.id;
        universities.push(u);
    });

    return universities;
})

export const fetchUniversityColleges = (async (university: University) => {
    const colleges: College[] = [];

    const universityRef = doc(collection(db, "universities"), university.id);
    const collegesCollection = collection(db, "colleges");

    const q = query(collegesCollection, where("university", "==", universityRef)).withConverter(collegeConverter);

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        colleges.push(doc.data() as College);
    });

    return colleges;
})

export const addDocument = async (collectioName: string, data: any): Promise<string> => {
    const tutorsCollection = collection(db, collectioName);
    const newDocument = await addDoc(tutorsCollection, data);

    return newDocument.id;
}

export const updateDocument = async (collectionName: string, id: string, data: any): Promise<void> => {
    const collectionRef = collection(db, collectionName);
    await updateDoc(doc(collectionRef, id), data);
}

export const getCourseContents = (async (id: string): Promise<CourseItem[]> => {
    const contents: CourseItem[] = [];

    const collectionRef = collection(db, `/courses/${id}/contents`)
        .withConverter(courseItemConverter);

    const q = query(collectionRef, orderBy("place"));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        contents.push(doc.data());
    });

    return contents;
})

export const getCourseContent = (async (courseId: string, id: string): Promise<CourseItem | null> => {
    const ref = doc(db, `/courses/${courseId}/contents`, id).withConverter(courseItemConverter);
    const item = await getDoc(ref);
    return item.exists() ? item.data() : null;
})

export const getOrder = (async (id: string): Promise<Order | null> => {
    const ref = doc(db, "orders", id).withConverter(orderConverter);
    let res = await getDoc(ref);
    return res.exists() ? res.data() as Order : null;
})

export const getVoucher = (async (id: string): Promise<Voucher | null> => {
    const ref = doc(db, "vouchers", id).withConverter(voucherConverter);
    let res = await getDoc(ref);
    return res.exists() ? res.data() as Voucher : null;
})

export const getAllTickets = (async () => {
    const data: DocumentSnapshot[] = [];

    const dataRef = collection(db, "tickets");
    const q = query(dataRef);

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        data.push(doc);
    });
})

export const getTicket = (async (id?: string): Promise<Ticket | null> => {
    if (id) {
        const ref = doc(db, "tickets", id);
        let res = await getDoc(ref);

        let ticket: Ticket | null = null;

        if (res.exists()) {
            ticket = ticketConverter.fromFirestore(res, {});

            return ticket;
        }
    }

    return null;
})

export const getTicketReplies = (async (id: string): Promise<TicketReply[]> => {
    const contents: TicketReply[] = [];

    const collectionRef = collection(db, "tickets", id, "messages")
        .withConverter(ticketReplyConverter);

    const q = query(collectionRef, orderBy("timeCreated"));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        contents.push(doc.data());
    });

    return contents;
})

export const setTicketStatus = (async (id: string, newStatus: TicketStatus) => {
    updateDoc(doc(db, `tickets/${id}`), { status: newStatus });
})

export const getStudentOrders = (async (studentId: string) => {
    const data: Order[] = [];

    const studentRef = doc(collection(db, "students"), studentId);

    const dataRef = collection(db, "orders").withConverter(orderConverter);
    const q = query(dataRef, where("student", "==", studentRef));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        data.push(doc.data());
    });

    return data;
})

export const getStudentCourses = (async (studentId: string) => {
    const data: DocumentSnapshot[] = [];

    const studentRef = doc(collection(db, "students"), studentId);

    const dataRef = collection(db, "student_courses");
    const q = query(dataRef, where("student", "==", studentRef));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
        data.push(doc);
    });

    let promises = data.map(async (snapshot: DocumentSnapshot) => {
        const data = snapshot.data();
        if (data) {
            const courseSnapshot: DocumentSnapshot<DocumentData, DocumentData> = await getDoc(data.course);
            const course = courseConverter.fromFirestore(courseSnapshot, {});

            if (courseSnapshot.data()?.college) {
                const collegeSnapshot: DocumentSnapshot = await getDoc(courseSnapshot.data()?.college); //.withConverter(collegeConverter));
                course.college = collegeConverter.fromFirestore(collegeSnapshot, {}); //.data() as College;

                if (collegeSnapshot.data()?.university) {
                    const universitySnapshot: DocumentSnapshot = await getDoc(collegeSnapshot.data()?.university);
                    course.college.university = universityConverter.fromFirestore(universitySnapshot, {});

                    if (universitySnapshot.data()?.country) {
                        const countrySnapshot: DocumentSnapshot = await getDoc(universitySnapshot.data()?.country);
                        course.college.university.country = countryConverter.fromFirestore(countrySnapshot, {});
                    }
                }
            }

            return course;
        }
    });

    return await Promise.all(promises);
})

export const getStudent = (async (id: string): Promise<Student | null> => {
    const ref = doc(db, "students", id);
    const snapshot = await getDoc(ref);
    let student: Student | null = null;

    if (snapshot.exists()) {
        student = studentConverter.fromFirestore(snapshot, {});
        return student;
    }

    return null;
})
