import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/page-header";
import { CourseItem } from "../../models/course_item";
import { getCourse, getCourseContent } from "../../services/api";
import { Layout } from "../../Layout";
import { Button, Table } from "react-bootstrap";
import PreviewDialog from "../../components/preview-dialog";
import { CourseItemType } from "../../enums/CourseItemType";
import { Quiz } from "../../models/quiz";
import QuizPreview from "../../components/quiz-preview";
import { useTranslation } from "react-i18next";

export default function ContentDetailsPage() {
    const { courseId, id } = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [courseItem, setCourseItem] = useState<CourseItem | null>(null);
    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        if (courseId && id) {
            getCourseContent(courseId, id).then((item) => {
                setCourseItem(item);

                if (item?.type == CourseItemType.quiz && item.payload) {
                    let q = item?.payload;
                }
            });
        }
    }, [])

    const showPreviewDialog = () => {
        setShowPreview(true);
    }

    return courseItem ? (
        <>
            <PageHeader title={t("content_title")} />
            <Table bordered striped>
                <tbody>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("title_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td className="w-100 text-start" colSpan={2}>
                            {courseItem.title}
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("type_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {courseItem.type}
                        </td>
                    </tr>
                    {courseItem.type === CourseItemType.quiz ?
                        <>
                            < tr >
                                <td className="field-label" colSpan={2}>
                                    {t("payload_column_header")}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <QuizPreview target={courseItem.payload as Quiz} />
                                </td>
                            </tr>
                        </>
                        :
                        <>
                            <tr>
                                <td className="field-label" colSpan={2}>
                                    {t("url_column_header")}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    {courseItem.url ? (
                                        <div className="d-flex d-flex-row">
                                            <div className="w-100">
                                                {courseItem.url}
                                            </div>
                                            <div className="d-inline float-end">
                                                <Button type="button" variant="secondary" style={{ height: "30px" }} className="d-flex px-2 py-2 allign-items-center">
                                                    <FontAwesomeIcon icon={faEye} onClick={showPreviewDialog} title="Preview" />
                                                </Button>
                                            </div>
                                        </div>
                                    ) : "\u00A0"}
                                </td>
                            </tr>
                            <tr>
                                <td className="field-label" colSpan={2}>
                                    {t("size_column_header")}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {courseItem.length ? courseItem.length : "\u00A0"}
                                </td>
                            </tr>
                        </>
                    }
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("free_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            {courseItem.free ? t("yes") : t("no")}
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("active_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            {courseItem.active ? t("yes") : t("no")}
                        </td>
                    </tr>
                </tbody>
            </Table >
            <div className="mt-3 float-end">
                <Button type="button" variant="secondary" className="px-4 py-2"
                    onClick={() => { navigate(`/admin/courses/${courseId}`) }}>
                    {t("back_button_label")}
                </Button>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>

            <PreviewDialog
                show={showPreview}
                url={courseItem?.url ?? ""}
                type={courseItem.type}
                onHide={() => setShowPreview(false)}
            />
        </>
    ) : (
        <div>
            Loading...
        </div>
    );
};