import { useState, useEffect } from "react";
import PageHeader from "../../components/page-header";
import { getAllCourses, getStudent, getStudentCourses, getStudentOrders } from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import { Student } from "../../models/Student";
import Order from "../../models/order";
import { DataGrid } from "../../components/data-grid";
import Course from "../../models/course";
import { useTranslation } from "react-i18next";
import CoursesDataGrid from "../../components/courses-data-grid";
import AddCourseDialog from "../../components/add-course-dialog";
import { WriteBatch, addDoc, collection, deleteDoc, doc, getDocs, query, serverTimestamp, where, writeBatch } from "firebase/firestore";
import { db } from "../../firebase";
import ActionPane from "../../components/action-pane";
import DeleteDialog from "../../components/delete-dialog";

export default function StudentDetailsPage() {
    const { id } = useParams<string>();
    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    const [student, setStudent] = useState<Student | null>();
    const [courses, setCourses] = useState<(Course | undefined)[]>([]);
    const [coursesList, setCoursesList] = useState<Course[]>([]);
    const [orders, setOrders] = useState<Order[]>([]);
    const [showAddCourseDialog, setShowAddCourseDialog] = useState<boolean>(false);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleAddCourse = async (course?: Course) => {
        if (course) {
            const studentRef = doc(db, `students/${id}`);
            const courseRef = doc(db, `courses/${course!.id}`);

            await addDoc(collection(db, 'student_courses'), {
                student: studentRef,
                course: courseRef,
                progress: 0,
                timeCreated: serverTimestamp(),
                timeodified: serverTimestamp()
            });

            window.location.reload();
            setShowAddCourseDialog(false);
        }
    }

    const handleDelete = async (row: any) => {
        const studentRef = doc(db, `students/${id}`);
        const courseRef = doc(db, `courses/${row!.id}`);

        const docRef = collection(db, "student_courses");
        const q = query(docRef, where("student", "==", studentRef), where("course", "==", courseRef));

        const querySnapshot = await getDocs(q);

        const batch = writeBatch(db);

        querySnapshot.docs.forEach((d) => {
            batch.delete(d.ref);
        });

        await batch.commit();

        window.location.reload();
        setShowDeleteDialog(false);
    }

    useEffect(() => {
        if (id) {
            getStudent(id).then((s) => {
                if (s) {
                    setStudent(s);
                    getStudentOrders(id).then((c) => {
                        setOrders(c);
                    })

                    getStudentCourses(id).then((c) => {
                        setCourses(c);

                        getAllCourses().then((res) => {
                            const filteredCourses = res.filter(n => !c.some(x => x?.id == n.id));
                            setCoursesList(filteredCourses);
                        })
                    })
                }
            })
        }
    }, [id])

    return student ? (
        <>
            <PageHeader title={t("student_title")} />
            <Table bordered striped>
                <tbody>
                    <tr>
                        <td className="w-100 field-label" colSpan={2}>
                            {t("email_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td className="w-100" colSpan={2}>
                            {student?.email}
                        </td>
                    </tr>
                    <tr>
                        <td className="w-100 field-label" colSpan={2}>
                            {t("name_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td className="w-100" colSpan={2}>
                            {student?.displayName}
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label">
                            {t("time_created_column_header")}
                        </td>
                        <td className="field-label">
                            {t("time_modified_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {
                                student.timeCreated
                                    ? student.timeCreated.toISOString()
                                    : "\u00A0"
                            }
                        </td>
                        <td>
                            {
                                student.timeModified
                                    ? student.timeModified.toISOString()
                                    : "\u00A0"
                            }
                        </td>
                    </tr>
                </tbody>
            </Table >
            <PageHeader title={t("courses_title")}>
                <div className="d-flex-inline float-end">
                    <Button variant="success" className='d-inline px-4 py-2' onClick={() => setShowAddCourseDialog(true)}>
                        {t("new_course_button_label")}
                    </Button>
                </div>
            </PageHeader>
            <DataGrid
                keyField='id'
                data={courses}
                actions={[]}
                actionsPaneWidth='120px'
                columns={[
                    {
                        dataField: 'title',
                        text: t("title_column_header"),
                        sort: true,
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '25%',
                                textAlign: "center",
                            };
                        },
                    },
                    {
                        dataField: i18n.language === "en" ? 'college.englishName' : 'college.arabicName',
                        text: t("college_column_header"),
                        sort: true,
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '25%',
                                textAlign: "center",
                            };
                        },
                    },
                    {
                        dataField: i18n.language === "en"
                            ? 'college.university.englishName'
                            : 'college.university.arabicName',
                        text: t("university_column_header"),
                        sort: true,
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '25%',
                                textAlign: "center",
                            };
                        },
                    },
                    {
                        dataField: i18n.language === "en"
                            ? 'college.university.country.englishName'
                            : 'college.university.country.arabicName',
                        text: t("country_column_header"),
                        sort: true,
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '25%',
                                textAlign: "center",
                            };
                        },
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return i18n.language === "en"
                                ? row.college?.university?.country?.englishName
                                : row.college?.university?.country?.arabicName;
                        }
                    },
                    {
                        dataField: 'dummy_field',
                        isDummyField: true,
                        text: t("actions_column_header"),
                        sort: false,
                        headerStyle: (column: any, colIndex: number) => {
                            return { width: '125px', minWidth: '125px', textAlign: 'center' };
                        },
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return (
                                <div style={{ textAlign: 'center' }}>
                                    <ActionPane
                                        showView={false}
                                        showEdit={false}
                                        onDelete={() => {
                                            setSelectedRow(row);
                                            setShowDeleteDialog(true);
                                        }}
                                    />
                                </div>
                            )
                        }
                    },
                ]}
            />
            <DeleteDialog
                visible={showDeleteDialog}
                row={selectedRow}
                onConfirm={handleDelete}
                onHide={() => setShowDeleteDialog(false)}
            />
            {/* <PageHeader title={t("orders_title")} />
            <DataGrid
                keyField='id'
                data={orders}
                actions={[]}
                actionsPaneWidth='120px'
                columns={[
                    {
                        dataField: 'serial',
                        isDummyField: true,
                        text: '#',
                        sort: false,
                        style: { textAlign: "center", width: "80px", minWidth: "80px", maxWidth: "80px", },
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '80px',
                                minWidth: "80px",
                                textAlign: "center",
                            };
                        },
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return (
                                <span>{`${rowIndex + 1}`}</span>
                            )
                        }
                    },
                    {
                        dataField: 'invoiceNo',
                        text: t("invoice_column_header"),
                        sort: false,
                        style: { textAlign: "center", width: "80px", minWidth: "80px", },
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '80px',
                                minWidth: "80px",
                                textAlign: "center",
                            };
                        },
                    },
                    {
                        dataField: 'date',
                        text: t("date_column_header"),
                        sort: false,
                        style: { textAlign: "center", width: "120px", minWidth: "120px", },
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '120px',
                                minWidth: "120px",
                                textAlign: "center",
                            };
                        },
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return (
                                <span>{cell.toISOString()}</span>
                            )
                        }
                    },
                    {
                        dataField: 'items',
                        text: t("items_column_header"),
                        sort: false,
                        headerStyle: (column: any, colIndex: number) => {
                            return { width: '100%', textAlign: "center" };
                        },
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return (
                                <Table bordered striped>
                                    <tbody>
                                        {
                                            cell.map((item: any) => {
                                                return (
                                                    <tr>
                                                        <td>{item.englishTitle}</td>
                                                        <td>{item.price.toFixed(3)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            )
                        }
                    },
                    {
                        dataField: 'total',
                        text: t("total_column_header"),
                        sort: false,
                        style: { textAlign: "center", width: "80px", minWidth: "80px", },
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '80px',
                                minWidth: "80px",
                                textAlign: "center",
                            };
                        },
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return (
                                <span>{cell.toFixed(3)}</span>
                            )
                        }
                    },
                ]}            /> */}

            <AddCourseDialog
                show={showAddCourseDialog}
                data={coursesList}
                onAdd={handleAddCourse}
                onHide={() => setShowAddCourseDialog(false)}
            />
            <div className="mt-3 float-end">
                <Button
                    type="button"
                    variant="secondary"
                    onClick={() => { navigate("/admin/students") }}
                >
                    {t("back_button_label")}
                </Button>
            </div>
        </>
    )
        : <div>Not found</div>
};