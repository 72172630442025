import { doc, collection, setDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import { useState, useRef, FormEvent, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/page-header";
import { db } from "../../firebase";
import { Country, countryConverter } from "../../models/country";
import { getCountry } from "../../services/api";
import { Layout } from "../../Layout";
import { Container, Table } from "react-bootstrap";
import CountryForm from "../../components/country-form";
import { useTranslation } from "react-i18next";
import SavingIndicator from "../../components/saving-indicator";

export default function CountryEditPage() {
    const { id } = useParams<string>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [country, setCountry] = useState<Country | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        getCountry(id).then((res) => {
            if (res) {
                setCountry(res);
            } else {
                setError("Country not found!!");
            }
        })
    }, [])

    const handleSubmit = async (data: any) => {
        setLoading(true);

        // Add timeModified
        data.timeModified = serverTimestamp();

        // Temp Solution for missing timeCreated
        if (!country?.timeCreated) {
            data["timeCreated"] = serverTimestamp();
        }

        if (country) {
            var newData = { ...country, ...data };

            // Remove unneeded fields
            delete newData['id'];
            delete newData['universities'];

            try {
                const ref = doc(collection(db, "countries"), id);
                await updateDoc(ref, newData);
                navigate("/admin/countries");
            } catch (e) {
                console.log(e);
                setError("Error!!");
                setLoading(false)
            }
        }
    }

    return (
        <>
            <PageHeader title={t("edit_country_title")} />
            <Container fluid>
                {country
                    ? (
                        <>
                            {error &&
                                <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                                    {error}
                                </div>
                            }
                            {loading
                                ? <SavingIndicator />
                                : <CountryForm
                                    target={country}
                                    onSubmit={handleSubmit}
                                    onCancel={() => navigate("/admin/countries")}
                                />
                            }
                        </>
                    )
                    : <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                        {error}
                    </div>
                }
            </Container>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </>
    )
}