import { useState, useEffect, FormEvent } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { Question } from "../models/question";
import { QuestionType } from "../enums/QuestionType";
import { Answer } from "../models/answer";
import { v4 as uuidv4 } from "uuid";
import GridAction from "./grid-action";
import { faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnswerSchema, QuestionSchema } from "../services/forms-schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useForm, SubmitHandler, SubmitErrorHandler } from "react-hook-form";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";

interface AddQuestionDialogProps {
    target?: Question,
    show: boolean,
    onHide?: (() => void) | undefined,
    onAdd?: ((question: Question) => void) | undefined,
    onUpdate?: ((question: Question) => void) | undefined,
}

export default function AddQuestionDialog(props: AddQuestionDialogProps) {
    const {
        target = null,
        show = false,
        onHide = undefined,
        onAdd = undefined,
        onUpdate = undefined
    } = props;

    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(show);
    const [typesMap, setTypesMap] = useState<{ id: any; value: any }[] | null>(null);
    const [answers, setAnswers] = useState<Answer[]>([]);
    const [questionType, setQuestionType] = useState<QuestionType | null>(null);

    type questionFormInputs = z.infer<typeof QuestionSchema>;
    type answerFormInputs = z.infer<typeof AnswerSchema>;

    const {
        register: registerForQuestionForm,
        handleSubmit: handleQuestionFormSubmit,
        reset: resetQuestionForm,
        setValue: setValueForQuestionForm,
        clearErrors: clearErrorsForQuestionForm,
        formState: { errors: questionFormErrors }
    } = useForm<questionFormInputs>({
        resolver: zodResolver(QuestionSchema)
    });

    const {
        register: registerForAnswerForm,
        handleSubmit: handleAnswerFormSubmit,
        reset: resetAnswerForm,
        // setValue: setValueForAnswerForm,
        // formState: { errors: answerFormErrors },
    } = useForm<answerFormInputs>({
        resolver: zodResolver(AnswerSchema)
    });

    const handleHide = () => {
        resetQuestionForm();
        resetAnswerForm();

        if (onHide) {
            onHide();
        }
    }

    const updateForm = (e: FormEvent<HTMLSelectElement>) => {
        setQuestionType(e.currentTarget.value as QuestionType);
        setAnswers([]);
        setValueForQuestionForm("answers", []);
    }

    const removeAnswer = (answer: Answer) => {
        const index = answers.indexOf(answer);

        if (index > -1) {
            answers.splice(index, 1);
            const arr = answers;
            setAnswers([...arr]);
            setValueForQuestionForm("answers", arr);
        }
    }

    const processQuestionForm: SubmitHandler<questionFormInputs> = data => {

        if (target) {
            target.body = data.body;
            target.type = data.type;
            target.answers = data.answers;

            if (onUpdate) {
                onUpdate(target);
            }
        } else {
            data.id = uuidv4();

            if (onAdd) {
                onAdd(data);
            }
        }

        resetQuestionForm();
    }

    const handleQuestionFormErrors: SubmitErrorHandler<questionFormInputs> = errors => {
        console.log(errors);
    }

    const processAnswerForm: SubmitHandler<answerFormInputs> = data => {

        // If its not "multiplechoice" then only one answer is correct
        if (questionType !== QuestionType.multiplechoicemulti && data.correct) {
            answers.forEach((e) => {
                if (e.correct) {
                    e.correct = false;
                }
            });
        }

        data.id = uuidv4();

        const newArr = [...answers, data];

        setAnswers(newArr);
        setValueForQuestionForm("answers", newArr);

        resetAnswerForm();

        clearErrorsForQuestionForm();
    }

    const handleAnswerFormErrors: SubmitErrorHandler<answerFormInputs> = errors => {
        alert("Please fill all required fields");
    }

    useEffect(() => {
        if (show) {
            setQuestionType(null);
            resetAnswerForm();
            resetQuestionForm();

            const m: { id: any; value: any }[] = [];

            let keys = Object.keys(QuestionType);
            let values = Object.values(QuestionType);

            for (var i = 0; i < keys.length; i++) {
                m?.push({ id: keys[i], value: values[i] });
            }

            m.sort((a, b) => (a.value < b.value ? -1 : 1));

            setTypesMap(m);

            if (target) {
                setAnswers([...target.answers]);
                setValueForQuestionForm("answers", target.answers);
                setQuestionType(target.type);
            } else {
                setAnswers([]);
                setValueForQuestionForm("answers", []);
            }
        }

        setShowModal(show);
    }, [show, target]);

    return (
        <>
            <form
                key="questionForm"
                id="questionForm"
                method="post"
                action="#xyz"
                className="w-100"
                onSubmit={handleQuestionFormSubmit(processQuestionForm, handleQuestionFormErrors)}
            >
                <input
                    type="hidden"
                    form="questionForm"
                    {...registerForQuestionForm("answers", { value: target?.answers })}
                />
            </form>
            <form
                key="answerForm"
                id="answerForm"
                method="post"
                className="w-100"
                onSubmit={handleAnswerFormSubmit(processAnswerForm, handleAnswerFormErrors)}
            >
            </form>

            <Modal size="lg" show={showModal} onHide={handleHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("add_question_title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table bordered striped>
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    {t("body_column_header")}
                                </td>
                            </tr>
                            <tr>
                                <td className="w-100" colSpan={2}>
                                    <textarea
                                        form="questionForm"
                                        className="w-100"
                                        defaultValue={target?.body ?? ""}
                                        placeholder="Enter body"
                                        {...registerForQuestionForm("body", { value: target?.body, required: true })}
                                    />
                                    {questionFormErrors.body?.message && (
                                        <p className="small text-danger">{questionFormErrors.body.message}</p>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    {t("type_column_header")}
                                </td>
                            </tr>
                            <tr>
                                <td className="w-50" colSpan={2}>
                                    <select
                                        form="questionForm"
                                        className="w-100"
                                        {...registerForQuestionForm("type", { value: target?.type, required: true, onChange: updateForm })}
                                    >
                                        <option key={0} value="">Select Type</option>
                                        {typesMap && typesMap.map((e) => {
                                            return (
                                                <option
                                                    key={e.id}
                                                    value={e.value}
                                                    selected={e.value === target?.type}
                                                >
                                                    {capitalize(e.value)}
                                                </option>
                                            )
                                        })}
                                    </select>
                                    {questionFormErrors.type?.message && (
                                        <p className="small text-danger">{questionFormErrors.type.message}</p>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <Table bordered striped>
                                        <tbody>
                                            {answers.map((e, idx) => (
                                                <>
                                                    <tr id={e.body}>
                                                        <td style={{ width: "100px" }}>
                                                            {questionType === QuestionType.multiplechoicemulti
                                                                ? <input type="checkbox" disabled checked={e.correct} />
                                                                : <input type="radio" disabled checked={e.correct} />}
                                                        </td>
                                                        <td className="w-50">
                                                            {e.body}
                                                            {e.correct && <FontAwesomeIcon icon={faCheck} className="text-success ms-2" />}
                                                        </td>
                                                        <td style={{ width: "80px" }}>
                                                            <GridAction icon={faTrash} className="text-danger" onClick={() => removeAnswer(e)} />
                                                        </td>
                                                        <td style={{ width: "100px" }}>
                                                            {questionType === QuestionType.multiplechoicemulti ?
                                                                <input type="checkbox" disabled checked={e.correct} />
                                                                :
                                                                <input type="radio" disabled checked={e.correct} />}
                                                        </td>
                                                    </tr>
                                                </>
                                            ))}
                                            {questionFormErrors.answers?.message && (
                                                <tr>
                                                    <td colSpan={5} className="text-center">
                                                        <span className="small text-danger">{questionFormErrors.answers.message}</span>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                    {questionType &&
                                        <Table bordered>
                                            <thead>
                                                <th className="text-center">Answer</th>
                                                <th className="text-center">Correct?</th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            form="answerForm"
                                                            className="w-100"
                                                            placeholder="Answer (English)"
                                                            {...registerForAnswerForm("body", { value: "", required: true })}
                                                        />
                                                    </td>
                                                    <td style={{ width: "100px" }} className="text-center">
                                                        <input
                                                            type="checkbox"
                                                            form="answerForm"
                                                            {...registerForAnswerForm("correct", { value: false, required: false })}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2} className="text-end">
                                                        <Button type="submit" variant="success" form="answerForm">Add</Button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleHide}>
                        {t("cancel_button_label")}
                    </Button>
                    <Button type="submit" form="questionForm" variant="primary">
                        {t("save_button_label")}
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    )
}