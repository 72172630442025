import { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../components/loading-indicator";

const CountriesDataGrid = lazy(() =>
    import("../../components/countries-data-grid")
);

export default function CountriesPage() {
    const { t } = useTranslation();

    return (
        <>
            <h2 className="mb-4">{t("countries")}</h2>
            <Suspense fallback={<LoadingIndicator />}>
                <CountriesDataGrid />
            </Suspense>
        </>
    );
}