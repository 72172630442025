import { collection, doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import PageHeader from "../../components/page-header";
import { db } from "../../firebase";
import { College } from "../../models/college";
import { getCollege } from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import CollegeForm from "../../components/college-form";
import { Layout } from "../../Layout";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import SavingIndicator from "../../components/saving-indicator";

export default function CollegeEditPage() {
    const { id } = useParams<string>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [college, setCollege] = useState<College | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        getCollege(id).then((res) => {
            if (res) {
                setCollege(res);
            } else {
                setError("College not found!!");
            }
        })
    }, [])

    const handleSubmit = async (data: any) => {
        setLoading(true);

        // Add timeModified
        data.timeModified = serverTimestamp();

        // Temp Solution for missing timeCreated
        if (!college?.timeCreated) {
            data["timeCreated"] = serverTimestamp();
        }

        if (college) {
            var newData = { ...college, ...data };

            // Remove unneeded fields
            delete newData['id'];
            delete newData['courses'];

            try {
                const collegesCollection = collection(db, 'colleges');
                await updateDoc(doc(collegesCollection, id), newData);

                navigate("/admin/colleges");
            } catch (e) {
                console.log(e);
                setError("Error!!");
                setLoading(false);
            }
        }
    }

    return college ? (
        <>
            <PageHeader title={t("edit_college_title")} />
            <Container fluid>
                {college
                    ? (
                        <>
                            {error &&
                                <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                                    {error}
                                </div>
                            }
                            {loading
                                ? <SavingIndicator />
                                : <CollegeForm
                                    targetCollege={college}
                                    onSubmit={handleSubmit}
                                    onCancel={() => navigate("/admin/colleges")}
                                />
                            }
                        </>
                    )
                    : <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                        {error}
                    </div>
                }
            </Container>
            <p>&nbsp;</p >
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </>
    ) : (
        <div>Loading...</div>
    )
};