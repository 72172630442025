import { ButtonGroup, Table, ToggleButton } from "react-bootstrap"
import { Quiz } from "../models/quiz";
import { useState } from "react";
import { Question } from "../models/question";
import { QuestionType } from "../enums/QuestionType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";


interface QuizPreviewProps {
    target: Quiz | null,
    onSubmit?(data: any): void,
    onCancel?(): void,
}

export default function QuizPreview(props: QuizPreviewProps) {
    const { target = null } = props;
    const { t, i18n } = useTranslation();

    const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(null);
    const [language, setLanguage] = useState<string>(i18n.language);

    return (
        <div className="section d-flex justify-content-center embed-responsive embed-responsive-16by9">
            <div className="w-100">
                <Table bordered striped className="w-100">
                    <tbody>
                        <tr>
                            <td colSpan={2} dir={i18n.language === "en" ? "ltr" : "rtl"}>
                                <span>{t("description_column_header")}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="w-100" colSpan={2}>
                                <span>{target?.description}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="w-50">
                                <span>{t("questions_column_header")}</span>
                            </td>
                            <td className="w-50">
                                <span>{t("preview_column_header")}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ol>
                                    {target?.questions.map((e) => {
                                        return (
                                            <li key={e.id} className="border border-primary rounded mb-1 px-1 py-1" onClick={() => { setSelectedQuestion(e) }}>
                                                <span>{e.body}</span>
                                            </li>
                                        )
                                    })}
                                </ol>
                            </td>
                            <td>
                                {selectedQuestion &&
                                    <div dir={language === "en" ? "ltr" : "rtl"}>
                                        <div>
                                            <span>{selectedQuestion.body}</span>
                                        </div>
                                        {selectedQuestion.answers.map((e) => {
                                            return (
                                                <div key={e.body}>
                                                    {selectedQuestion.type === QuestionType.multiplechoicemulti ?
                                                        <input type="checkbox" disabled className={language === "en" ? "me-2" : "ms-2"} />
                                                        :
                                                        <input type="radio" disabled className={language === "en" ? "me-2" : "ms-2"} />
                                                    }
                                                    <span>
                                                        {e.body}
                                                        {e.correct &&
                                                            <FontAwesomeIcon icon={faCheck} className={"text-success " + (language === "en" ? "ms-2" : "me-2")} />
                                                        }
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )
}