import React, { useTransition } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router";
import { auth } from "../firebase";
import logo from "../logo.png";
import { useAuth } from "../contexts/AuthContext";
import NotificationsIcon from "./notifications_icon";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { useLayoutDirection } from "../LayoutDirectionProvider";

export const NavigationBar = () => {
    const { currentUser, userRole, signOut } = useAuth();
    const { isRTL, changeLanguage } = useLayoutDirection();

    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    const logout = async () => {
        await signOut();
        navigate("/admin/login");
    }

    const gotoTickets = () => {
        navigate("/admin/tickets");
    }

    // const changeLanguage = async (lng: string) => {
    //     // await i18n.changeLanguage(lng);
    //     // const cookies = new Cookies();
    //     // cookies.set("language", lng, { path: ' / ' });

    //     changeLanguage(lng);
    // };

    return (
        <Navbar expand="lg" className="navbar navbar-expand-lg navbar-light bg-white fixed-top">
            <Container fluid>
                <Navbar.Toggle aria-controls="basic-navbar-nav" data-bs-toggle="collapse" data-bs-target="#sidebarMenu">
                    <span className="navbar-toggler-icon"></span>
                </Navbar.Toggle>
                <Navbar.Brand href="/admin/dashboard">
                    <span className="strong">{t("coursat")}</span>
                </Navbar.Brand>
                {/* <Navbar.Collapse id="basic-navbar-nav"> */}
                <Nav className="d-flex flex-row">
                    <span className="navbar-text">
                        {t("welcome_msg", { name: currentUser?.displayName, role: userRole })}&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    {userRole === "admin" && <NotificationsIcon onClick={gotoTickets} />}

                    {i18n.language == "en" &&
                        <Nav.Link href="#" onClick={() => changeLanguage("ar")} className="float-end lang-selector">
                            Ar
                        </Nav.Link>
                    }
                    {i18n.language == "ar" &&
                        <Nav.Link href="#" onClick={() => changeLanguage("en")} className="float-end lang-selector">
                            En
                        </Nav.Link>
                    }
                    <Nav.Link href="#" onClick={logout} className="float-end">
                        {t('logout')}
                    </Nav.Link>
                </Nav>
                {/* </Navbar.Collapse> */}
            </Container>
        </Navbar>
    );
}