import { collection, doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CourseForm from "../../components/course-form";
import PageHeader from "../../components/page-header";
import { StudyMaterialType } from "../../enums/StudeyMaterialType";
import { db } from "../../firebase";
import { getReview } from "../../services/api";
import Review from "../../models/reviewe";
import { useTranslation } from "react-i18next";
import SavingIndicator from "../../components/saving-indicator";
import { Container } from "react-bootstrap";

export default function ReviewEditPage() {
    const { id } = useParams<string>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [review, setReview] = useState<Review | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (data: any) => {
        setLoading(true);

        data.type = StudyMaterialType.review;

        // Add timeModified
        data.timeModified = serverTimestamp();

        // Temp Solution for missing timeCreated
        if (!review?.timeCreated) {
            data["timeCreated"] = serverTimestamp();
        }

        if (review) {
            var newData = { ...review, ...data };

            if (isNaN(newData["rating"])) {
                newData["rating"] = 0;
            }

            if (isNaN(newData["discount"])) {
                newData["discount"] = null;
            }

            if (newData["collection"] === undefined) {
                newData["collection"] = null;
            }

            // Remove unneeded fields
            delete newData['id'];

            try {
                const ref = collection(db, 'courses');
                await updateDoc(doc(ref, id), newData);

                navigate("/admin/reviews");
            } catch (e) {
                console.log(e);
                setError("Error!!");
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        if (id) {
            getReview(id).then((review: Review | null) => {
                if (review) {
                    review.type = StudyMaterialType.review;
                    setReview(review);
                } else {
                    setError("Review not found!");
                }
            });
        }
    }, [id]);

    return (
        <>
            <PageHeader title={t("edit_review_title")} />
            <Container fluid>
                {review
                    ? (
                        <>
                            {error &&
                                <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                                    {error}
                                </div>
                            }
                            {loading
                                ? <SavingIndicator />
                                : <CourseForm
                                    target={review}
                                    onSubmit={handleSubmit}
                                    onCancel={() => navigate("/admin/reviews")}
                                />
                            }
                        </>
                    )
                    : <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                        {error}
                    </div>
                }
            </Container>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </>
    );
};
