import { zodResolver } from "@hookform/resolvers/zod";
import { storage } from "../firebase";
import { uploadBytes, getDownloadURL, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { z } from "zod";
import { Tutor } from "../models/tutor";
import { TutorEditSchema, TutorSchema } from "../services/forms-schemas";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface TutorFormProps {
    target: Tutor | null,
    onSubmit?(data: any): void,
    onCancel?(): void,
}

export default function TutorForm(props: TutorFormProps) {

    const { target = new Tutor() } = props;
    const { t, i18n } = useTranslation();

    const [file, setFile] = useState<File>();
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const mode: string = target && target.id ? "edit" : "new";

    type Inputs = z.infer<typeof TutorSchema>;

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors } } = useForm<Inputs>({
            resolver: zodResolver(mode == "edit" ? TutorEditSchema : TutorSchema)
        });

    useEffect(() => {
        if (target) {
            setEmail(target.email);
            setPassword(target.password);
            setValue("email", target.email);
            setValue("password", target.password);
        }
    }, [target])

    const processForm: SubmitHandler<Inputs> = async data => {

        if (!target && !file) return

        let photoUrl = target?.photoUrl;

        if (file) {
            const fileId = uuidv4()
            photoUrl = `tutors/${fileId}-${file.name}`
            const storageRef = ref(storage, photoUrl)
            const { metadata } = await uploadBytes(storageRef, file);
            // photoUrl = await getDownloadURL(storageRef);
            const { fullPath } = metadata
        }

        const objectData = {
            arabicName: data.arabicName,
            englishName: data.englishName,
            email: data.email,
            password: data.password,
            active: data.active,
            photoUrl: photoUrl,
        }

        if (props.onSubmit) {
            props?.onSubmit(objectData);
        }

        reset();
    }

    return (
        <form className="w-100" method="post" onSubmit={handleSubmit(processForm)}>
            <Table bordered striped>
                <tbody>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("name_column_header")}
                        </td>
                    </tr>
                    <tr>
                        {i18n.language === "en"
                            ? <>
                                <td>
                                    <input
                                        className="w-100 text-start"
                                        placeholder="Enter name (English)"
                                        defaultValue={target?.englishName}
                                        {...register("englishName", { value: target?.englishName, required: true })}
                                    />
                                    {errors.englishName?.message && (
                                        <p className="small text-danger">{errors.englishName.message}</p>
                                    )}
                                </td>
                                <td>
                                    <input
                                        className="w-100 text-end"
                                        dir="rtl"
                                        placeholder="Enter name (Arabic)"
                                        defaultValue={target?.arabicName}
                                        {...register("arabicName", { value: target?.arabicName, required: true })}
                                    />
                                    {errors.arabicName?.message && (
                                        <p className="small text-danger">{errors.arabicName.message}</p>
                                    )}
                                </td>
                            </>
                            : <>
                                <td>
                                    <input
                                        className="w-100 text-start"
                                        dir="rtl"
                                        placeholder="Enter name (Arabic)"
                                        defaultValue={target?.arabicName}
                                        {...register("arabicName", { value: target?.arabicName, required: true })}
                                    />
                                    {errors.arabicName?.message && (
                                        <p className="small text-danger">{errors.arabicName.message}</p>
                                    )}
                                </td>
                                <td className="text-end">
                                    <input
                                        className="w-100 text-end"
                                        placeholder="Enter name (English)"
                                        defaultValue={target?.englishName}
                                        {...register("englishName", { value: target?.englishName, required: true })}
                                    />
                                    {errors.englishName?.message && (
                                        <p className="small text-danger">{errors.englishName.message}</p>
                                    )}
                                </td>
                            </>
                        }
                    </tr>
                    {mode == "new" &&
                        <>
                            <tr>
                                <td className="field-label w-50">
                                    {t("email_column_header")}
                                </td>
                                <td className="field-label w-50">
                                    {t("password_column_header")}
                                </td>
                            </tr>
                            <tr>
                                <td className={i18n.language === "ar" ? "text-end" : "text-start"}>
                                    <input
                                        className={`w-100 ${i18n.language === "en" ? "text-start" : "text-end"}`}
                                        placeholder="Enter email"
                                        defaultValue={email}
                                        {...register("email", { value: email ?? null, required: true })}
                                    />
                                    {errors.email?.message && (
                                        <p className="small text-danger">{errors.email.message}</p>
                                    )}
                                </td>
                                <td className={i18n.language === "ar" ? "text-end" : "text-start"}>
                                    <input
                                        className={`w-100 ${i18n.language === "en" ? "text-start" : "text-end"}`}
                                        type="password"
                                        placeholder="Enter password"
                                        defaultValue={password}
                                        {...register("password", { value: password, required: true })}
                                    />
                                    {errors.password?.message && (
                                        <p className="small text-danger">{errors.password.message}</p>
                                    )}
                                </td>
                            </tr>
                        </>
                    }
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("photo_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <input type="file"
                                accept=".png,.jpg,.jpeg"
                                className="w-100"
                                id="photo"
                                name="photo"
                                placeholder="Select photo"
                                onChange={(e) => {
                                    if (e.target.files?.[0]) {
                                        setFile(e.target.files?.[0]);
                                        setValue("photoUrl", e.target.files[0]?.name);
                                    }
                                }}
                            />
                            <input
                                type="hidden"
                                {...register("photoUrl", { value: target?.photoUrl, required: true })}
                            />
                            {errors.photoUrl?.message && (
                                <p className="small text-danger">{errors.photoUrl.message}</p>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("active_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <input type="checkbox"
                                {...register("active")}
                                defaultChecked={target?.active}
                            />
                            {errors.active?.message && (
                                <p className="small text-danger">{errors.active?.message}</p>
                            )}
                        </td>
                    </tr>
                </tbody>
            </Table>
            <div className="mt-3 float-end">
                <Button type="submit" variant="primary" className="px-4 py-2 me-3">{t("save_button_label")}</Button>
                <Button type="button" variant="secondary" className="px-4 py-2"
                    onClick={props.onCancel}>
                    {t("cancel_button_label")}
                </Button>
            </div>
        </form >
    );
}