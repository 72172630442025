import { DocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import Course from "./course";

export class Tutor {
    id?: string;
    arabicName: string = "";
    englishName: string = "";
    email: string = "";
    password: string = "";
    photoUrl: string = "";
    active: boolean = true;
    deleted: boolean = false;
    timeCreated?: Date;
    timeModified?: Date;

    courses?: Course[];
}

export const tutorConverter = {
    toFirestore: (tutor: Tutor) => {
        return {
            arabicName: tutor?.arabicName,
            englishName: tutor?.englishName,
            email: tutor?.email,
            // password: tutor?.password,
            photoUrl: tutor?.photoUrl,
            active: tutor?.active,
            deleted: tutor?.deleted,
        };
    },
    fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
        const data = snapshot.data(options);

        let tutor = new Tutor();
        tutor.id = snapshot.id as string;
        tutor.arabicName = data?.arabicName;
        tutor.englishName = data?.englishName;
        tutor.email = data?.email;
        // tutor.password = data?.password;
        tutor.photoUrl = data?.photoUrl;
        tutor.active = data?.active;
        tutor.deleted = data?.deleted;

        if (data?.timeCreated) {
            tutor.timeCreated = data?.timeCreated.toDate();
        }

        if (data?.timeModified) {
            tutor.timeModified = data?.timeModified.toDate();
        }

        return tutor;
    }
}