import { DocumentSnapshot, SnapshotOptions } from "firebase/firestore";

export default class Price {
    id?: string | null;
    usd: number = 0.0;
    jod: number = 0.0;
    deleted: boolean = false;
    timeCreated?: Date;
    timeModified?: Date;
}

export const priceConverter = {
    toFirestore: (price: Price) => {
        return {
            usd: price?.usd,
            jod: price?.jod,
            deleted: price?.deleted,
        };
    },
    fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
        const data = snapshot.data(options);

        let price = new Price();
        price.id = snapshot.id;
        price.usd = data?.usd;
        price.jod = data?.jod;
        price.deleted = data?.deleted;

        if (data?.timeCreated) {
            price.timeCreated = data?.timeCreated.toDate();
        }

        if (data?.timeModified) {
            price.timeModified = data?.timeModified.toDate();
        }

        return price;
    }
};