import { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../components/loading-indicator";

const UniversitiesDataGrid = lazy(() =>
    import("../../components/universities-data-grid")
);

export default function UniversitiesPage() {
    const { t } = useTranslation();

    return (
        <>
            <h2 className="mb-4">{t("universities")}</h2>
            <Suspense fallback={<LoadingIndicator />}>
                <UniversitiesDataGrid />
            </Suspense>
        </>
    );
}