import { zodResolver } from "@hookform/resolvers/zod";
import { db } from "../firebase";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";
import { PriceSchema } from "../services/forms-schemas";
import { Button, Table } from "react-bootstrap";
import Price from "../models/price";
import { useTranslation } from "react-i18next";
import { collection, getDocs, query, where } from "firebase/firestore";

interface PriceFormProps {
    target: Price | null,
    onError?(error: string): void,
    onSubmit?(data: any): void,
    onCancel?(): void,
}

export default function PriceForm(props: PriceFormProps) {

    const { target = new Price() } = props;

    const { t } = useTranslation();

    type Inputs = z.infer<typeof PriceSchema>;

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors } } = useForm<Inputs>({
            resolver: zodResolver(PriceSchema)
        });

    const processForm: SubmitHandler<Inputs> = async data => {

        const objectData = {
            usd: data.usd,
            jod: data.jod,
        }

        if (!target && await isPriceExists(data)) {
            if (props.onError) {
                props?.onError("Duplicate Entry");
            }
            return;
        } else if (props.onSubmit) {
            props?.onSubmit(objectData);
        }

        reset();
    }

    const isPriceExists = async (data: Inputs) => {
        const pricesRef = collection(db, "prices");

        const rows = await getDocs(query(pricesRef,
            where("usd", "==", data.usd)));

        return rows.docs.length > 0;
    }

    return (
        <form className="w-100" method="post" onSubmit={handleSubmit(processForm)}>
            <Table bordered striped>
                <tbody>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("usd")}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input
                                className="w-100"
                                placeholder="Enter price (USD)"
                                defaultValue={target?.usd}
                                {...register("usd", { value: target?.usd, required: true, valueAsNumber: true })}
                            />
                            {errors.usd?.message && (
                                <p className="small text-danger">{errors.usd.message}</p>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("jod")}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <input
                                className="w-100"
                                placeholder="Enter price (JOD)"
                                defaultValue={target?.jod}
                                {...register("jod", { value: target?.jod, required: true, valueAsNumber: true })}
                            />
                            {errors.jod?.message && (
                                <p className="small text-danger">{errors.jod.message}</p>
                            )}
                        </td>
                    </tr>
                </tbody>
            </Table>
            <div className="mt-3 float-end">
                <Button type="submit" variant="primary" className="px-4 py-2 me-3">{t("save_button_label")}</Button>
                <Button type="button" variant="secondary" className="px-4 py-2"
                    onClick={props.onCancel}>
                    {t("cancel_button_label")}
                </Button>
            </div>
        </form >
    );
}