import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { College } from "../models/college";
import { Country } from "../models/country";
import { University } from "../models/university";
import SelectCollegeDialog from "./select-college-dialog";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface CollegeEditorProps {
    data?: College | null | undefined,
    onSelect?: ((college: College) => void) | undefined,
}

export default function CollegePicker(props: CollegeEditorProps): JSX.Element {
    const { data = null, onSelect = null } = props;
    const { t, i18n } = useTranslation();

    const [showCollegeDialog, setShowCollegeDialog] = useState(false);

    const [college, setCollege] = useState<College | null>(data);
    const [university, setUniversity] = useState<University | null | undefined>(data?.university);
    const [country, setCountry] = useState<Country | null | undefined>(data?.university?.country);

    const handleHide = () => setShowCollegeDialog(false);

    const handleSelect = (country: Country, university: University, college: College,) => {

        setCountry(country);
        setUniversity(university);
        setCollege(college);

        if (props?.onSelect) {
            props?.onSelect(college);
        }

        setShowCollegeDialog(false);
    }

    return (
        <>
            <tr>
                <td className="field-label" colSpan={2}>
                    <p className="d-inline me-3">{t("college_column_header")}</p>
                    <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        title={t("select_college_title")}
                        onClick={() => setShowCollegeDialog(true)} />

                    <SelectCollegeDialog
                        show={showCollegeDialog}
                        onHide={() => setShowCollegeDialog(false)}
                        onSelect={handleSelect}
                    />
                </td>
            </tr>
            {college ?
                <>
                    <tr>
                        {i18n.language === "en"
                            ? <>
                                <td className="no-bottom-border">
                                    {college.englishName}
                                </td>
                                <td className="text-end no-bottom-border" dir="rtl">
                                    {college.arabicName}
                                </td>
                            </>
                            : <>
                                <td className="no-bottom-border">
                                    {college.arabicName}
                                </td>
                                <td className="text-end no-bottom-border" dir="ltr">
                                    {college.englishName}
                                </td>
                            </>
                        }
                    </tr>
                    <tr>
                        {i18n.language === "en"
                            ? <>
                                <td className="no-top-border no-bottom-border">
                                    {university ? university?.englishName : college.university?.englishName}
                                </td>
                                <td className="text-end no-top-border no-bottom-border" dir="rtl">
                                    {university ? university?.arabicName : college.university?.arabicName}
                                </td>
                            </>
                            : <>
                                <td className="no-top-border no-bottom-border">
                                    {university ? university?.arabicName : college.university?.arabicName}
                                </td>
                                <td className="text-end no-top-border no-bottom-border" dir="ltr">
                                    {university ? university?.englishName : college.university?.englishName}
                                </td>
                            </>
                        }
                    </tr>
                    <tr>
                        {i18n.language === "en"
                            ? <>
                                <td className="no-top-border">
                                    {country ? country?.englishName : college.university?.country?.englishName}
                                </td>
                                <td className="text-end no-top-border" dir="rtl">
                                    {country ? country?.arabicName : college.university?.country?.arabicName}
                                </td>
                            </>
                            : <>
                                <td className="no-top-border">
                                    {country ? country?.arabicName : college.university?.country?.arabicName}
                                </td>
                                <td className="text-end no-top-border" dir="ltr">
                                    {country ? country?.englishName : college.university?.country?.englishName}
                                </td>
                            </>
                        }
                    </tr>
                </>
                :
                <tr>
                    <td colSpan={2}>
                        &nbsp;
                    </td>
                </tr>
            }
        </>
    )
}