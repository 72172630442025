import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import CourseForm from "../../components/course-form";
import PageHeader from "../../components/page-header";
import { db } from "../../firebase";
import { Layout } from "../../Layout";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import SavingIndicator from "../../components/saving-indicator";

export default function CourseCreatePage() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async (data: any) => {
        setLoading(true);

        // Add dates
        data.timeCreated = serverTimestamp();
        data.timeModified = serverTimestamp();

        data.deleted = false;
        data.titleLower = data.title.toLowerCase();

        try {
            const coursesCollection = collection(db, 'courses');
            const newCourse = await addDoc(coursesCollection, data);

            navigate("/admin/courses");
        } catch (e) {
            console.log(e);
            setError("Error!!");
            setLoading(false);
        }
    }

    return (
        <>
            <PageHeader title={t("add_course_title")} />
            <Container fluid>
                {error &&
                    <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                        {error}
                    </div>
                }
                {loading
                    ? <SavingIndicator />
                    : <CourseForm
                        target={null}
                        onSubmit={handleSubmit}
                        onCancel={() => navigate("/admin/courses")}
                    />}
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </Container>
        </>
    )
};