import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActionPane from "../../components/action-pane";
import { DataGrid } from "../../components/data-grid";
import PageHeader from "../../components/page-header";
import Course from "../../models/course";
import { getCourse, getCourseContents } from "../../services/api";
import { Button, Table } from "react-bootstrap";
import PreviewDialog from "../../components/preview-dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { collection, deleteDoc, doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import DeleteDialog from "../../components/delete-dialog";
import { CourseItem } from "../../models/course_item";
import { useAuth } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";

export default function CourseDetailsPage() {
    const { id } = useParams<string>();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const { userRole } = useAuth();

    const [course, setCourse] = useState<Course | null>(null);
    const [contents, setContents] = useState<CourseItem[]>([]);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [showPreview, setShowPreview] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    useEffect(() => {
        if (id) {
            getCourse(id).then((res) => {
                getCourseContents(id).then((rows) => setContents(rows))
                setCourse(res);
            });
        }
    }, [])

    const showPreviewDialog = () => {
        setShowPreview(true);
    }

    function handleItemView(id: number): void {
        navigate(`content/${id}`);
    }

    function handleItemEdit(id: number): void {
        navigate(`content/edit/${id}`);
    }

    async function handleItemDelete(row: any): Promise<void> {
        if (id) {
            try {
                await deleteDoc(doc(db, `/courses/${id}/contents`, row.id))

                if (contents) {
                    const index = contents.indexOf(row);
                    if (index > -1) { // only splice array when item is found
                        contents.splice(index, 1); // 2nd parameter means remove one item only
                    }
                }

                setShowDeleteDialog(false);
            } catch (e) {
                console.log(e);
            }

            // navigate(`/admin/courses/${id}`);
        }
    }

    return course ? (
        <>
            <PageHeader title={t("course_title")} />
            <Table bordered striped>
                <tbody>
                    <tr>
                        <td className="w-100 field-label" colSpan={2}>
                            {t("title_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td className="w-100 text-start" colSpan={2}>
                            {course.title}
                        </td>
                    </tr>
                    {course.subtitle &&
                        <tr>
                            <td className="no-top-border" colSpan={2}>
                                {course.subtitle}
                            </td>
                        </tr>
                    }
                    <tr>
                        <td className="w-100 field-label" colSpan={2}>
                            {t("banner_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            {course.banner ? (
                                <div className="d-flex d-flex-row">
                                    <div className="w-80 truncate">
                                        {course.banner}
                                    </div>
                                    <div className="d-inline float-end">
                                        <Button type="button" variant="secondary" style={{ height: "30px" }} className="d-flex px-2 py-2 allign-items-center">
                                            <FontAwesomeIcon icon={faEye} onClick={showPreviewDialog} title="Preview" />
                                        </Button>
                                    </div>
                                </div>
                            ) : "\u00A0"}
                        </td>
                    </tr>
                    <tr>
                        <td className="w-50 field-label">
                            {t("price_column_header")}
                        </td>
                        <td className="w-50 field-label">
                            App Store Code
                        </td>
                    </tr>
                    <tr>
                        <td className="no-bottom-border">
                            ${course.price.toFixed(3)}
                        </td>
                        <td className="no-bottom-border">
                            Apple: {course.iosCode ?? ""}<br />
                            Google: {course.androidCode ?? ""}
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("college_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-start no-bottom-border" colSpan={2}>
                            {i18n.language === "en"
                                ? <>
                                    {course.college?.englishName}
                                    <br />
                                    {course.college?.university?.englishName}
                                    <br />
                                    {course.college?.university?.country?.englishName}
                                </>
                                : <>
                                    {course.college?.arabicName}
                                    <br />
                                    {course.college?.university?.arabicName}
                                    <br />
                                    {course.college?.university?.country?.arabicName}
                                </>
                            }
                        </td>
                    </tr>
                    {userRole !== "tutor" &&
                        <>
                            <tr>
                                <td className="field-label" colSpan={2}>
                                    {t("tutors_column_header")}
                                </td>
                            </tr>
                            <tr>
                                <td className="text-start" colSpan={2}>
                                    {
                                        course.tutors
                                            ? course.tutors.map((t) => <p>{i18n.language === "en" ? t.englishName : t.arabicName}</p>)
                                            : "\u00A0"
                                    }
                                </td>
                            </tr>
                        </>
                    }
                    <tr>
                        <td className="field-label">
                            {t("time_created_column_header")}
                        </td>
                        <td className="field-label">
                            {t("time_modified_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {
                                course.timeCreated
                                    ? course.timeCreated.toISOString()
                                    : "\u00A0"
                            }
                        </td>
                        <td>
                            {
                                course.timeModified
                                    ? course.timeModified.toISOString()
                                    : "\u00A0"
                            }
                        </td>
                    </tr>
                </tbody>
            </Table>
            <p>&nbsp;</p>
            <PageHeader title={t("contents_title")} />
            <div className="mb-2">
                <div className="d-inline">
                    <Button variant="success" className='inline px-4 py-2' onClick={() => navigate("content/create")}>
                        {t("new_content_button_label")}
                    </Button>
                </div>
            </div>
            <DataGrid
                keyField='title'
                data={contents}
                actions={[]}
                actionsPaneWidth='120px'
                columns={[
                    {
                        dataField: 'place',
                        text: t("place_column_header"),
                        sort: true,
                        style: { textAlign: "center" },
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '80px',
                                minWidth: '80px',
                                textAlign: "center",
                            };
                        },
                    },
                    {
                        dataField: 'title',
                        text: t("title_column_header"),
                        sort: true,
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '70%',
                                textAlign: "center",
                            };
                        },
                    },
                    {
                        dataField: 'type',
                        isDummyField: true,
                        text: t("type_column_header"),
                        sort: true,
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '15%',
                                textAlign: "center",
                            };
                        },
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return t(row.type)
                        },
                    },
                    {
                        dataField: 'free',
                        isDummyField: true,
                        text: t("free_column_header"),
                        sort: true,
                        headerStyle: (column: any, colIndex: number) => {
                            return {
                                width: '15%',
                                textAlign: "center",
                            };
                        },
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return row.free ? t('yes') : t('no');
                        },
                    },

                    {
                        dataField: 'dummy_field',
                        isDummyField: true,
                        text: t("actions_column_header"),
                        sort: false,
                        headerStyle: (column: any, colIndex: number) => {
                            return { width: '120px', minWidth: '100px', textAlign: 'center' };
                        },
                        formatter: (cell: any, row: any, rowIndex: number, extraData: any) => {
                            return (
                                <div style={{ textAlign: 'center' }}>
                                    <ActionPane
                                        onView={() => handleItemView(row.id)}
                                        onEdit={() => handleItemEdit(row.id)}
                                        onDelete={() => {
                                            setSelectedIndex(row);
                                            setShowDeleteDialog(true);
                                        }}
                                    />
                                </div>
                            )
                        }
                    },
                ]}
            />
            <p>&nbsp;</p>
            <div className="mt-3 float-end">
                <Button type="button" variant="secondary" className="px-4 py-2"
                    onClick={() => { navigate("/admin/courses") }}>
                    {t("back_button_label")}
                </Button>
            </div>

            <PreviewDialog
                show={showPreview}
                url={course?.banner}
                onHide={() => setShowPreview(false)}
            />

            <DeleteDialog
                visible={showDeleteDialog}
                row={selectedIndex}
                onConfirm={handleItemDelete}
                onHide={() => setShowDeleteDialog(false)}
            />
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </>
    ) : (
        <div>
            Loading...
        </div>
    );
};