import { useState, useRef, useEffect } from "react";
import { Tutor } from "../models/tutor";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface AddTutorDialogProps {
    data: Tutor[],
    show: boolean,
    onHide?: (() => void) | undefined,
    onAdd?: ((tutor?: Tutor[]) => void) | undefined,
}

export default function AddTutorDialog(props: AddTutorDialogProps) {
    let { data = [], show = false, onHide = undefined, onAdd = undefined } = props;
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(show);
    const [tutors, setTutors] = useState<Tutor[]>([]);
    const [tutorsList, setTutorsList] = useState<Tutor[]>(data);

    const tutorsRef = useRef<HTMLSelectElement>(null);

    const handleAddTutor = () => {
        if (onAdd) {
            const selectedTutor = tutorsRef.current?.selectedOptions;
            let value = Array.prototype
                .slice.call(tutorsRef.current?.selectedOptions);

            const tutors: any[] = value.map((v) => {
                return data.find((t) => t.id === v.value);
            });

            onAdd(tutors);
        }
    }

    const handleQueryChange = (e: React.FormEvent<HTMLInputElement>) => {
        const query = e.currentTarget.value;
        let filteredItems = tutors;

        if (query !== "") {
            filteredItems = tutorsList.filter((e) => {
                return e.arabicName.includes(query);
            });

            setTutorsList(filteredItems);
        } else {
            setTutorsList(data);
        }

    };

    useEffect(() => {
        setTutorsList(data);

        setShowModal(show);
    }, [show]);

    return (
        <Modal show={showModal} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{t("add_tutor_title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    <input
                        type="text"
                        onChange={handleQueryChange}
                        className="w-100"
                        placeholder="Search..."
                    />
                    <select
                        name="tutorsList"
                        id="tutorsList"
                        size={6}
                        multiple={true}
                        className="w-100"
                        ref={tutorsRef}
                    >
                        {/* <option value="">Select Tutor</option> */}
                        {tutorsList && tutorsList.map((tutor) => (
                            <option value={tutor.id}>
                                {tutor.englishName} | {tutor.arabicName}
                            </option>
                        ))}
                    </select>
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    {t("cancel_button_label")}
                </Button>
                <Button variant="primary" onClick={handleAddTutor}>
                    {t("add_button_label")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}