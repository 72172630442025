import { zodResolver } from "@hookform/resolvers/zod";
import { doc, collection } from "firebase/firestore";
import { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";
import { db } from "../firebase";
import { College } from "../models/college";
import { Country } from "../models/country";
import { University } from "../models/university";
import { getAllCountries } from "../services/api";
import { CollegeSchema } from "../services/forms-schemas";
import UniversityPicker from "./university-picker";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface CollegeFormProps {
    targetCollege: College | null,
    onSubmit?(data: any): void,
    onCancel?(): void,
}

export default function CollegeForm(props: CollegeFormProps) {

    const { targetCollege = new College() } = props;
    const { t, i18n } = useTranslation();

    const [countryList, setCountryList] = useState<Country[]>([]);

    type Inputs = z.infer<typeof CollegeSchema>;

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors } } = useForm<Inputs>({
            resolver: zodResolver(CollegeSchema)
        });

    const processForm: SubmitHandler<Inputs> = data => {
        const universityRef = doc(collection(db, "universities"), data.university);

        const objectData = {
            arabicName: data.arabicName,
            englishName: data.englishName,
            university: universityRef,
            active: data.active,
        }

        if (props.onSubmit) {
            props?.onSubmit(objectData);
        }

        reset();
    }

    const handleSelectUniversity = (selectedCountry?: Country, selectedUniversity?: University) => {
        if (selectedUniversity) {
            setValue("university", selectedUniversity.id ?? "");
        }
    }

    useEffect(() => {
        getAllCountries().then((rows) => {
            setCountryList(countryList);
        });

        if (targetCollege?.university) {
            setValue("university", targetCollege?.university?.id as string);
        }
    }, []);

    return (
        <form className="w-100" method="post" onSubmit={handleSubmit(processForm)}>
            <Table bordered striped>
                <tbody>
                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("name_column_header")}
                        </td>
                    </tr>
                    <tr>
                        {i18n.language === "en"
                            ? <>
                                <td>
                                    <input
                                        className="w-100"
                                        placeholder="Enter name (English)"
                                        defaultValue={targetCollege?.englishName}
                                        {...register("englishName", { value: targetCollege?.englishName, required: true })}
                                    />
                                    {errors.englishName?.message && (
                                        <p className="small text-danger">{errors.englishName.message}</p>
                                    )}
                                </td>
                                <td>
                                    <input
                                        className="w-100 text-end"
                                        placeholder="Enter name (Arabic)"
                                        defaultValue={targetCollege?.arabicName}
                                        {...register("arabicName", { value: targetCollege?.arabicName, required: true })}
                                    />
                                    {errors.arabicName?.message && (
                                        <p className="small text-danger">{errors.arabicName.message}</p>
                                    )}
                                </td>
                            </>
                            : <>
                                <td>
                                    <input
                                        className="w-100 text-start"
                                        placeholder="Enter name (Arabic)"
                                        defaultValue={targetCollege?.arabicName}
                                        {...register("arabicName", { value: targetCollege?.arabicName, required: true })}
                                    />
                                    {errors.arabicName?.message && (
                                        <p className="small text-danger">{errors.arabicName.message}</p>
                                    )}
                                </td>
                                <td className="text-end">
                                    <input
                                        className="w-100 text-end"
                                        placeholder="Enter name (English)"
                                        defaultValue={targetCollege?.englishName}
                                        {...register("englishName", { value: targetCollege?.englishName, required: true })}
                                    />
                                    {errors.englishName?.message && (
                                        <p className="small text-danger">{errors.englishName.message}</p>
                                    )}
                                </td>
                            </>
                        }
                    </tr>
                    <UniversityPicker
                        data={targetCollege?.university}
                        onSelect={handleSelectUniversity}
                    />
                    <input
                        type="hidden"
                        {...register("university", { validate: (value) => value !== "" })}
                        defaultValue={targetCollege?.university?.id}
                    />
                    {errors.university?.message && (
                        <p className="small text-danger">{errors.university?.message}</p>
                    )}

                    <tr>
                        <td className="field-label" colSpan={2}>
                            {t("active_column_header")}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <input type="checkbox"
                                {...register("active")}
                                defaultChecked={targetCollege?.active}
                            />
                            {errors.active?.message && (
                                <p className="small text-danger">{errors.active?.message}</p>
                            )}
                        </td>
                    </tr>
                </tbody>
            </Table>
            <div className="mt-3 float-end">
                <Button
                    variant="primary"
                    type="submit"
                    className="me-3 py-2 px-4"
                >
                    {t("save_button_label")}
                </Button>
                <Button
                    variant="secondary"
                    type="button"
                    className="py-2 px-4"
                    onClick={props.onCancel}
                >
                    {t("cancel_button_label")}
                </Button>
            </div>
        </form >
    );
}