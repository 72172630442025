import { Suspense, useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import FcmMessageForm from "../../components/fcm-message-form";
import { string } from "zod";
import { useTranslation } from "react-i18next";

export default function MessagingPage() {
    const { t, i18n } = useTranslation();

    const sendMessageToDevice = (title: string, body: string, topic: string) => {

        const functions = getFunctions();
        const sendPushNotification = httpsCallable(functions, 'sendPushNotification');
        sendPushNotification({ topic: topic, title: title, body: body })
            .then((result) => {
                console.log(result);
                // // Read result of the Cloud Function.
                // /** @type {any} */
                // const data = result.data;
                // const sanitizedMessage = data.text;
            });
    }

    const handleSend = (data: any) => {
        sendMessageToDevice(data.title, data.body, data.topic);
    }

    return (
        <>
            <script type="text/javascript" src="sender.js"></script>
            <h2 className="mb-4">{t("messaging_title")}</h2>
            <FcmMessageForm onSubmit={handleSend} />
        </>
    );
}