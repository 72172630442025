import { useNavigate } from "react-router-dom";
import { functions } from "../../firebase";
import { serverTimestamp } from "firebase/firestore";
import PageHeader from "../../components/page-header";
import TutorForm from "../../components/tutor-form";
import { Container } from "react-bootstrap";
import { httpsCallable } from "firebase/functions";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import SavingIndicator from "../../components/saving-indicator";
import { ApiResponse } from "../../models/response";
import { Tutor } from "../../models/tutor";


export default function TutorCreatePage() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [target, setTarget] = useState<Tutor | null>(null);

    const handleSubmit = async (data: any) => {
        setLoading(true);

        try {
            // Add extra fields
            data.deleted = false;
            data.timeCreated = serverTimestamp();
            data.timeModified = serverTimestamp();

            const createTutor = httpsCallable(functions, "createTutor");
            const res = await createTutor({ tutor: data });

            var response: ApiResponse = res.data as ApiResponse;

            if (response.error) {
                setTarget(data as Tutor);
                setError(response.error);
                setLoading(false);
            } else {
                navigate("/admin/tutors");
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    return (
        <>
            <PageHeader title={t("add_tutor_title")} />
            <Container fluid>
                {error &&
                    <div className="text-center text-danger mb-3 fw-bold" dir="ltr">
                        {error}
                    </div>
                }
                {loading
                    ? <SavingIndicator />
                    : <TutorForm
                        target={target}
                        onSubmit={handleSubmit}
                        onCancel={() => navigate("/admin/tutors")
                        }
                    />
                }
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </Container>
        </>
    )
}