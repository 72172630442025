import { ReactNode } from "react";
import { useLayoutDirection } from "./LayoutDirectionProvider";
import { Container, ThemeProvider } from "react-bootstrap";
import { NavigationBar } from "./components/navigation-bar";
import { SideBar } from "./components/sidebar";

interface LayoutProps {
    children?: ReactNode
}

export const Layout = (props: LayoutProps) => {
    const { isRTL } = useLayoutDirection();

    return (
        <>
            <link
                rel="stylesheet"
                type="text/css"
                href={`/css/${isRTL() ? "bootstrap.rtl.min.css" : "bootstrap.min.css"}`}
            />

            <ThemeProvider dir={isRTL() ? "rtl" : "ltr"} >
                < header >
                    <SideBar />

                    <NavigationBar />
                </header >

                <main className="mainContent">
                    <Container fluid className="pt-4">
                        {props.children}
                    </Container>
                </main>
            </ThemeProvider >
        </>
    )

}